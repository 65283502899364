import React, {useEffect, useState, Fragment, useRef} from 'react';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridFilterInputValue,
  getGridStringOperators,
  GridPreferencePanelsValue,
} from '@mui/x-data-grid';
import {useSelector, useDispatch} from 'react-redux';
import {onPutBulkFinding} from 'redux/actions';
import Pagination from '@mui/material/Pagination';
import {Box /*IconButton*/} from '@mui/material';
import {getIssuesCleanRequest /*onDeleteScanFinding*/} from 'redux/actions';
import {GET_ISSUES_CUR_PAGE} from 'shared/constants/ActionTypes';
import { setSelectedIds } from 'redux/actions/Dashboard';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import styles from './IssuesDataTable.module.css';
import LinearProgress from '@mui/material/LinearProgress';
import {GridToolbarContainer} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
// import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

import {
  storeFilterData,
  removeFilterData,
  setPageSize,
} from '../../../redux/actions/Dashboard';

import {
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';
import {setTableColumns} from 'redux/actions/Table';
import Modal from '@mui/material/Modal';
import PolicyIcon from '@mui/icons-material/Policy';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CommentIcon from '@mui/icons-material/Comment';
import Button from '@mui/material/Button';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import StyleIcon from '@mui/icons-material/Style';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {getAllRoles} from 'redux/actions/Roles';
import {getAllPermissions} from 'redux/actions/Roles';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';


// const deleteIcon = {fontSize: 60};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const IssuesDataTable = ({
  _id,
  issuesSearch,
  isFindingsNew,
  handleClose,
  open,
  reqComment,
  onSelectedIdsChange
}) => {
  let {org_id} = useParams();
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');

  const dispatch = useDispatch();
  const curReportId = useSelector(({dashboard}) => dashboard.curReportId);
  const issuesData = useSelector(({dashboard}) => dashboard.issuesData);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const loggedUser = useSelector(({common}) => common.userInfo);
  const originator = [loggedUser?.usr?._id];
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);
  const filterData = useSelector(({dashboard}) => dashboard.filterData);
  const totalRows = useSelector(({dashboard}) => dashboard.totalRows);
  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const pageCount = useSelector(({dashboard}) => dashboard.pageCount);
  const initial = useSelector(({dashboard}) => dashboard.initial);
  const selectedIds = useSelector(({dashboard}) => dashboard.selectedIds);
  const users = useSelector(({common}) => common.users);
  const issuesPageCount = useSelector(
    ({dashboard}) => dashboard.issuesPageCount,
  );
  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const tableColumn = useSelector(({table}) => table.tableColumns.columns);

  const [rows, setRows] = useState([]);

  const navigate = useNavigate(); 
  useEffect(() => {
    const temp = [];
    if (issuesData) {
      let data = generateTableData(issuesData, issuesCurPage);
      if (data.length === 0) {
        console.warn();
      } else {
        data.forEach((item /*index*/) => {
          let today = new Date();
          let diffDay = differenceInDays(today, new Date(item.founddate));
          let diffHour = differenceInHours(today, new Date(item.founddate));
          let diffMin = differenceInMinutes(today, new Date(item.founddate));
          item.customFields = item.customFields.map(
            (tag) => `${tag.fieldName}:${tag.fieldValue}`,
          );
          temp.push({
            id: item._id,
            issueNumber: /*index + 1*/ item.id,
            issueId: item.issueId ? item.issueId : '',
            status: item.state ? item.state : '',
            title: item.title,
            type: item.type,
            severity: item.severity
              ? item.severity.charAt(0).toUpperCase() + item.severity.slice(1)
              : '',
            assigned: item.assign ? item.assign : 'Unassigned',
            age:
              diffDay > 1
                ? diffDay + ' Days'
                : diffHour > 1
                ? diffHour + ' Hours'
                : diffMin > 1
                ? diffMin + ' Mins'
                : diffMin < 1
                ? '1 Min'
                : '',
            category: item?.category?.toUpperCase() || '',
            tags: item?.customFields || [],
            cwe: item?.cweViolation || '',
            owasp: item?.owasp || [],
            violations: item?.complianceTypes?.join(' , ') || '',
            filename: item?.filename?item.filename:'NA',
            line: item?.line?item.line:'NA'
          });
        });
      }
    }
    setRows(temp);
  }, [issuesData, issuesPageCount, org_id]);


  const paginationChange = (event, value) => {
    dispatch({type: GET_ISSUES_CUR_PAGE, payload: value});
    if ((_id || reportId) && org_id) {
      dispatch(
        getIssuesCleanRequest({
          ...issuesSearch,
          _id: _id || reportId,
          page: value,
          orgId: org_id,
          filterData: filterData,
          pageSize: pageSize,
          isDownload: false,
          isFindingsNew: isFindingsNew,
        }),
      );
    }
  };

  const convertSeverityToChip = (severity) => {
    switch (severity) {
      case 'High':
        return (
          <Chip
            label='High'
            color='error'
            variant='outlined'
            sx={{minWidth: '110px'}}
          />
        );
      case 'Medium':
        return (
          <Chip
            label='Medium'
            variant='outlined'
            sx={{minWidth: '110px', borderColor: '#EB9D20', color: '#EB9D20'}}
          />
        );
      case 'Low':
        return (
          <Chip
            label='Low'
            color='primary'
            variant='outlined'
            sx={{minWidth: '110px'}}
          />
        );
      case 'Critical':
        return (
          <Chip
            label='Critical'
            color='warning'
            variant='outlined'
            sx={{minWidth: '110px'}}
          />
        );
      case 'Info':
        return (
          <Chip
            label='Info'
            color='success'
            variant='outlined'
            sx={{minWidth: '110px'}}
          />
        );
      default:
        return (
          <Chip
            label='Undefined'
            color='primary'
            variant='outlined'
            sx={{minWidth: '110px'}}
          />
        );
    }
  };

  const convertStatusText = (status) => {
    switch (status) {
      case 'Accepted':
        return <p style={{color: '#3A4AEC'}}>Accepted</p>;
      case 'Active':
        return <p style={{color: 'red'}}>Active</p>;
      case 'False-positive':
        return <p style={{color: '#89807E'}}>False-positive</p>;
      case 'False-Positive':
        return <p style={{color: '#89807E'}}>False-Positive</p>;
      case 'Re-activated':
        return <p style={{color: '#F9801C'}}>Re-activated</p>;
      case 'Supressed':
        return <p style={{color: '#89807E'}}>Supressed</p>;
      case 'Suppressed':
        return <p style={{color: '#89807E'}}>Suppressed</p>;
      case 'active':
        return <p style={{color: 'red'}}>Active</p>;
      case 'Fixed':
        return <p style={{color: '#63BF00'}}>Fixed</p>;
      case 'Resolved':
        return <p style={{color: '#63BF00'}}>Resolved</p>;
      case 'Duplicate':
        return <p style={{color: '#17a2b8'}}>Duplicate</p>;
      case 'Ignored':
        return <p style={{color: '#343a40'}}>Ignored</p>;
      default:
        return <p style={{color: 'black'}}>{status}</p>;
    }
  };

  const convertTagsChips = (tags) => {
    return (
      <div className={styles.tagContainer}>
        {tags.map((tag, index) => (
          <span
            key={index}
            style={{
              backgroundColor: '#0a8fdc',
              padding: '2px 6px',
              borderRadius: 4,
              color: '#fff',
              marginRight: 4,
              overflowX: 'auto',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {tag}
          </span>
        ))}
      </div>
    );
  };

  // const convertTagsArrayToString = (tagsArray) => {
  //   return tagsArray
  //     .map((tag) => `${tag.fieldName}:${tag.fieldValue}`)
  //     .join(', ');
  // };

  // const onDelete = (e) => {
  //   e.stopPropagation();
  //   setDeleteModal(true);
  // };

  // const deleteHandler = async () => {
  //   dispatch(
  //     onDeleteScanFinding({
  //       findingId: clickedRow.id,
  //       scanId: curReportId,
  //       org_id: org_id,
  //     }),
  //   );
  //   dispatch(
  //     getIssuesCleanRequest({
  //       ...issuesSearch,
  //       _id: _id,
  //       page: issuesCurPage,
  //       orgId: org_id,
  //     }),
  //   );
  // };

  // const handleClick = (event, row) => {
  //   event.stopPropagation();
  //   setClickedRow(row);
  //   setAnchorEl(event.currentTarget);
  //   setDeleteModal(true);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const columns = [
    {
      field: 'issueNumber',
      headerName: 'Count',
      minWidth: 120,
      flex: 1,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'issueId',
      headerName: 'Issue Id',
      minWidth: 120,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => convertStatusText(params.value),
      filterOperators: notContainsOperator(),
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 495,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className={styles.overFlowTitleText}>
            {params.value}{' '}
            <OpenInNewIcon
              className={styles.openInNewIcon}
              onClick={(e) => {
                openIssue(e, params.row.id);
              }}
            />
          </span>
        );
      },
      filterOperators: notContainsOperator(),
    },

    {
      field: 'filename',
      headerName: 'Filename',
      minWidth: 170,
      flex: 1,
      hide: true,
      renderCell: (params) => {
        return (
          <span className={styles.overFlowTitleText}>
            {params.value}{' '}
          </span>
        );
      },
      filterOperators: notContainsOperator(),
    },

    {
      field: 'line',
      headerName: 'Line',
      minWidth: 170,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },

    {
      field: 'severity',
      headerName: 'Severity',
      minWidth: 170,
      flex: 1,
      renderCell: (params) => convertSeverityToChip(params.value),
      filterOperators: notContainsOperator(),
    },
    {
      field: 'assigned',
      headerName: 'Assigned',
      minWidth: 250,
      flex: 1,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'age',
      headerName: 'Age',
      minWidth: 140,
      flex: 1,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 140,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      minWidth: 140,
      flex: 1,
      filterOperators: notContainsOperator(),
      renderCell: (params) => convertTagsChips(params.value),
    },
    {
      field: 'cwe',
      headerName: 'CWE',
      minWidth: 140,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'owasp',
      headerName: 'OWASP',
      minWidth: 140,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },
    {
      field: 'violations',
      headerName: 'Violations',
      minWidth: 140,
      flex: 1,
      hide: true,
      filterOperators: notContainsOperator(),
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   minWidth: 140,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton
    //           title={open ? 'Delete' : 'Delete'}
    //           onClick={(e) => handleClick(e, params.row)}
    //         >
    //           <DeleteIcon color='secondary' />
    //         </IconButton>
    //       </>
    //     );
    //   },
    //   // filterOperators: notContainsOperator(),
    // },
  ];

  const [columnDefs, setColumnDefs] = useState(tableColumn || []);

  useEffect(async () => {
    const check = JSON.stringify(columns) === JSON.stringify(tableColumn);
    if (check || !tableColumn) await dispatch(setTableColumns({columns}));
    setColumnDefs(tableColumn);
  }, []);

  const handleColumnVisibilityChange = React.useCallback(
    async (model) => {
      const newColumnDefs = columnDefs.map((col) => {
        if (col.field === model.colDef.field) {
          return {
            ...col,
            hide: model.colDef.hide,
          };
        } else {
          return col;
        }
      });
      setColumnDefs(newColumnDefs);
      await dispatch(setTableColumns({columns: newColumnDefs}));
    },
    [columnDefs],
  );

  useEffect(() => {
    if (!columnDefs || columnDefs.length === 0) {
      console.warn;
    }
  }, [columnDefs]);

  const noColumnsRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.location.reload();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (noColumnsRef.current) {
      observer.observe(noColumnsRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
  }, []);

  const [updatedIds, setUpdatedIds] = useState([]);

  useEffect(() => {
    setUpdatedIds(selectedIds);
  }, [selectedIds]);
  

  function notContainsOperator() {
    return [
      ...getGridStringOperators(),
      {
        value: 'noContain',
        getApplyFilterFn: (filterItem, column) => {
          if (
            !filterItem.columnField ||
            !filterItem.value ||
            !filterItem.operatorValue
          ) {
            return null;
          }

          const filterRegex = new RegExp(filterItem.value, 'i');
          return (params) => {
            const rowValue = column.valueGetter
              ? column.valueGetter(params)
              : params.value;
            return !filterRegex.test(rowValue?.toString() || '');
          };
        },
        InputComponent: GridFilterInputValue,
      },
    ];
  }

  // const openIssue = (e, rowId) => {
  //   e.stopPropagation();
  //   window.open(`/app/details/${org_id}/${curReportId}/${rowId}`, '_blank');
  // };

  const openIssue = (e, rowId) => {
    e.stopPropagation();
    window.open(`/app/details/${org_id}/${curReportId}/${rowId}`, '_blank');
  };

  const navgateToDetail = (params) => {
    const singleIssue = issuesData.find((element) => element._id == params.id);
    const issue_id = singleIssue._id;
    navigate(
      `/details/${org_id}/${curReportId}/${issue_id}/${issuesCurPage}?isFindingsNew=${isFindingsNew}`,
    );
  };

  useEffect(async () => {
    const check = JSON.stringify(columnDefs) === JSON.stringify(tableColumn);
    if (check || !tableColumn) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setColumnDefs(tableColumn);
    }
  }, [columnDefs, tableColumn]);

  function generateTableData(findings, page) {
    var tableData = [];
    var count = 1 + (page - 1) * pageSize;
    findings.forEach(function (finding) {
      var tableItem = {};
      tableItem._id = finding._id;
      tableItem.id = count;
      if (finding.filename == 'NA') {
        tableItem.title = finding.issue;
      } else if (finding.issuetype.includes('Vulnerable_Software')) {
        tableItem.title = finding.issue + ' @ ' + finding.filename;
      } else if (finding.category === 'config') {
        tableItem.title = finding.issue;
        // if(finding.issuetype.includes('SFDC_GUEST_ACCESS_LIGHTNING')){
        //   tableItem.title = finding.title;
        // }
      } else if (finding.issuetype.includes('SFDC_GUEST')) {
        tableItem.title = finding.codesnippets;
      } else {
        tableItem.title =
          finding.issue +
          ' @ ' +
          finding.filename +
          ' on line: ' +
          finding.line;
      }
      if (typeof finding.issue === 'undefined') {
        tableItem.type = 'Misc';
      } else {
        tableItem.type = finding.issue;
      }
      tableItem.state = finding.status;
      if (
        finding.assigned !== null &&
        typeof finding.assigned !== 'undefined'
      ) {
        tableItem.assign = finding.assigned.email;
      } else {
        tableItem.assign = 'Unassigned';
      }
      tableItem.severity = finding.severity;
      if (finding.customFields) {
        tableItem.customFields = finding.customFields;
      }
      if (finding.category) {
        tableItem.category = finding.category.toUpperCase();
      }
      if(finding.filename) {
        tableItem.filename = finding.filename;
      }

      if(finding.line){
        tableItem.line = finding.line;
      }

      if (finding.complianceTypes && finding.complianceTypes.length > 0) {
        tableItem.complianceTypes = finding.complianceTypes;
      } else {
        tableItem.complianceTypes = [];
      }
      if (finding.cweViolation && finding.cweViolation.length > 0) {
        tableItem.cweViolation = finding.cweViolation
          .split(':')[0]
          .trim()
          .split(' ')[1];
      } else {
        tableItem.cweViolation = '';
      }
      if (finding.owasp && finding.owasp.length > 0) {
        tableItem.owasp = finding.owasp;
      } else {
        tableItem.owasp = [];
      }
      tableItem.founddate = finding.founddate;
      tableItem.updateddate = finding.updateddate;
      tableItem.issueId = finding.issueId;
      tableData.push(tableItem);
      count = count + 1;
    });
    return tableData;
  }

  function containsSpecialCharacters(input) {
    const regex = /[*(),\\?[\]#]/;
    return regex.test(input);
  }

  const onFilterChange = (filterModel) => {
    if (
      filterModel?.items[0].operatorValue == 'isEmpty' ||
      filterModel?.items[0].operatorValue == 'isNotEmpty'
    ) {
      dispatch(storeFilterData(filterModel));
      if ((_id || reportId) && org_id) {
        dispatch(
          getIssuesCleanRequest({
            ...issuesSearch,
            _id: _id || reportId,
            page: issuesCurPage,
            orgId: org_id,
            filterData: filterModel,
            pageSize: pageSize,
            isFindingsNew: isFindingsNew,
          }),
        );
      }
    }
    if (!containsSpecialCharacters(filterModel.items[0].value)) {
      if (
        filterModel.items.length > 0 &&
        filterModel.items[0].value != undefined &&
        filterModel.items[0].value != ''
      ) {
        if (filterModel.items[0].columnField == 'age') {
          const parts = filterModel.items[0].value.split(' ');
          if (parts.length > 1) {
            filterModel.items[0].value = parts[0];
          }
        }
        dispatch(storeFilterData(filterModel));
        if ((_id || reportId) && org_id) {
          dispatch(
            getIssuesCleanRequest({
              ...issuesSearch,
              _id: _id || reportId,
              page: issuesCurPage,
              orgId: org_id,
              filterData: filterModel,
              pageSize: pageSize,
              isFindingsNew: isFindingsNew,
            }),
          );
        }
      }
    }
    if (
      filterModel.items[0].value == undefined ||
      filterModel.items[0].value == ''
    ) {
      if (!initial) {
        dispatch(removeFilterData());
        if ((_id || reportId) && org_id) {
          dispatch(
            getIssuesCleanRequest({
              ...issuesSearch,
              _id: _id || reportId,
              page: issuesCurPage,
              orgId: org_id,
              pageSize: pageSize,
              isFindingsNew: isFindingsNew,
            }),
          );
        }
      }
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    dispatch(setPageSize(newPageSize));
    if ((_id || reportId) && org_id) {
      dispatch(
        getIssuesCleanRequest({
          ...issuesSearch,
          _id: _id || reportId,
          page: issuesCurPage,
          orgId: org_id,
          filterData: filterData,
          pageSize: newPageSize,
          isFindingsNew: isFindingsNew,
        }),
      );
    }
  };

  //bulk findings modification

  useEffect(() => {
    const isEmpty = selectedIds.length === 0;
    onSelectedIdsChange(isEmpty);
  }, [selectedIds, onSelectedIdsChange]);

  // useEffect(() => {
  //  console.log('Selected IDs: ', selectedIds);
  // }, [selectedIds]);


  //main modal
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 400,
    width: 600,
    borderRadius: 5,
    padding: 7,
    overflowY: 'auto',
  };

  const [form, setForm] = useState({
    status: '',
    severity: '',
    assigned: '',
  });

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Resolved', value: 'Resolved'},
    {label: 'Fixed', value: 'Fixed'},
    {label: 'Suppressed', value: 'Suppressed'},
    {label: 'False-positive', value: 'False-positive'},
    {label: 'Re-activated', value: 'Re-activated'},
    {label: 'Accepted', value: 'Accepted'},
    {label: 'Ignored', value: 'Ignored'},
    {label: 'Duplicate', value: 'Duplicate'},
  ];

  const [openSnackbarComment, setOpenSnackbarComment] = useState(false);
  const [snackbarMessageComment, setSnackbarMessageComment] = useState('');

  const handleSnackbarCloseComment = () => {
    setOpenSnackbarComment(false);
  };

  const handleStatusChange = (event, value) => {
    if (reqComment && isDisabledComment) {
      setOpenSnackbarComment(true);
      setSnackbarMessageComment("Status changes require a comment per account settings. User doesn't have access to add comment. Please contact administrator");
      setForm((prevForm) => ({
        ...prevForm,
        status: '',
      }));
    } else if (reqComment && !isDisabledComment && !isDisabledStatus) {
      setIsModalOpen(true);
      setForm((prevForm) => ({
        ...prevForm,
        status: value,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        status: value,
      }));
    }
  };

  const handleDisabledFieldClick = (event) => {
    event.stopPropagation();

    if (reqComment && isDisabledComment) {
      setOpenSnackbarComment(true);
      setSnackbarMessageComment(
        "Status changes require a comment per account settings. User doesn't have access to add comment. Please contact administrator",
      );
    }

    if (reqComment && isDisabledComment && isDisabledStatus) {
      setOpenSnackbarComment(false);
    }
  };

  const severityOptions = [
    {label: 'Critical', value: 'critical'},
    {label: 'High', value: 'high'},
    {label: 'Medium', value: 'medium'},
    {label: 'Low', value: 'low'},
    {label: 'Info', value: 'info'},
  ];
  
  const [severityChanged, setSeverityChanged] = useState(false);

  const handleSeverityChange = (event, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      severity: value,
    }));
    setSeverityChanged(true);
  };

  const getAssignTo = () => {
    let arr = users?.map((user) => user.email) || [];
    arr.push('Unassigned');
    return arr;
  };

  const onChangeAssign = (event, value) => {
    setForm((cur) => {
      return {
        ...cur,
        assigned: value,
      };
    });
  };

  //comment modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setForm((prevForm) => ({
      ...prevForm,
      status: '',
    }));
    setIsModalOpen(false);
  };

  const handleModalCommentSave = () => {
    if(!comment){
      return dispatch({
        type: FETCH_ERROR,
        payload: 'Comment is required',
      });
    };
    setIsModalOpen(false);
  };

  const modalComment = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 400,
    width: 600,
    borderRadius: 5,
    padding: 7,
    overflowY: 'auto',
  };

  //tags modal
  const [tagsModal1, setTagsModal1] = useState(false);

  const [tags, setTags] = useState([]);
  const [newTagData, setNewTagData] = useState({
    addLink: false,
    fieldName: '',
    fieldValue: '',
    href: '',
  });

  const tagsModalOpen1 = () => {
    setTagsModal1(true);
  };
  const tagsModalClose = () => {
    setTagsModal1(false);
  };

  const [openSnackbarURL, setOpenSnackbarURL] = useState(false);
  const [snackbarMessageURL, setSnackbarMessageURL] = useState('');

  const handleSnackbarCloseURL = () => {
    setOpenSnackbarURL(false);
  };

  const handleSaveTags = () => {
    const hasInvalidUrl = tags.some(tag => 
      tag.addLink && tag.href && !isValidUrl(tag.href)
    );
  
    if (hasInvalidUrl) {
      setOpenSnackbarURL(true);
      setSnackbarMessageURL("Link cannot be validated. Please check the web address and try again");
      return;
    }
  
    const hasEmptyFields = tags.some(tag => tag.fieldName === "" || tag.fieldValue === "");
  
    if (hasEmptyFields) {
      setOpenSnackbarURL(true);
      setSnackbarMessageURL("Please fill the fields");
      return;
    }
  
    if (tags.some(tag => tag.addLink && !tag.href)) {
      setOpenSnackbarURL(true);
      setSnackbarMessageURL("Please add URL");
      return;
    }    
  
    setTagsModal1(false);
  };
  
  const isValidUrl = (input) => {
    try {
      new URL(input);
      return true;
    } catch (error) {
      return false;
    }
  };  
  
  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleAddTag = () => {
    setTags([...tags, newTagData]);
    setNewTagData({
      addLink: false,
      fieldName: '',
      fieldValue: '',
      href: '',
    });
  };

  const handleTagDataChange = (e, index) => {
    const {name, value, checked} = e.target;
    const updatedTags = [...tags];
    updatedTags[index] = {
      ...updatedTags[index],
      [name]: name === 'addLink' ? checked : value,
    };
    setTags(updatedTags);
  };

  const submitForm = () => {
    let payloadSelectedRows = selectedRowsPayload;
  
    if (!severityChanged) {
      payloadSelectedRows = selectedRowsPayload.map(({ id }) => ({ id }));
    }
  
    const userAssigned = users.find(user => user.email === form.assigned);
  
    const obj = {
      ...form,
      status: form.status.value,
      severity: form.severity.value,
      comment,
      selectedIds: payloadSelectedRows,
      customFields: tags,
      _scanID: reportId,
    };
  
    if (userAssigned && userAssigned !== 'Unassigned' && userAssigned !== form.assigned) {
      obj.assigned = userAssigned;
    }
  
    const updateObject = obj => Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        if (value.trim()) {
          acc[key] = value;
        }
      } else if (Array.isArray(value) && value.length > 0) {
        acc[key] = value;
      } else if (value !== null && value !== undefined && !(typeof value === 'object' && Object.keys(value).length === 0)) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const newObject = updateObject(obj);
    const hasData = obj.status || obj.severity || obj.comment || (obj.customFields && obj.customFields.length > 0) || obj.assigned;

    if (!hasData) {
      setOpenSnackbarComment(true);
      setSnackbarMessageComment("Please select atleast one attribute");
      return;
    }
    
      dispatch(
        onPutBulkFinding({
          obj: newObject,
          report_id: _id || reportId,
          page: issuesCurPage,
          orgId: org_id,
          pageSize: pageSize,
          filterData: filterData,
          issuesSearch: issuesSearch,
          isFindingsNew: isFindingsNew,
          originator: originator,
        }),
      );
    // dispatch(
    //   getIssuesCleanRequest({
    //     ...issuesSearch,
    //     _id: _id || reportId,
    //     orgId: org_id,
    //     filterData: filterData,
    //     page: issuesCurPage,
    //     pageSize: pageSize,
    //     isDownload: false,
    //     isFindingsNew: isFindingsNew,
    //   }),
    // );
    handleClose();
  };
  
  
  const currentOrg = loggedUser.usr.salesforceOrgs 
  ? loggedUser.usr.salesforceOrgs.find(org => org.sfdcId === org_id)
  : null;
  
  if (!currentOrg) {
    return null;
  }

  const roleForCurrentOrg = Array.isArray(allRoles) ? allRoles.find(role => role._id === currentOrg.roleId) : undefined;

  if (!roleForCurrentOrg) {
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(permission => 
    permission._id === roleForCurrentOrg.permissionsId
  );  

  const findingStatusPermission = permissionsForCurrentOrg
    .flatMap(permission => permission.permissions)
    .find(permission => permission.object === 'findingStatus');

const findingAssignmentPermission = permissionsForCurrentOrg
    .flatMap(permission => permission.permissions)
    .find(permission => permission.object === 'findingAssignment');

    const findingSeverityPermission = permissionsForCurrentOrg
    .flatMap(permission => permission.permissions)
    .find(permission => permission.object === 'findingSeverity');

    const findingTagPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingTag');

  const findingCommentPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingComment');

const isDisabledStatus =
    findingStatusPermission &&
    findingStatusPermission.permissions.length === 1 &&
    findingStatusPermission.permissions.includes('READ');

const isDisabledAssignment =
    findingAssignmentPermission &&
    findingAssignmentPermission.permissions.length === 1 &&
    findingAssignmentPermission.permissions.includes('READ');

    const isDisabledSeverity =
    findingSeverityPermission &&
    findingSeverityPermission.permissions.length === 1 &&
    findingSeverityPermission.permissions.includes('READ');

  const isDisabledTag =
    findingTagPermission &&
    findingTagPermission.permissions.length === 1 &&
    findingTagPermission.permissions.includes('READ');

  const isDisabledComment =
    findingCommentPermission &&
    findingCommentPermission.permissions.length === 1 &&
    findingCommentPermission.permissions.includes('READ');

    const selectedRowsData = rows.filter((row) => selectedIds.includes(row.id));

    const selectedRowsPayload = selectedRowsData.map((row) => ({
      id: row.id,
      oldSeverity: row.severity.toLowerCase(),
    }));  

  return (
    <>
      <Fragment>
        <div style={{height: 1000, width: '100%'}}>
          <DataGrid
            rows={rows}
            columns={
              columnDefs
                ? [...columnDefs, {field: 'issueNumber', filterable: false}]
                : []
            }
            onFilterModelChange={onFilterChange}
            localeText={{
              filterOperatorNoContain: 'does not contain',
            }}
            state={{
              preferencePanel: {
                open: true,
                openedPanelValue: GridPreferencePanelsValue.filters,
              },
            }}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            FilterPanelDeleteIcon
            onCellClick={navgateToDetail}
            getRowClassName={() => styles.dataGridElement}
            keepNonExistentRowsSelected
            onColumnVisibilityChange={handleColumnVisibilityChange}
            checkboxSelection={true}
            onSelectionModelChange={(newSelection) => {
              dispatch(setSelectedIds(newSelection));
              setUpdatedIds(selectedIds);
            }}
            selectionModel={updatedIds}
            initialState={{
              rows,
              pagination: {
                pageSize: pageSize,
              },
              filter:
                filterData.items.length > 0
                  ? {
                      filterModel: {
                        items: [
                          {
                            columnField: filterData.items[0].columnField,
                            operatorValue: filterData.items[0].operatorValue,
                            value: filterData.items[0].value,
                          },
                        ],
                      },
                    }
                  : null,
            }}
            page={parseInt(issuesCurPage || 1) - 1}
            rowCount={totalRows}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[25, 50, 100]}
            pageSize={pageSize}
            paginationMode='server'
            filterMode='server'
            sx={{
              '.MuiTablePagination-actions': {
                display: 'none',
              },
              '.MuiTablePagination-toolbar': {
                paddingRight: '30px',
              },
              marginBottom: 100,
              '& .MuiDataGrid-renderingZone': {
                maxHeight: 'none !important',
              },
              '& .MuiDataGrid-cell': {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal',
                justifyContent: 'center !important',
              },
              '& .MuiDataGrid-row': {
                maxHeight: 'none !important',
              },
              overflowX: 'hidden',
            }}
          />
        </div>
        {pageCount > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Pagination
              count={pageCount}
              variant='outlined'
              shape='rounded'
              onChange={paginationChange}
              page={parseInt(issuesCurPage || 1)}
            />
          </Box>
        )}
        {/* Main Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <Box sx={styleModal}>
            <div className={styles.headerContainer}>
              <PolicyIcon />
              <h2>Modify Finding</h2>
            </div>
            <div
              className={styles.formSubBlock}
              onClick={(event) => {
                if (isDisabledStatus || (reqComment && isDisabledComment)) {
                  handleDisabledFieldClick(event);
                }
              }}
            >
              <div
                style={{
                  pointerEvents:
                    isDisabledStatus || (reqComment && isDisabledComment)
                      ? 'none'
                      : 'auto',
                }}
              >
                <Autocomplete
                  disabled={
                    isDisabledStatus || (reqComment && isDisabledComment)
                  }
                  value={form.status}
                  onChange={handleStatusChange}
                  options={statusOptions}
                  renderInput={(params) => (
                    <TextField {...params} label='Status' />
                  )}
                />
              </div>
            </div>
            <div className={styles.formSubBlock}>
              <Autocomplete
                disabled={isDisabledSeverity}
                value={form.severity}
                onChange={handleSeverityChange}
                options={severityOptions}
                renderInput={(params) => (
                  <TextField {...params} label='Severity' />
                )}
              />
            </div>
            <div className={styles.formSubBlock}>
              <Autocomplete
                disabled={isDisabledAssignment}
                options={getAssignTo()}
                value={form.assigned}
                onChange={onChangeAssign}
                renderInput={(params) => (
                  <TextField {...params} label='Assigned' />
                )}
              />
            </div>
            <div className={styles.btnContainerActions}>
              <Button
                disabled={isDisabledComment}
                onClick={handleModalOpen}
                sx={{marginRight: 1}}
                variant='contained'
              >
                <CommentIcon className={styles.iconContainer} />
                <p className={styles.iconContainerp}>
                  {comment ? 'Edit Comment' : 'Add Comment'}
                </p>
              </Button>
              <Button
                disabled={isDisabledTag}
                onClick={tagsModalOpen1}
                sx={{marginRight: 1}}
                variant='contained'
              >
                <LocalOfferIcon className={styles.iconContainer} />
                <p className={styles.iconContainerp}>
                  {tags.length > 0 ? 'Edit Tags' : 'Add Tags'}
                </p>
              </Button>
            </div>
            <div className={styles.submitBtnContainer}>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClose}
              >
                Close
              </Button>
              <Button variant='contained' type='submit' onClick={submitForm}>
                <p>Submit</p>
              </Button>
            </div>
          </Box>
        </Modal>
        {/* Main Modal */}

        {/* Comment Modal */}
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <Box sx={modalComment}>
            <div className={styles.headerContainerComment}>
              <CommentIcon className={styles.commentIconHeader} />
              <h3>{reqComment ? 'Required Comment' : 'Add Comment'}</h3>
            </div>
            <p className={styles.pLabels}>Comments:</p>
            <TextareaAutosize
              className={styles.commentArea}
              minRows={10}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className={styles.btnContainer}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleModalCommentSave}
              >
                Save
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleModalClose}
                style={{marginRight: 8}}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        {/* Comment Modal */}

        {/* Tags Modal */}
        <Modal open={tagsModal1} onClose={tagsModalClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 5,
              padding: 7,
              width: '50%',
              minHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <div>
              <div className={'headerTag'}>
                <StyleIcon />
                <Typography variant='h2'>Add Tag</Typography>
              </div>
              <div className={'addBtnContainer'}>
                <Button
                  className={'closeBtn'}
                  variant='contained'
                  color='secondary'
                  onClick={tagsModalClose}
                >
                  Close
                </Button>
                <Button
                  className={'closeBtn'}
                  variant='contained'
                  color='primary'
                  onClick={handleAddTag}
                >
                  Add Tag
                </Button>
              </div>
              &nbsp;
              <TableContainer component={Paper}>
                <Table
                  sx={{minWidth: 650}}
                  aria-label='simple table'
                  stickyHeader={true}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Add Link</TableCell>
                      <TableCell align='left'>Tag</TableCell>
                      <TableCell align='left'>Value</TableCell>
                      <TableCell align='left'>URL</TableCell>
                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tags.map((tag, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            name='addLink'
                            checked={tag.addLink}
                            onChange={(e) => handleTagDataChange(e, index)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            required={true}
                            label='Tag'
                            name='fieldName'
                            value={tag.fieldName}
                            onChange={(e) => handleTagDataChange(e, index)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            required={true}
                            label='Value'
                            name='fieldValue'
                            value={tag.fieldValue}
                            onChange={(e) => handleTagDataChange(e, index)}
                          />
                        </TableCell>
                        <TableCell>
                          {tag.addLink ? (
                            <TextField
                              label='URL'
                              name='href'
                              value={tag.href}
                              onChange={(e) => handleTagDataChange(e, index)}
                              required={tag.addLink}
                            />
                          ) : (
                            <p>No URL</p>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            className={'removeTag'}
                            onClick={() => removeTag(index)}
                            size='small'
                            variant='contained'
                            color='secondary'
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={'submitBtnContainer'}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSaveTags}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        {/* Tags Modal */}
      </Fragment>
      <Snackbar
        open={openSnackbarURL}
        autoHideDuration={3000}
        onClose={handleSnackbarCloseURL}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseURL}
        >
          {snackbarMessageURL}
        </MuiAlert>
      </Snackbar>
      {/* snackbar required comment*/}
      <Snackbar
        open={openSnackbarComment}
        autoHideDuration={6000}
        onClose={handleSnackbarCloseComment}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseComment}
        >
          {snackbarMessageComment}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default IssuesDataTable;

IssuesDataTable.propTypes = {
  _id: PropTypes.string,
  issuesSearch: PropTypes.object,
  isFindingsNew: PropTypes.bool,
  reqComment: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onSelectedIdsChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};
