import React from 'react';
import {Fragment} from 'react';
import AppCard from '@crema/core/AppCard';
import SingleReport from './SingleReport/SingleReport';
import './Reports.css';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {onGetReportsData, onRefreshReports} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {Box} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  GET_CUR_REPORT_ID,
  GET_REPORTS_CUR_PAGE,
  GET_REPORTS_PAGE_COUNT,
} from 'shared/constants/ActionTypes';
import NewScan from './NewScan/NewScan';
import PropTypes from 'prop-types';
import UploadScan from './UploadScan/UploadScan';
import TextField from '@mui/material/TextField';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from '@mui/material/FormControl';
import DatePicker from '@mui/lab/DatePicker';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import {onGetUsers} from 'redux/actions';

const Reports = ({viewIssues, writeable, readable}) => {
  let {org_id} = useParams();

  const dispatch = useDispatch();
  const reportsData = useSelector(({reports}) => reports.reportsData);
  const reportsPageCount = useSelector(({reports}) => reports.reportsPageCount);
  const reportsCurPage = useSelector(({reports}) => reports.reportsCurPage);
  const lastScanStatus = useSelector(({reports}) => reports.lastScanStatus);
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);
  const users = useSelector(({common}) => common.users);
  const logs = useSelector(({roles}) => roles.logs);

  useEffect(() => {
    dispatch({type: GET_REPORTS_CUR_PAGE, payload: reportsCurPage});
    dispatch({type: GET_REPORTS_PAGE_COUNT, payload: 1});
    dispatch(onGetReportsData({org_id: org_id, page: reportsCurPage}));
    dispatch(onGetUsers());
  }, [dispatch, org_id]);

  useEffect(() => {
    dispatch({
      type: GET_CUR_REPORT_ID,
      payload: reportsData?.length ? reportsData[0]?._id : null,
    });
  }, [reportsData]);

  const lastScanInterval = () => {
    dispatch(onRefreshReports({org_id, page: reportsCurPage}));
  };

  useEffect(() => {
    const interval = setInterval(() => lastScanInterval(), 60000);

    return () => {
      clearInterval(interval);
    };
  }, [lastScanStatus, org_id]);

  function paginationChange(event, value) {
    dispatch({type: GET_REPORTS_CUR_PAGE, payload: value});
    dispatch(
      onGetReportsData({
        org_id: org_id,
        page: value,
      }),
    );
  }

  const clearFilters = () => {
    setScanName('');
    setSelectedUsers([]);
    setSelectedTags([]);
    setSelectedSource([]);
    setScanType([]);
    setFromDate(null);
    setToDate(null);
    setSecurityGate([]);
    setSelectedTags([]);
  };

  //Filter
  const [filterVisible, setFilterVisible] = useState(false);

  const toggleFilterContainer = () => {
    setFilterVisible(!filterVisible);
  };

  const [scanName, setScanName] = useState('');

  const handleScanNameChange = (event) => {
    setScanName(event.target.value);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectChange = (_, value) => {
    setSelectedUsers(value);
  };

  const availableUsers = users
    ? users
        .map((user) => user.email)
        .filter((email) => !selectedUsers.includes(email))
    : [];

  const [selectedTags, setSelectedTags] = useState([]);

  const handleSelectChangeTags = (_, newValue) => {
    if (Array.isArray(newValue)) {
      setSelectedTags(newValue);
    }
  };

  const isOptionDisabledTags = (option) => {
    return selectedTags.some((selected) => selected.value === option.value);
  };

  const optionsTags = (reportsData ?? []).flatMap((report) =>
    (report.customFields ?? []).map((field) => ({
      label: `${field.fieldName} : ${field.fieldValue}`,
      value: `${field.fieldName} : ${field.fieldValue}`,
    })),
  );

  const [selectedSource, setSelectedSource] = useState([]);

  const handleChange = (event, newValue) => {
    setSelectedSource(newValue);
  };

  const sourceOptions = [
    {label: 'Gitlab', value: 'gitLab'},
    {label: 'Github', value: 'gitHub'},
    {label: 'BitBucket', value: 'bitbucket'},
    {label: 'Scheduled Scan', value: 'scheduledscan'},
    {label: 'DigitSec Web', value: 'digitsecWeb'},
    {label: 'Azure DevOps', value: 'azure'},
    {label: 'DigitSec VSCode Extension', value: 'vscode'},
    {label: 'DigitSec SFDX Plugin', value: 'sfdxplugin'},
    {label: 'DigitSec CLI', value: 'cli'},
    {label: 'Copado Essentials', value: 'copadoEssentials'},
    {label: 'Copado Flow', value: 'copadoFlow'},
    {label: 'Copado Branch Scan (Github)', value: 'copado|gitHub'},
    {label: 'Copado Branch Scan (Gitlab)', value: 'copado|gitLab'},
    {label: 'Copado Branch Scan (Bitbucket)', value: 'copado|bitbucket'},
    {label: 'Copado Branch Scan (Azure DevOps)', value: 'copado|azure'},
  ];

  const isOptionDisabledSource = (option) => {
    return selectedSource.some((selected) => selected.value === option.value);
  };

  const [scanType, setScanType] = useState([]);

  const typeOptions = [
    {label: 'SAST', value: 'SAST'},
    {label: 'IAST', value: 'IAST'},
    {label: 'SCA', value: 'SCA'},
    {label: 'CONFIG', value: 'CONFIG'},
    {label: 'THIRD PARTY', value: '3rdPartyPackages'},
    {label: 'QUALITY', value: 'QUALITY'},
  ];

  const isOptionDisabledType = (option) => {
    return scanType.some((selected) => selected.value === option.value);
  };

  const handleScanTypeChange = (event, newValue) => {
    setScanType(newValue);
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const options = [
    {value: 'null', label: 'Not configured'},
    {value: 'true', label: 'Passed'},
    {value: 'false', label: 'Failed'},
  ];

  const [securityGate, setSecurityGate] = useState([]);

  const handleSecurityGateChange = (event, newValue) => {
    setSecurityGate(newValue);
  };

  const isOptionDisabledSecurity = (option) => {
    if (Array.isArray(securityGate)) {
      return securityGate.some(
        (selectedOption) => selectedOption.value === option.value,
      );
    } else {
      console.error;
    }
  };

  useEffect(() => {
    const storedScanName = localStorage.getItem('scanName');
    const storedSelectedUsers = localStorage.getItem('selectedUsers');
    const storedSelectedSource = localStorage.getItem('selectedSource');
    const storedScanType = localStorage.getItem('scanType');
    const storedFromDate = localStorage.getItem('fromDate');
    const storedToDate = localStorage.getItem('toDate');
    const storedSecurityGate = localStorage.getItem('securityGate');
    const storedSelectedTags = localStorage.getItem('selectedTags');
    const storedFilterVisible = localStorage.getItem('filterVisible');

    if (storedScanName) {
      setScanName(storedScanName);
    }

    if (storedSelectedUsers) {
      setSelectedUsers(JSON.parse(storedSelectedUsers));
    }

    if (storedSelectedSource) {
      setSelectedSource(JSON.parse(storedSelectedSource));
    }

    if (storedScanType) {
      setScanType(JSON.parse(storedScanType));
    }

    if (storedFromDate) {
      setFromDate(new Date(storedFromDate));
    }

    if (storedToDate) {
      setToDate(new Date(storedToDate));
    }

    if (storedSecurityGate) {
      const securityGateParse = JSON.parse(storedSecurityGate);

      if (Array.isArray(securityGateParse)) {
        setSecurityGate(securityGateParse);
      }
    }
    if (storedSelectedTags) {
      setSelectedTags(JSON.parse(storedSelectedTags));
    }

    if (storedFilterVisible) {
      setFilterVisible(JSON.parse(storedFilterVisible));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('scanName', scanName);
    localStorage.setItem('selectedUsers', JSON.stringify(selectedUsers));
    localStorage.setItem('selectedSource', JSON.stringify(selectedSource));
    localStorage.setItem('scanType', JSON.stringify(scanType));

    if (fromDate instanceof Date && !isNaN(fromDate.getTime())) {
      localStorage.setItem('fromDate', fromDate.toISOString());
    } else {
      localStorage.removeItem('fromDate');
    }

    if (toDate instanceof Date && !isNaN(toDate.getTime())) {
      localStorage.setItem('toDate', toDate.toISOString());
    } else {
      localStorage.removeItem('toDate');
    }

    localStorage.setItem('securityGate', JSON.stringify(securityGate));
    localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
    localStorage.setItem('filterVisible', JSON.stringify(filterVisible));
  }, [
    scanName,
    selectedUsers,
    selectedSource,
    scanType,
    fromDate,
    toDate,
    securityGate,
    selectedTags,
  ]);

  const handleUnload = () => {
    setFilterVisible(false);
    clearFilters();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const filteredReports =
    reportsData && reportsData.length > 0
      ? reportsData.filter((item) => {
          const filterByName = item.name
            .toLowerCase()
            .includes(scanName.toLowerCase());
          const filterBySource =
            selectedSource.length === 0 ||
            selectedSource.some((option) => item.source.includes(option.value));

          const filterBySecurityGate =
            Array.isArray(securityGate) &&
            (securityGate.length === 0 ||
              securityGate.some((selectedGate) => {
                if (selectedGate.value === 'null') {
                  return item.isSecurityGatePassed === null;
                } else if (selectedGate.value === 'true') {
                  return item.isSecurityGatePassed === true;
                } else if (selectedGate.value === 'false') {
                  return item.isSecurityGatePassed === false;
                } else {
                  return false;
                }
              }));

          const filterByScanType =
            scanType.length === 0 ||
            scanType.every((selectedType) =>
              item.scan_type.includes(selectedType.value),
            );

          const filterByDate =
            (fromDate
              ? new Date(item.Initiated_Scan_date) >= fromDate
              : true) &&
            (toDate ? new Date(item.Initiated_Scan_date) <= toDate : true);

          const filterByInitiatedBy =
            selectedUsers.length === 0 ||
            selectedUsers.includes(item.initiated_by);

          const filterByTags =
            selectedTags.length === 0 ||
            selectedTags.some((selectedTag) =>
              item.customFields.some(
                (field) =>
                  selectedTag.label ===
                  `${field.fieldName} : ${field.fieldValue}`,
              ),
            );

          return (
            filterByName &&
            filterBySource &&
            filterByScanType &&
            filterBySecurityGate &&
            filterByDate &&
            filterByInitiatedBy &&
            filterByTags
          );
        })
      : [];

  return (
    <Fragment>
      <AppCard sxStyle={{}} title=''>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '15px',
          }}
        >
          <div className='filter-icon-container'>
            <h3>Workspace Scan Details</h3>
            {/* <SearchIcon className='filter-icon' /> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '3px',
              }}
              onClick={toggleFilterContainer}
            >
              <FilterListIcon className='filter-icon' />
              <p>Filters</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '3px',
              }}
              onClick={clearFilters}
            >
              <RestartAltIcon className='filter-icon' />
              <p>Clear </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {writeable &&
              singleOrg &&
              !singleOrg?.instanceurl?.includes('Scratch Org (Offline)') &&
              !singleOrg?.instanceurl?.includes('scratch_offline') && (
                <NewScan />
              )}
            {writeable && <UploadScan />}
          </div>
        </div>

        {filterVisible && (
          <div className='filters-container'>
            <FormControl className='item' sx={{width: 300}}>
              <TextField
                style={{marginTop: '5px'}}
                id='demo-simple-select'
                value={scanName}
                label='Title'
                onChange={handleScanNameChange}
              ></TextField>
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              <Autocomplete
                multiple
                id='multi-select-chips'
                value={selectedUsers}
                onChange={handleSelectChange}
                options={availableUsers}
                renderInput={(params) => (
                  <div style={{marginTop: '5px'}}>
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Initiated by'
                    />
                  </div>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{fontSize: '10px'}}
                      key={index}
                      label={option}
                      {...getTagProps({index})}
                      onDelete={() => {
                        const newValues = [...selectedUsers];
                        newValues.splice(index, 1);
                        setSelectedUsers(newValues);
                      }}
                    />
                  ))
                }
                style={{maxHeight: 150, overflowY: 'auto'}}
              />
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              <Autocomplete
                multiple
                id='multi-select-chips'
                options={sourceOptions.filter(
                  (option) => !isOptionDisabledSource(option),
                )}
                value={selectedSource}
                onChange={handleChange}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <div style={{marginTop: '5px'}}>
                    <TextField {...params} label='Source' variant='outlined' />
                  </div>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{fontSize: '10px'}}
                      key={option.label}
                      label={option.label}
                      {...getTagProps({index})}
                    />
                  ))
                }
                style={{maxHeight: 150, overflowY: 'auto'}}
              />
            </FormControl>

            <FormControl className='item' sx={{width: 300}}>
              <Autocomplete
                multiple
                id='multi-select-chips'
                value={scanType}
                onChange={handleScanTypeChange}
                options={typeOptions.filter(
                  (option) => !isOptionDisabledType(option),
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <div style={{marginTop: '5px'}}>
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Scan Type'
                    />
                  </div>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option) => (
                    <Chip
                      sx={{fontSize: '10px'}}
                      key={option.value}
                      label={option.label}
                      {...getTagProps({})}
                      onDelete={() => {
                        const newValues = scanType.filter(
                          (item) => item.value !== option.value,
                        );
                        setScanType(newValues);
                      }}
                    />
                  ))
                }
              />
            </FormControl>
            <FormControl className='item' sx={{width: 300, marginTop: '5px'}}>
              <DatePicker
                label='From'
                value={fromDate}
                onChange={(date) => setFromDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl className='item' sx={{width: 300, marginTop: '5px'}}>
              <DatePicker
                label='To'
                value={toDate}
                onChange={(date) => setToDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl className='item' sx={{width: 300, marginTop: '5px'}}>
              <Autocomplete
                multiple
                id='security-gate-autocomplete'
                options={options.filter(
                  (option) => !isOptionDisabledSecurity(option),
                )}
                getOptionLabel={(option) => option.label}
                value={securityGate}
                onChange={handleSecurityGateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Security Gate'
                    variant='outlined'
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      style={{
                        margin: '2px',
                        fontSize: '10px',
                        important: 'true',
                      }}
                      className='chipSecurity'
                      key={option.value}
                      label={option.label}
                      {...getTagProps({index})}
                    />
                  ))
                }
              />
            </FormControl>
            <FormControl className='item' sx={{width: 300}}>
              <Autocomplete
                multiple
                id='autocomplete-tags'
                value={selectedTags}
                onChange={handleSelectChangeTags}
                options={optionsTags.filter(
                  (option) => !isOptionDisabledTags(option),
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <div style={{marginTop: '5px'}}>
                    <TextField {...params} variant='outlined' label='Tags' />
                  </div>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={`${option.label}-${index}`}>
                      <Chip
                        sx={{fontSize: '10px'}}
                        label={option.label}
                        {...getTagProps({index})}
                        onDelete={() => {
                          if (Array.isArray(selectedTags)) {
                            const newValues = [...selectedTags];
                            newValues.splice(index, 1);
                            setSelectedTags(newValues);
                          }
                        }}
                      />
                    </div>
                  ))
                }
                style={{maxHeight: 150, overflowY: 'auto'}}
              />
            </FormControl>
            {/* <Button
              className='search-btn'
              variant='contained'
              title='Clear filters'
              onClick={clearFilters}
            >
              <RestartAltIcon />
            </Button> */}
          </div>
        )}

        {readable &&
          filteredReports?.length > 0 &&
          filteredReports.map((item) => {
            return (
              <SingleReport
                key={item._id}
                Critical={item.Critical}
                High={item.High}
                Medium={item.Medium}
                Low={item.Low}
                Info={item.Info}
                ScanDate={item.Initiated_Scan_date}
                status={item.status}
                message={item.message ? item.message : 'undefined'}
                _id={item._id}
                reportsCurPage={reportsCurPage}
                isSecurityGatePassed={item.isSecurityGatePassed}
                name={item.name}
                customFields={item.customFields}
                viewIssues={viewIssues}
                writeable={writeable}
                source={item.source}
                logsRead={logs?.read}
                initiatedBy={item.initiated_by}
                scan_type={item.scan_type}
                show_new_findings={item.configs?.show_new_findings?item.configs.show_new_findings:false}
              />
            );
          })}

        {reportsData && reportsData?.length === 0 && readable && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '150px',
              alignItems: 'center',
            }}
          >
            <h2>No Scans Found</h2>
          </div>
        )}

        {reportsData && reportsData?.length !== 0 && readable === false && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '150px',
              alignItems: 'center',
            }}
          >
            <h2>You do not have access to view scans</h2>
          </div>
        )}

        {reportsPageCount > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Pagination
              count={reportsPageCount}
              shape='rounded'
              onChange={paginationChange}
              page={reportsCurPage}
              color='primary'
            />
          </Box>
        )}
      </AppCard>
    </Fragment>
  );
};

export default Reports;

Reports.propTypes = {
  viewIssues: PropTypes.bool,
  writeable: PropTypes.bool,
  readable: PropTypes.bool,
};
