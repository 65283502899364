import {
  SFDC_PERMISSION,
  SFCC_PERMISSION,
  COMPANY_PERMISSION,
  USER_PERMISSION,
  LOGS,
  GLOBAL_SECURITYGATE,
  GLOBAL_SCAN_SETTINGS,
  GLOBAL_INTEGRATIONS,
  GLOBAL_SCHEDULER,
} from './ActionTypes';

export const authRole = {
  admin: ['GLOBAL_ADMIN'],
  user: ['user', 'GLOBAL_ADMIN'],
};

export const RoutePermittedRole = {
  admin: 'GLOBAL_ADMIN',
  user: 'user',
};
export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const initialUrl = '/salesforce'; // this url will open after login

export const defaultError = 'Something went wrong! Please try again';

export const GlobalPermissions = [
  SFDC_PERMISSION,
  SFCC_PERMISSION,
  COMPANY_PERMISSION,
  USER_PERMISSION,
  LOGS,
  GLOBAL_SECURITYGATE,
  GLOBAL_SCAN_SETTINGS,
  GLOBAL_INTEGRATIONS,
  GLOBAL_SCHEDULER,
];
