import React, {Fragment, useState} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from './ExcelDownload.module.css';
import {useDispatch} from 'react-redux';
import {onGetExcelData} from 'redux/actions';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';

const excelOptions = [
  {label: 'Select All', value: 'select-all'},
  {label: 'Id', value: '_id'},
  {label: 'Issue Id', value: 'issueId'},
  {label: 'Status', value: 'status'},
  {label: 'Scan Id', value: 'scanId'},
  {label: 'Severity', value: 'severity'},
  {label: 'Recent', value: 'isnew'},
  {label: 'OWASP', value: 'owasp'},
  {label: 'CWE', value: 'cweViolation'},
  {label: 'Compliance', value: 'complianceViolations'},
  {label: 'Line', value: 'line'},
  {label: 'Column', value: 'col'},
  {label: 'File Name', value: 'filename'},
  {label: 'Issue Type', value: 'issuetype'},
  {label: 'Date Found', value: 'founddate'},
  {label: 'Date Updated', value: 'updateddate'},
  {label: 'Issue', value: 'issue'},
  {label: 'Description', value: 'description'},
  {label: 'Assigned', value: 'assigned'},
  {label: 'History', value: 'history'},
  {label: 'Hash', value: 'hash'},
  {label: 'Remediation Guidance', value: 'remediationguidance'},
];

const ExcelDownload = ({excelModal, handleModalClose, _id, orgId, show_new_findings}) => {
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);

  const excelHandleModalProceed = () => {
    if (selectedValues.length == 0) {
      return dispatch({
        type: FETCH_ERROR,
        payload: 'Please Select atleast 1 Excel field',
      });
    }
    let arrayVal = selectedValues
      .map((item) => item.value)
      .filter((item) => item != 'select-all');
    dispatch(onGetExcelData({_id, reportType: 'csv', fields: arrayVal, orgId, show_new_findings}));
    handleModalClose();
  };

  const excelChangeHandler = (event, value) => {
    // setSelectedValues(value.map((item) => item.value));
    if (value.find((val) => val.value === 'select-all')) {
      // console.log( excelOptions.filter((item) => item.value != 'select-all'));
      setSelectedValues(
        excelOptions.filter((item) => item.value != 'select-all'),
      );
    } else setSelectedValues(value);
  };

  return (
    <Fragment>
      <ModalContainer
        title=' Download Reports'
        open={excelModal}
        handleModalClose={handleModalClose}
        handleModalProceed={excelHandleModalProceed}
        proceedButtonText='Download'
        closeButtonText='Close'

      >
        <p>Please select the fields you would like in the report.</p>
        <div className={styles.autoCompleteContainer}>
          <Autocomplete
            multiple
            limitTags={1}
            disablePortal
            id='combo-box-demo'
            options={excelOptions}
            value={selectedValues}
            onChange={excelChangeHandler}
            className={styles.autoComplete}
            disableCloseOnSelect
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                className={styles.textField}
                {...params}
                variant='outlined'
                label='Excel Fields'
                size='small'
                name='assign'
              />
            )}
          />
        </div>
      </ModalContainer>
    </Fragment>
  );
};

export default ExcelDownload;
ExcelDownload.propTypes = {
  excelModal: PropTypes.bool,
  handleModalClose: PropTypes.func,
  _id: PropTypes.string,
  orgId: PropTypes.string,
  show_new_findings:PropTypes.bool,
};
