import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppCard} from '@crema';
import './Roles.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import {DataGrid} from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import AddNewRoles from 'components/setup/User/AddNewRole/AddNewRole';
// import PropTypes from 'prop-types';
import {getAllRoles} from 'redux/actions/Roles';
import {getAllPermissions} from 'redux/actions/Roles';
import {useDispatch} from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Modal from '@mui/material/Modal';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import {TextField} from '@mui/material';
// import {InputAdornment} from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {deleteRole} from 'redux/actions/Roles';
import {CSVLink} from 'react-csv';
import GetAppIcon from '@mui/icons-material/GetApp';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';

const Roles = () => {
  const dispatch = useDispatch();
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);
  const users = useSelector(({common}) => common.users);


  const disabledRoles = [
    'Admin',
    'Environment Manager',
    'Workspace Manager',
    'Environment User',
    'Workspace User',
    'Environment User Read Only',
    'Workspace User Read Only',
  ];

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
  }, []);

  // const {userInfo} = useSelector(({common}) => common);

  const rows =
    allRoles && Array.isArray(allRoles) && allPermissions
      ? allRoles.map((role) => {
          const matchingPermission =
            role.permissionsId &&
            allPermissions.find(
              (permission) => permission._id === role.permissionsId,
            );

          const permissionsData = matchingPermission
            ? matchingPermission.permissions.map((item) => ({
                object: item.object,
                permissions: `${item.permissions.join(', ')}`,
              }))
            : null;

          return {
            id: role._id,
            roleName: role.name,
            permissions: permissionsData,
            roleType: role.type,
            permissionsId: role.permissionsId,
          };
        })
      : [];

  const uniquePermissionObjects = new Set();

  (allRoles || []).forEach((role) => {
    const matchingPermission =
  role.permissionsId &&
  (allPermissions || []).find(
    (permission) => permission._id === role.permissionsId,
  );


    matchingPermission?.permissions.forEach((perm) => {
      uniquePermissionObjects.add(perm.object);
    });
  });

  const rowsCSV = (allRoles || []).map((role) => {
    const matchingPermission =
      role.permissionsId &&
      (allPermissions || []).find(
        (permission) => permission._id === role.permissionsId,
      );
  
    let transformedRole = {
      id: role._id,
      roleName: role.name,
      roleType: role.type === 'ORG_LEVEL' ? 'Workspace' : role.type === 'GLOBAL' ? 'Environment' : '',
      permissionsId: role.permissionsId,
    };
  

    uniquePermissionObjects.forEach((object) => {
      const permissionsData =
        matchingPermission?.permissions
          .filter((perm) => perm.object === object)
          .map((perm) => perm.permissions.join(', '))
          .join('; ') || '';
      transformedRole[object] = permissionsData;
    });
    
    return transformedRole;
    });
    
    const getCustomLabel = (object) => {
      const objectLabel = {
        'user': 'User',
        'sfdcinfo': 'SFDC Orgs',
        'sfccinfo': 'SFCC Orgs',
        'company': 'Company',
        'globalintegrations': 'Environment Integrations',
        'globalscansettings': 'Environment Scan Settings',
        'globalsecuritygate': 'Environment Security Gate',
        'logs': 'Logs',
        'scheduler': 'Scan Scheduler',
        'scan': 'Scans',
        'finding': 'Issues',
        'team': 'Team',
        'orgsecuritygate': 'Workspace Security Gate',
        'orgintegrations': 'Workspace Integrations',
        'orgscansettings': 'Workspace Scan Settings',
        'orgscanrules': 'Workspace Scan Rules',
        'orgignorelist': 'Workspace Ignorelist',
        'orgscheduler': 'Workspace Scheduler',
        'reports': 'Reports'
      };
    
      return objectLabel[object] || (object.charAt(0).toUpperCase() + object.slice(1));
    };
    
    const dynamicHeaders = Array.from(uniquePermissionObjects).map((object) => ({
      label: getCustomLabel(object),
      key: object,
    }));

  const rolesData = [
    {label: 'Role Name', key: 'roleName'},
    {label: 'Role Type', key: 'roleType'},
    ...dynamicHeaders,
  ];

  const columns = [
    {field: 'roleName', headerName: 'Role Name', width: 200},
    {
      field: 'roleType',
      headerName: 'Role Type',
      width: 150,
      valueGetter: (params) => {
        const roleType = params.value;

        if (roleType === 'GLOBAL') {
          return 'Environment';
        } else if (roleType === 'ORG_LEVEL') {
          return 'Workspace';
        }

        return roleType;
      },
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      width: 850,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            overflowX: 'auto',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {params.row.permissions &&
            params.row.permissions.map((item, index) => (
              <Tooltip
                key={index}
                title={
                  <div className='tooltipContainer'>
                    {item.permissions === 'READ' ? (
                      <RemoveRedEyeIcon className='tooltipIcons' />
                    ) : (
                      <EditIcon className='tooltipIcons' />
                    )}
                    <span>
                      {item.permissions === 'READ'
                        ? 'READ ONLY'
                        : item.permissions}
                    </span>
                  </div>
                }
              >
                <Chip
                  style={{
                    fontSize: '12px',
                    height: '25px',
                    margin: '2px',
                    cursor: 'pointer',
                  }}
                  label={
                    item.object === 'sfdcinfo'
                      ? 'SFDC Orgs'
                      : item.object === 'sfccinfo'
                      ? 'SFCC Orgs'
                      : item.object === 'company'
                      ? 'Company'
                      : item.object === 'user'
                      ? 'User'
                      : item.object === 'globalintegrations'
                      ? 'Environment Integrations'
                      : item.object === 'globalscansettings'
                      ? 'Environment Scan Settings'
                      : item.object === 'globalsecuritygate'
                      ? 'Environment Security Gate'
                      : item.object === 'logs'
                      ? 'Logs'
                      : item.object === 'scheduler'
                      ? 'Scan Scheduler'
                      : item.object === 'scan'
                      ? 'Scans'
                      : item.object === 'finding'
                      ? 'Issues'
                      : item.object === 'team'
                      ? 'Team'
                      : item.object === 'orgsecuritygate'
                      ? 'Workspace Security Gate'
                      : item.object === 'orgintegrations'
                      ? 'Workspace Integrations'
                      : item.object === 'orgscansettings'
                      ? 'Workspace Scan Settings'
                      : item.object === 'orgscanrules'
                      ? 'Workspace Scan Rules'
                      : item.object === 'orgignorelist'
                      ? 'Workspace Ignorelist'
                      : item.object === 'reports'
                      ? 'Reports'
                      : item.object === 'orgscheduler'
                      ? 'Workspace Scheduler'
                      : item.object === 'findingSeverity'
                      ? 'Issues > Severity'
                      : item.object === 'findingStatus'
                      ? 'Issues > Status'
                      : item.object === 'findingTag'
                      ? 'Issues > Tags'
                      : item.object === 'findingComment'
                      ? 'Issues > Comments'
                      : item.object === 'findingAssignment'
                      ? 'Issues > Assignment'
                      : item.object
                  }
                  color={
                    item.permissions.includes('READ') &&
                    item.permissions.includes('WRITE')
                      ? 'primary'
                      : 'default'
                  }
                />
              </Tooltip>
            ))}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        const isMenuDisabled = disabledRoles.includes(params.row.roleName);
        return (
          <div>
            <IconButton
              disabled={isMenuDisabled}
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={(e) => handleClick(e, params)}
              className={isMenuDisabled ? 'disabled-button' : ''}
            >
              <MoreHorizIcon
                sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
                color={isMenuDisabled ? 'disabled' : 'primary'}
              />
            </IconButton>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  boxShadow: 'none',
                  border: '1px solid #e3d1d1',
                },
              }}
            >
              <MenuItem onClick={() => editModalOpen(params)}>Edit</MenuItem>
              <MenuItem
                onClick={() => {
                  deleteModalOpen(params);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport printOptions={{disableToolbarButton: true}} />
        </GridToolbarContainer> */}
      </GridToolbarContainer>
    );
  }
  const [rolesModal, setRolesModal] = useState(false);

  const roleModalClose = () => {
    setRolesModal(false);
    setEditMode(false);
  };
  const rolesModalOpen = () => {
    setRolesModal(true);
    setRolesName('');
    setRolesType('');
  };

  //3 dots menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [rolesName, setRolesName] = useState('');
  const [rolesType, setRolesType] = useState('');
  const [roleId, setRoleId] = useState('');
  const [permissionsId, setPermissionsId] = useState('');
  const [currentPermissionsList, setCurrentPermisssionList] = useState([]);

  const handleClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setRolesName(params.row.roleName);
    setRolesType(params.row.roleType);
    if (params.row.permissions) {
      const modifiedPermissions = params.row.permissions.map((permission) => {
        const {object: permissionName, permissions: access} = permission;
        return {permission: permissionName, access};
      });

      setCurrentPermisssionList(modifiedPermissions);
    } else {
      console.warn('');
    }

    setRoleId(params.row.id);
    setPermissionsId(params.row.permissionsId);
  };

  // useEffect(() => {
  //   console.log('Role Name After Click: ', rolesName);
  //   console.log('Role Type After Click: ', rolesType);
  //   console.log('Permissions After Click: ', currentPermissionsList);
  //   console.log('Role ID After Click: ', roleId);
  //   console.log('Role Permissions ID After Click: ', permissionsId);
  // }, [rolesName, rolesType, currentPermissionsList, roleId, permissionsId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteIcon = {fontSize: 60};

  const deleteModalOpen = () => {
    setAnchorEl(null);
    setDeleteModal(true);
  };
  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const editModalOpen = () => {
    setEditMode(true);
    setAnchorEl(null);
    setRolesModal(true);
  };

  const handleDeleteRole = () => {
    const assignedRoleIdsEnv = Array.from(new Set(users.map((user)=>{return user.roleId.toString();})));
    const assignedRoleIdsOrg = new Set();
    users.map((user)=>{
      user.salesforceOrgs.map((orgEntry)=>{
          if(orgEntry.roleId && orgEntry.access === true){
               assignedRoleIdsOrg.add(orgEntry.roleId.toString());
          }else 
          {
              assignedRoleIdsOrg.add('');
          }
      });
  });
  let roleIdsToAvoid = assignedRoleIdsEnv.concat(Array.from(assignedRoleIdsOrg));
    if (roleIdsToAvoid.indexOf(roleId) !== -1) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'This role is currently assigned to a user',
      });
      return;
    }

    dispatch(deleteRole(roleId, permissionsId));
    dispatch(getAllRoles());
    setDeleteModal(false);
  };

  //page size
  const [pageSize, setPageSize] = useState(25);

  return (
    <AppCard>
      <div className='userHeader'>
        <div className='userHeaderTitle'>
          <AccountCircleIcon></AccountCircleIcon>
          <h4>Manage Roles</h4>
        </div>
        <Box my={{xs: 3, sm: 0, md: 0}}>
          <div className='manageUserButton'>
            {/* <div>
                 <TextField
                   label='Search User'
                   InputProps={{
                     endAdornment: (
                       <InputAdornment position='end'>
                         <PersonSearchIcon />
                       </InputAdornment>
                     ),
                   }}
                   size='small'
                 />
               </div> */}
            <Button
              variant='contained'
              onClick={rolesModalOpen}
              startIcon={<AddIcon />}
              // disabled={!writeable}
            >
              Add Roles
            </Button>
          </div>
        </Box>
      </div>
      <div className='dt-container'>
        <div className='export-container'>
          <CSVLink
            data={rowsCSV}
            headers={rolesData}
            filename={'DigitSec_roles'}
          >
            <Tooltip title='Export Roles'>
              <GetAppIcon style={{color: '#0a8fdc', cursor: 'pointer'}} />
            </Tooltip>
          </CSVLink>
        </div>
        <div style={{height: 500, width: '100%', overflow: 'auto'}}>
          <DataGrid
            rowHeight={130}
            id='logs-dt'
            rows={rows}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}
            // hideFooterPagination={true}
            // hideFooter={true}
            hideFooterSelectedRowCount={true}
            columns={[
              ...columns,
              {field: 'permissions', filterable: false},
              {field: 'actions', filterable: false},
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal',
                justifyContent: 'center',
                textAlign: 'center !important',
              },
            }}
          />
        </div>
      </div>
      <AddNewRoles
        rolesModal={rolesModal}
        rolesModalClose={roleModalClose}
        editMode={editMode}
        rolesName={rolesName}
        rolesType={rolesType}
        roleId={roleId}
        title='Edit Role'
        currentPermissionsList={currentPermissionsList}
      />
      <Modal open={deleteModal} className='deleteUserContainer'>
        <div
          className='deleteUserContainer'
          style={{
            backgroundColor: 'white',
            height: '245px',
            width: '800px',
            borderRadius: '5px',
          }}
        >
          <div>
            <DeleteForeverSharpIcon color='secondary' sx={deleteIcon} />
          </div>
          <h3>Are you sure you want to delete this role?</h3>

          <div className='deleteBtnContainer'>
            <Button
              onClick={deleteModalClose}
              variant='contained'
              color='secondary'
            >
              No
            </Button>
            <Button
              onClick={handleDeleteRole}
              variant='contained'
              color='primary'
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </AppCard>
  );
};

export default Roles;

// Roles.propTypes = {
//     writeable: PropTypes.bool,
//   };
