import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styles from './CommentSection.module.css';
import {onPutCommentDetail} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import AddTags from './Add Tags/AddTags';
import {useParams} from 'react-router-dom';
import MentionInput from 'components/mentioninput/MentionInput';
import {mentionUser} from 'transformers/Users';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function CommentSection({_id, detailsTabChange}) {
  const currOrgName = useSelector(({dashboard}) => dashboard.curOrgName);
  let {report_id, issue_id, org_id} = useParams();

  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const loggedUser = useSelector(({common}) => common.userInfo);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const users = useSelector(({common}) => common.users);
  const dispatch = useDispatch();

  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );

  const [comment, setComment] = useState('');

  const [openSnackbarCommentS, setOpenSnackbarCommentS] = useState(false);
  const [snackbarMessageCommentS, setSnackbarMessageCommentS] = useState('');

  const handleSnackbarCloseCommentS = () => {
    setOpenSnackbarCommentS(false);
  };


  function onSubmitComment(event) {
    event.preventDefault();
  
    let obj = {
      comment: comment,
      _scanID: report_id,
    };
  
    if (!comment.trim()) {
      setOpenSnackbarCommentS(true);
      setSnackbarMessageCommentS("Comment field is required");
      return;
    }
  
    dispatch(onPutCommentDetail({
      _id,
      obj,
      issue_id,
      issuesCurPage,
      report_id,
      org_id,
      org_name: currOrgName,
      pageSize,
      curIssuesFilterState
    }));
  
    detailsTabChange(event, 0);
  }
  

  // Tags Modal
  const [tagsModal, setTagsModal] = useState(false);
  const tagsModalClose = () => {
    setTagsModal(false);
  };
  const tagsModalOpen = () => {
    setTagsModal(true);
  };

  const mentionHandler = (event, newValue, newPlainTextValue) => {
    setComment(newPlainTextValue);
  };

  //Filtered orgs

  // const filteredRoles = allRoles.map((role) => ({
  //   roleId: role._id,
  //   permissionsId: role.permissionsId,
  // }));

  // const filteredPermissions = (allPermissions || []).map((permissions) => ({
  //   permissionsId: permissions._id,
  //   permissions: permissions.permissions,
  // }));

  // const filteredOrgs = loggedUser.usr.salesforceOrgs.map((org) => ({
  //   roleId: org.roleId,
  //   sfdc: org.sfdc,
  //   _id: org._id,
  //   sfdcId: org.sfdcId,
  // }));

  const currentOrg = loggedUser.usr.salesforceOrgs.find(
    (org) => org.sfdcId === org_id,
  );

  if (!currentOrg) {
    console.error('Org not found');
    return null;
  }

  const roleForCurrentOrg = allRoles.find(
    (role) => role._id === currentOrg.roleId,
  );

  if (!roleForCurrentOrg) {
    console.error('role not found');
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(
    (permission) => permission._id === roleForCurrentOrg.permissionsId,
  );

  const findingTagPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingTag');

  const findingCommentPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingComment');

  const isDisabledTag =
    findingTagPermission &&
    findingTagPermission.permissions.length === 1 &&
    findingTagPermission.permissions.includes('READ');

  const isDisabledComment =
    findingCommentPermission &&
    findingCommentPermission.permissions.length === 1 &&
    findingCommentPermission.permissions.includes('READ');

  return (
    <>
      <div style={{padding: 14}} className='App'>
        <form onSubmit={onSubmitComment}>
            <MentionInput
            textHandler={mentionHandler}
            data={mentionUser(users)}
            inputText={comment}
            placeholder='Add a Comment'
            rows='8'
          />
          <div className={styles.commentTagContainerBtn}>
            <Button
              variant='contained'
              type='submit'
              disabled={isDisabledComment}
            >
              Post Comment
            </Button>
            <Button
              disabled={isDisabledTag}
              variant='contained'
              onClick={tagsModalOpen}
            >
              Add Tags
            </Button>
          </div>
        </form>
      </div>
      <AddTags
        _id={_id}
        tagsModal={tagsModal}
        tagsModalClose={tagsModalClose}
        detailsTabChange={detailsTabChange}
      />
        <Snackbar
       open={openSnackbarCommentS}
       autoHideDuration={3000}
       onClose={handleSnackbarCloseCommentS}
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'right',
       }}
     >
       <MuiAlert
         elevation={6}
         variant='filled'
         severity='error'
         onClose={handleSnackbarCloseCommentS}
       >
         {snackbarMessageCommentS}
       </MuiAlert>
     </Snackbar>
    </>
  );
}

export default CommentSection;
CommentSection.propTypes = {
  comments: PropTypes.array,
  _id: PropTypes.string,
  detailsTabChange: PropTypes.func,
};
