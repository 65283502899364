import React, {Fragment, useState, useEffect} from 'react';
import AppCard from '@crema/core/AppCard';
import {InputLabel} from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styles from './DetailsForm.module.css';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {
  onUpdateIssueDetail,
  onDeleteTag,
  onUpdateIssueDetailComment,
} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CommentIcon from '@mui/icons-material/Comment';
import {onGetGlobalConfigs} from 'redux/actions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {getAllRoles} from 'redux/actions/Roles';
import {getAllPermissions} from 'redux/actions/Roles';

const statusOptions = [
  {label: 'Active', value: 'Active'},
  {label: 'Resolved', value: 'Resolved'},
  {label: 'Fixed', value: 'Fixed'},
  {label: 'Suppressed', value: 'Suppressed'},
  {label: 'False-positive', value: 'False-positive'},
  {label: 'Re-activated', value: 'Re-activated'},
  {label: 'Accepted', value: 'Accepted'},
  {label: 'Ignored', value: 'Ignored'},
  {label: 'Duplicate', value: 'Duplicate'},
];

const severityOptions = [
  {label: 'Critical', value: 'critical'},
  {label: 'High', value: 'high'},
  {label: 'Medium', value: 'medium'},
  {label: 'Low', value: 'low'},
  {label: 'Info', value: 'info'},
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  borderRadius: 5,
  textAlign: 'center',
};

const modalss2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: 450,
  width: 600,
  borderRadius: 5,
  padding: 7,
  overflowY: 'auto',
};

function DetailsForm({
  founddate,
  type,
  status,
  severity,
  assigned,
  _id,
  category,
  cweViolation,
  tags,
  owasp,
  reqComment,
}) {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};

  let {issue_id, report_id, org_id} = useParams();

  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [currentTag, setCurrentTag] = React.useState(null);
  const toggleOpenParent = (tag) => {
    if (tag) setCurrentTag(tag);
    setOpen(!open);
  };

  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const currOrgName = useSelector(({dashboard}) => dashboard.curOrgName);
  const userInfo = useSelector(({common}) => common.userInfo);
  const users = useSelector(({common}) => common.users);
  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );
  useEffect(() => {
    dispatch(onGetGlobalConfigs(true));
  }, []);

  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const loggedUser = useSelector(({common}) => common.userInfo);
  const allPermissions = useSelector(({roles}) => roles.getAllPermissions);

  const [form, setForm] = useState({
    status: status,
    severity: severity,
    assignTo: assigned?.email ? assigned.email : 'Unassigned',
  });

  useEffect(() => {
    setForm({
      status: status,
      severity: severity,
      assignTo: assigned?.email ? assigned.email : 'Unassigned',
    });

    return () => {};
  }, [_id]);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
  }, []);

  const getAssignTo = () => {
    let arr = users?.map((user) => user.email) || [];
    arr.push('Unassigned');
    return arr;
  };

  // const onChangeStatus = (event, value) => {
  //   setForm((cur) => {
  //     return {
  //       ...cur,
  //       status: value?.value,
  //     };
  //   });
  //   setIsModalOpen(true);
  // };

  const onChangeSeverity = (event, value) => {
    setForm((cur) => {
      return {
        ...cur,
        severity: value?.value,
      };
    });
  };

  const onChangeAssign = (event, value) => {
    setForm((cur) => {
      return {
        ...cur,
        assignTo: value,
      };
    });
  };

  const onSubmitForm = () => {
    let obj = {};

    if (status != form.status) {
      obj.status = form.status;
    }
    if (severity != form.severity) {
      obj.severity = form.severity;
      obj.oldSeverity = severity;
    }
    if (assigned?.email != form.assignTo) {
      form.assignTo != 'Unassigned'
        ? (obj.assigned = users?.find((user) => user.email == form.assignTo))
        : (obj.assigned = assigned?.email ? 'Unassigned' : null);
    }

    dispatch(
      onUpdateIssueDetail({
        report_id,
        obj: {_scanID: report_id, ...obj},
        issuesCurPage,
        _id: _id,
        issue_id,
        org_id,
        org_name: currOrgName,
        pageSize,
        curIssuesFilterState,
      }),
    );
    setComment('');
  };

  const onSubmitFormComment = () => {
    let obj = {};

    if (status != form.status) {
      obj.status = form.status;
    }
    if (severity != form.severity) {
      obj.severity = form.severity;
      obj.oldSeverity = severity;
    }
    if (assigned?.email != form.assignTo) {
      form.assignTo != 'Unassigned'
        ? (obj.assigned = users?.find((user) => user.email == form.assignTo))
        : (obj.assigned = assigned?.email ? 'Unassigned' : null);
    }

    dispatch(
      onUpdateIssueDetailComment({
        report_id,
        obj: {
          _scanID: report_id,
          ...obj,
          comment,
          email: userInfo.usr.email,
          date: new Date(),
        },
        issuesCurPage,
        _id: _id,
        issue_id,
        org_id,
        org_name: currOrgName,
        pageSize,
        curIssuesFilterState,
      }),
    );
    setComment('');
  };

  function onMouseOverX(event) {
    event.target.style.cursor = 'pointer';
    event.target.style.color = 'red';
  }

  function onMouseOutX(event) {
    event.target.style.cursor = 'default';
    event.target.style.color = '#feca37';
  }

  const convertTagsChips = (tags) => {
    let divTags = tags?.map((tag, index) => {
      if (tag.href) {
        return (
          <div key={index} className={styles.tagChip}>
            <a
              key={index}
              className={styles.tagChipAction}
              href={tag.href}
              target='_blank'
              rel='noreferrer'
            >
              {tag.fieldName}:{tag.fieldValue}
            </a>
            <a
              onClick={() => {
                toggleOpenParent(tag);
              }}
              onMouseEnter={onMouseOverX}
              onMouseLeave={onMouseOutX}
              className={styles.tagChipDelete}
            >
              x
            </a>
          </div>
        );
      }
      return (
        <div key={index} className={styles.tagChip}>
          {tag.fieldName}:{tag.fieldValue}
          <a
            onClick={() => {
              toggleOpenParent(tag);
            }}
            onMouseEnter={onMouseOverX}
            onMouseLeave={onMouseOutX}
            className={styles.tagChipDelete}
          >
            x
          </a>
        </div>
      );
    });

    return <div className={styles.tagsContainer}>{divTags}</div>;
  };

  const deleteTag = () => {
    toggleOpenParent(null);
    if (currentTag) {
      dispatch(
        onDeleteTag({
          _id,
          tag: currentTag,
          report_id,
          issue_id,
          issuesCurPage,
          org_id,
          org_name: currOrgName,
          pageSize,
          curIssuesFilterState,
        }),
      );
    }
  };

  const owaspURL = (owasp) => {
    switch (owasp) {
      case 'A01:2021-Broken Access Control':
        return 'https://owasp.org/Top10/A01_2021-Broken_Access_Control/';
      case 'A06:2021-Vulnerable and Outdated Components':
        return 'https://owasp.org/Top10/A06_2021-Vulnerable_and_Outdated_Components/';
      case 'A04:2021-Insecure Design':
        return 'https://owasp.org/Top10/A04_2021-Insecure_Design/';
      case 'A03:2021-Injection':
        return 'https://owasp.org/Top10/A03_2021-Injection/';
      case 'A07:2021-Identification and Authentication Failures':
        return 'https://owasp.org/Top10/A07_2021-Identification_and_Authentication_Failures/';
      case 'A02:2021-Cryptographic Failures':
        return 'https://owasp.org/Top10/A02_2021-Cryptographic_Failures/';
      default:
        return '#';
    }
  };

  const url = owaspURL(owasp);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const [openSnackbarComment, setOpenSnackbarComment] = useState(false);
  const [snackbarMessageComment, setSnackbarMessageComment] = useState('');

  const handleSnackbarCloseComment = () => {
    setOpenSnackbarComment(false);
  };

  const handleSave = () => {
    if (!comment.trim()) {
      setOpenSnackbarComment(true);
      setSnackbarMessageComment(`Comment field is required`);
      return;
    } else {
      onSubmitFormComment();
      handleModalClose();
    }
  };

  const initialStatus = status;

  const handleCancel = () => {
    setComment('');
    setForm({...form, status: initialStatus});
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   console.log('globalConfigs', globalConfigs);
  // }, [globalConfigs]);

  //Filtered orgs

  // const filteredRoles = allRoles.map(role => ({
  //   roleId: role._id,
  //   permissionsId: role.permissionsId
  // }));

  // const filteredPermissions = (allPermissions || []).map(permissions => ({
  //   permissionsId: permissions._id,
  //   permissions: permissions.permissions
  // }));

  // const filteredOrgs = loggedUser.usr.salesforceOrgs.map(org => ({
  //   roleId: org.roleId,
  //   sfdc: org.sfdc,
  //   _id: org._id,
  //   sfdcId: org.sfdcId,
  // }));

  const currentOrg = loggedUser.usr.salesforceOrgs.find(
    (org) => org.sfdcId === org_id,
  );

  if (!currentOrg) {
    console.error('Org not found');
    return null;
  }

  const roleForCurrentOrg = allRoles.find(
    (role) => role._id === currentOrg.roleId,
  );

  if (!roleForCurrentOrg) {
    console.error('role not found');
    return null;
  }

  const permissionsForCurrentOrg = (allPermissions || []).filter(
    (permission) => permission._id === roleForCurrentOrg.permissionsId,
  );

  const findingStatusPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingStatus');

  const findingAssignmentPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingAssignment');

  const findingSeverityPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingSeverity');

  const findingCommentPermission = permissionsForCurrentOrg
    .flatMap((permission) => permission.permissions)
    .find((permission) => permission.object === 'findingComment');

  const isDisabledStatus =
    findingStatusPermission &&
    findingStatusPermission.permissions.length === 1 &&
    findingStatusPermission.permissions.includes('READ');

  const isDisabledAssignment =
    findingAssignmentPermission &&
    findingAssignmentPermission.permissions.length === 1 &&
    findingAssignmentPermission.permissions.includes('READ');

  const isDisabledSeverity =
    findingSeverityPermission &&
    findingSeverityPermission.permissions.length === 1 &&
    findingSeverityPermission.permissions.includes('READ');

  const isDisabledComment =
    findingCommentPermission &&
    findingCommentPermission.permissions.length === 1 &&
    findingCommentPermission.permissions.includes('READ');

  // const [openSnackbarComment, setOpenSnackbarComment] = useState(false);
  // const [snackbarMessageComment, setSnackbarMessageComment] = useState('');

  // const handleSnackbarCloseComment = () => {
  //   setOpenSnackbarComment(false);
  // };

  const onChangeStatus = (event, value) => {
    if (reqComment && isDisabledComment) {
      setForm((cur) => ({
        ...cur,
        status: initialStatus,
      }));
      setOpenSnackbarComment(true);
      setSnackbarMessageComment(
        "Status changes require a comment per account settings. User doesn't have access to add comment. Please contact administrator",
      );
    } else if (reqComment && !isDisabledComment && !isDisabledStatus) {
      setIsModalOpen(true);
      setForm((cur) => ({
        ...cur,
        status: value?.value,
      }));
    } else {
      setForm((cur) => {
        return {
          ...cur,
          status: value?.value,
        };
      });
    }
  };

  const handleDisabledFieldClick = (event) => {
    event.stopPropagation();

    if (reqComment && isDisabledComment) {
      setOpenSnackbarComment(true);
      setSnackbarMessageComment(
        "Status changes require a comment per account settings. User doesn't have access to add comment. Please contact administrator",
      );
    }

    if (reqComment && isDisabledComment && isDisabledStatus) {
      setOpenSnackbarComment(false);
    }
  };

  return (
    <Fragment>
      <AppCard sxStyle={{}}>
        <div
          className={styles.formSubBlock}
          onClick={(event) => {
            if (isDisabledStatus || (reqComment && isDisabledComment)) {
              handleDisabledFieldClick(event);
            }
          }}
        >
          <div
            style={{
              pointerEvents:
                isDisabledStatus || (reqComment && isDisabledComment)
                  ? 'none'
                  : 'auto',
            }}
          >
            <InputLabel>Status</InputLabel>
            <Autocomplete
              className={styles.autoCompleteSelection}
              disableClearable
              disablePortal
              disabled={isDisabledStatus || (reqComment && isDisabledComment)}
              id='status'
              name='status'
              options={statusOptions}
              value={form.status}
              onChange={onChangeStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  variant='outlined'
                  size='small'
                  required
                  onClick={handleDisabledFieldClick}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.formSubBlock}>
          <InputLabel>Severity</InputLabel>
          <Autocomplete
            disablePortal
            disableClearable
            id='severity'
            name='severity'
            options={severityOptions}
            disabled={isDisabledSeverity}
            value={
              form.severity.charAt(0).toUpperCase() + form.severity.substring(1)
            }
            className={styles.autoCompleteSelection}
            onChange={onChangeSeverity}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' size='small' />
            )}
          />
        </div>
        <div className={styles.formSubBlock}>
          <InputLabel>Assigned</InputLabel>
          <Autocomplete
            disablePortal
            disableClearable
            disabled={isDisabledAssignment}
            id='assignTo'
            name='assignTo'
            options={getAssignTo()}
            className={styles.autoCompleteSelection}
            value={form.assignTo}
            onChange={onChangeAssign}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' size='small' />
            )}
          />
        </div>
        <div className={styles.formSubBlock}>{convertTagsChips(tags)}</div>
        <form>
          <div className={styles.formSubBlock}>
            <p>
              Created:{' '}
              {new Date(founddate)
                .toLocaleString('en-us', options1)
                .replace(
                  new Date(founddate)
                    .toLocaleString('en-us', options1)
                    .split(',')[0] + ',',
                  '',
                )}{' '}
              {new Date(founddate).toLocaleString('en-us', options)}{' '}
            </p>
          </div>
          <div className={styles.formSubBlock}>
            <p>
              Updated:{' '}
              {new Date(founddate)
                .toLocaleString('en-us', options1)
                .replace(
                  new Date(founddate)
                    .toLocaleString('en-us', options1)
                    .split(',')[0] + ',',
                  '',
                )}{' '}
              {new Date(founddate).toLocaleString('en-us', options)}{' '}
            </p>
          </div>
          <div className={styles.formSubBlock}>
            <p>Type: {type} </p>
          </div>
          <div className={styles.formSubBlock}>
            <p>Category: {category} </p>
          </div>
          {cweViolation.length > 0 && (
            <div className={styles.formSubBlock}>
              <p>
                CWE:{' '}
                {cweViolation?.substring(cweViolation.indexOf('CWE') + 4) || ''}
              </p>
            </div>
          )}
          {owasp && (
            <div className={styles.formSubBlock}>
              <p>
                OWASP:{' '}
                <Link href={url} target='_blank' rel='noopener noreferrer'>
                  {owasp}
                </Link>
              </p>
            </div>
          )}
          <div className={styles.btnSubmit}>
            <Button
              onClick={onSubmitForm}
              className={styles.btn}
              variant='contained'
            >
              Save
            </Button>
          </div>
        </form>
      </AppCard>
      <Modal
        open={open}
        onClose={() => {
          toggleOpenParent(null);
        }}
      >
        <Box sx={{...style, width: 400}}>
          <Box sx={style}>
            <h2> Do you want to delete this Tag?</h2>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                margin: '10px',
              }}
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  toggleOpenParent(null);
                }}
              >
                No
              </Button>
              <Button variant='contained' onClick={deleteTag}>
                Yes
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbarComment}
        autoHideDuration={3000}
        onClose={handleSnackbarCloseComment}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          severity='error'
          onClose={handleSnackbarCloseComment}
        >
          {snackbarMessageComment}
        </MuiAlert>
      </Snackbar>
      {globalConfigs && globalConfigs.required_comments_findings === true && (
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <Box sx={modalss2}>
            <div className={styles.headerContainerComment}>
              <CommentIcon className={styles.commentIconHeader} />
              <h3>Required Comment</h3>
            </div>
            <p className={styles.pLabels}>Status:</p>
            <Autocomplete
              disableClearable
              disablePortal
              id='status'
              name='status'
              options={statusOptions}
              value={form.status}
              className={styles.autoCompleteSelection}
              onChange={onChangeStatus}
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  variant='outlined'
                  size='small'
                  required
                />
              )}
            />
            <p className={styles.pLabels}>Comments:</p>
            <TextareaAutosize
              className={styles.commentArea}
              minRows={10}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className={styles.btnContainer}>
              <Button variant='contained' color='primary' onClick={handleSave}>
                Save
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleCancel}
                style={{marginRight: 8}}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </Fragment>
  );
}

export default DetailsForm;

DetailsForm.propTypes = {
  updateddate: PropTypes.string,
  founddate: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  severity: PropTypes.string,
  owasp: PropTypes.string,
  tags: PropTypes.array,
  issuesFilters: PropTypes.object,
  assigned: PropTypes.object,
  _id: PropTypes.string,
  category: PropTypes.string,
  cweViolation: PropTypes.string,
  reqComment: PropTypes.bool,
};
