//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const IS_API_COMPLETED = 'is_api_completed';
export const IS_API_IN_PROCESS = 'is_api_in_process';
export const API_RESPONSE = 'is_api_response';
export const API_ERROR = 'api_error';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//HEATH_ CARE-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//ERRORS_DASHBOARD

export const GET_ERRORS_DATA = 'get_error_data';

// TRENDING_DASHBOARD

export const GET_TRENDING_DATA = 'get_trending_data';

// SECURITY_DATA_DASHBOARD
export const GET_SECURITY_DATA = 'get_security_data';

// ISSUES DATA DASHBOARD
export const GET_ISSUES_DATA = 'get_issues_data';
export const TOTAL_ISSUES = 'total_issues';
export const GET_ISSUES_PAGE_COUNT = 'get_issues_page_count';
export const GET_ISSUES_CUR_PAGE = 'get_issues_cur_page';
export const GET_ISSUES_FILTERS = 'get_issues_filters';
export const SET_SELECTED_IDS = 'set_selected_ids';
export const SET_SOCKET_VALUE = 'set_socket_value';

// SALESFORCE

export const GET_SFDC_INFO = 'get_sfdc_info';
export const GET_PAGE_COUNT = 'get_page_count';
export const GET_CUR_PAGE = 'get_cur_page';
export const ADD_SALESFORCE_ORG_RESULT = 'add_salesforce_org_result';
export const EDIT_SALESFORCE_ORG_RESULT = 'edit_salesforce_org_result';
export const ADDING_ORG_IN_PROCESS = 'ADDING_ORG_IN_PROCESS';
export const SALESFORCE_RESPONSE = 'salesforce_response';
export const GET_ALL_SFDC = 'get_all_sfdc';

//SETUP

export const GET_COMPANY_INFO = 'get_company_info';
export const GET_ALL_ORGS = 'get_all_orgs';
export const GET_ALL_ORGS_IN_ACCOUNT = 'get_all_orgs_in_account';

export const SETUP_RESPONSE = 'setup_response';
export const GET_SUBSCRIPTION_INFO = 'get_subscription_info';
export const SETUP_PROCESS_API = 'setup_process_api';

// Common

export const GET_USER_INFO = 'get_user_info';
export const GET_USERS = 'get_users';
export const SUBSCRIPTION_ACTIVE = 'subscription_active';

//Profile

export const GET_TWO_STEP_AUTHENTICATION = 'get_two_step_authentication';
export const POST_USER_PROFILE = 'post_user_profile';
export const GET_COUNTRY_CODES = 'get_country_codes';
export const PROFILE_RESPONSE = 'profile_response';

// REPORTS

export const GET_REPORTS_DATA = 'get_reports_data';
export const GET_REPORTS_PAGE_COUNT = 'get_reports_page_count';
export const GET_REPORTS_CUR_PAGE = 'get_reports_cur_page';

export const GET_CUR_ORG_ID = 'get_cur_org_id';
export const GET_CUR_ORG_NAME = 'get_cur_org_name';

export const GET_CUR_REPORT_ID = 'get_cur_report_id';

export const POST_SCAN_DATA = 'post_scan_data';
export const POST_START_SCAN_DATA = 'post_start_scan_data';

export const POST_DOWNLOAD_REPORTS = 'post_download_reports';
export const DOWNLOAD_REPORTS_CUR_PAGE = 'download_reports_cur_page';

export const LAST_COMPLETED_SCAN = 'last_completed_scan';

//settings

export const GET_SCAN_TYPES = 'get_scan_types';

export const GET_CUSTOM_RULES = 'get_custom_rules';

export const GET_SCAN_RULES = 'get_scan_rules';

// APP BAR

export const APP_BAR_DASHBOARD = 'app_bar_dashboard';

// Integrations
export const GITHUB_INFO = 'githubinfo';
export const GITLAB_INFO = 'gitlabinfo';
export const AZURE_INFO = 'azureinfo';
export const BITBUCKET_INFO = 'bitbucketinfo';
export const GITHUB_SECRET = 'git_secret';
export const BITBUCKET_SECRET = 'bitbucket_secret';
export const AZURE_SECRET = 'azure_secret';
export const AZURE_VERIFIED = 'azure_verified';
export const GITLAB_SECRET = 'gitlab_secret';
export const GITLAB_VERIFIED = 'gitlab_verified';
export const COPADO_ESSENTIAL_SECRET = 'copado_essentials_secret';
export const JIRA_OUTPUT_SFDX = 'jira_output_sfdx';
export const API_SECRET_TOKEN = 'api_secret_token';

// REPORTS-PDF

export const GET_SCAN_STATS = 'get_scan_stats';
export const GET_FINDINGS_DATA = 'get_findings_data';

// DETAILS

export const DETAILS_RESPONSE = 'details_response';
export const GET_ISSUE_DETAIL = 'get_issue_detail';

// Configure

export const GLOBAL_CONFIGS = 'global_configs';
export const CONFIG_RESPONSE = 'config_response';

// Team

export const SPECIFIC_ORG_USERS = 'specific_org_users';
export const TEAM_RESPONSE = 'team_response';

// authentication

export const IS_ADMIN = 'is_admin';

// Dashboard

export const DASHBOARD_RESPONSE = 'dashboard_response';
export const SINGLE_ORG = 'single_org';
export const LAST_SCAN_STATUS = 'last_scan_status';
export const CUR_ISSUES_FILTER_STATE = 'cur_issues_filter_state';
export const Set_Filter_Data = 'Set_Filter_Data';
export const Set_Initial = 'Set_Initial';
export const Set_Total_Rows = 'Set_Total_Rows';
export const Set_PageSize = 'Set_PageSize';
export const Set_PageCount = 'Set_PageCount';
export const Set_Initial_True = 'Set_Initial_True';
export const Remove_Filter_Data = 'Remove_Filter_Data';
export const GET_ISSUES_DATA_FOR_DOWNLOAD = 'GET_ISSUES_DATA_FOR_DOWNLOAD';

// Roles

export const GET_ALL_ROLES = 'get_all_roles';
export const GET_GLOBAL_PERMISSION = 'get_global_permission';
export const GET_ALL_PERMISSIONS = 'get_all_permissions';
export const UPDATE_A_ROLE = 'update_a_role';

// GLOBAL
export const SFDC_PERMISSION = 'sfdcinfo';
export const SFCC_PERMISSION = 'sfccinfo';
export const SUBSCRIPTION_PERMISSION = 'subscription'; // dep
export const COMPANY_PERMISSION = 'company';
export const USER_PERMISSION = 'user';
export const CONFIGURE_PERMISSION = 'config';

export const GLOBAL_SECURITYGATE = 'globalsecuritygate';
export const GLOBAL_SCAN_SETTINGS = 'globalscansettings';
export const GLOBAL_INTEGRATIONS = 'globalintegrations';
export const GLOBAL_SCHEDULER = 'scheduler';
export const LOGS = 'logs';

export const GLOBAL_ROLE = 'global_role';

// ORGS

export const ORG_ROLES = 'org_roles';

// ScanStatistics

export const GET_SCAN_BY_ID = 'get_scan_by_id';
export const TRENDING_SCAN_PER_ORG = 'trending_scan_per_org';

export const SET_SELECTED_ROW = 'set_selected_row';

// Table

export const SET_TABLE_COLUMNS = 'set_table_columns';

// Logging
export const GET_LOGGING_DATA = 'get_logging_data';

//Scheduler
export const GET_SCHEDULER_DATA = 'get_scheduler_data';
export const GET_ORG_SCHEDULER_DATA = 'get_org_schedules';
export const POST_SCHEDULER_DATA = 'post_scheduler_data';
export const UPDATE_SCHEDULER_DATA = 'update_scheduler_data';
export const POST_CHECK_SCHEDULER_DATA = 'check_if_org_in_schedule';
export const GET_SCHEDULER_ORG_DATA = 'list_all_orgs';

//user
export const UPDATE_USER_STATUS = 'update_user_status';
export const GET_USER_NOTIFICATIONS = 'get_user_notifications';
