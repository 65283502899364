import React from 'react';
import PropTypes from 'prop-types';
import styles from './VulnerabilityDetails.module.css';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';

function VulnerabilityDetails({traces, exploits}) {
  return (
    <div className={styles.TraceContainer}>
      {traces != null && !traces?.length && !exploits && <h3>No Vulnerability Trace Present</h3>}

      {traces && traces?.length && traces?.map((traceItem, index) => {
        return (
          <div key={traceItem._id} className={styles.trace}>
            <div className={styles.traceHeader}>
              <h5>{traceItem['ASTNodeType']} </h5>
              <p className={styles.NodeName}>{traceItem['ASTNodeName']}</p>
              <div>
                (
                {traceItem['filename'].substring(
                  traceItem['filename'].lastIndexOf(
                    '/',
                    traceItem['filename'].lastIndexOf('/') - 1,
                  ) + 1,
                )}
                &nbsp; Line: {traceItem['line']} Col: {traceItem['col']})
                {traceItem['params'].map((item) => {
                  return (
                    <p key={item._id}>
                      Parameter: {item.paramName} (Type: {item.paramType})
                    </p>
                  );
                })}
              </div>
            </div>
            <p className={styles.codeSnippet}>{traceItem['codeSnippet']}</p>
            {traces.length - 1 != index && (
              <div className={styles.arrowContainer}>
                <ArrowDownwardSharpIcon color='primary' sx={{fontSize: 40}} />
              </div>
            )}
          </div>
        );
      })}

      {exploits?.split(',').map((item, index) => {
        return (
          <p key={index} className={styles.exploits}>
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default VulnerabilityDetails;

VulnerabilityDetails.propTypes = {
  traces: PropTypes.array,
  exploits: PropTypes.string,
};
