import React, {useState} from 'react';
import styles from './SingleReport.module.css';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  APP_BAR_DASHBOARD,
  GET_CUR_REPORT_ID,
  GET_ISSUES_CUR_PAGE,
} from 'shared/constants/ActionTypes';
import {TextField} from '@mui/material';
import {onDeleteReport, onEditReport} from 'redux/actions';
import {useNavigate, useParams} from 'react-router-dom';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EllipsisDropdown from '../EllipsisDropdown/EllipsisDropdown';
import ScanCustomTags from '../ScanCustomTags/ScanCustomTags';
import {Oval} from 'react-loader-spinner';
import github from 'assets/images/salesforce/github.svg';
import gitlab from 'assets/images/salesforce/gitlab.svg';
import bitbucket from 'assets/images/salesforce/bitbucket.svg';
import devops from 'assets/images/salesforce/devops.svg';
import Copado from 'assets/images/configure/copado.svg';
import digitsec from 'assets/icon/white-horizontal-transparent.svg';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import copy from 'clipboard-copy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';

const deleteIcon = {fontSize: 60};
const editIcon = {fontSize: 60};

const SingleReport = ({
  Critical,
  High,
  Medium,
  Low,
  ScanDate,
  status,
  message,
  _id,
  reportsCurPage,
  isSecurityGatePassed,
  name,
  customFields,
  viewIssues,
  writeable,
  source,
  logsRead,
  initiatedBy,
  scan_type,
  show_new_findings,
}) => {
  let options1 = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let options = {timeStyle: 'long', hour12: true};

  let scanName = '';
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteModalOpen = () => {
    setDeleteModal(true);
  };
  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  const [editModal, setEditModal] = useState(false);

  const editModalOpen = () => {
    setEditModal(true);
  };
  const editModalClose = () => {
    setEditModal(false);
  };

  const getDays = () => {
    // var Scdate = new Date(ScanDate);
    // var toDate = new Date();
    // let difference = toDate.getTime() - Scdate.getTime();
    // let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    // return `${TotalDays} ${TotalDays > 1 ? 'Days' : 'Day'}`;

    let today = new Date();
    let diffDay = differenceInDays(today, new Date(ScanDate));
    let diffHour = differenceInHours(today, new Date(ScanDate));
    let diffMin = differenceInMinutes(today, new Date(ScanDate));

    if (diffDay > 1) {
      return diffDay + ' Days ';
    } else if (diffHour > 1) {
      return diffHour + ' Hours ';
    } else if (diffMin > 1) {
      return diffMin + ' Mins ';
    } else if (diffMin < 1) {
      return '1 Min ';
    }
  };

  const deleteHandler = () => {
    dispatch(onDeleteReport({_id, org_id, page: reportsCurPage}));
  };

  const editHandler = () => {
    //if(document.getElementById('scanName').value !== '' && document.getElementById('scanName').value !== null && document.getElementById('scanName').value !== undefined){
    setEditModal(false);
    scanName = document.getElementById('scanName').value;
    dispatch(onEditReport({_id, org_id, page: reportsCurPage, scanName}));
    // }else{
    //   setEditModal(true);
    // }
  };

  const clickHandler = () => {
    if (viewIssues) {
      dispatch({type: GET_CUR_REPORT_ID, payload: _id});
      dispatch({type: GET_ISSUES_CUR_PAGE, payload: 1});
      dispatch({type: APP_BAR_DASHBOARD, payload: 'issues'});
      const currentUrl = window.location.pathname.replace('/app', '');
      const existingParams = new URLSearchParams();
      existingParams.append('tab', 'issues');
      existingParams.append('reportId', _id);
      const updatedUrl = `${currentUrl}?${existingParams.toString()}`;
      navigate(updatedUrl);
    }
  };
  const getSourceHtml = (source) => {
    if (source === 'gitHub') {
      return (
        <Tooltip title={'Github'}>
          <img className={styles.singleIcon} src={github} />
        </Tooltip>
      );
    } else if (source === 'gitLab') {
      return (
        <Tooltip title={'Gitlab'}>
          <img className={styles.singleIcon} src={gitlab} />
        </Tooltip>
      );
    } else if (source === 'bitbucket') {
      return (
        <Tooltip title={'Bitbucket'}>
          <img className={styles.singleIcon} src={bitbucket} />
        </Tooltip>
      );
    } else if (source === 'azure') {
      return (
        <Tooltip title={'Azure DevOps'}>
          <img className={styles.singleIcon} src={devops} />
        </Tooltip>
      );
    } else if (source === 'digitsecWeb') {
      return (
        <Tooltip title={'DigitSec Web'}>
          <img className={styles.singleIcon} src={digitsec} />
        </Tooltip>
      );
    }
    else if (source === 'vscode') {
      return (
        <Tooltip title={'DigitSec VSCode Extension'}>
          <img className={styles.singleIcon} src={devops} />
        </Tooltip>
      );
    } else if (source === 'sfdxplugin') {
      return (
        <Tooltip title={'DigitSec SFDX Plugin'}>
          <img className={styles.singleIcon} src={digitsec} />
        </Tooltip>
      );
    } else if (source === 'cli') {
      return (
        <Tooltip title={'DigitSec CLI'}>
          <img className={styles.singleIcon} src={digitsec} />
        </Tooltip>
      );
    } else if (source === 'copadoEssentials') {
      return (
        <Tooltip title={'Copado Essentials'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'copadoFlow') {
      return (
        <Tooltip title={'Copado Flow'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'copado|gitHub') {
      return (
        <Tooltip title={'Copado Branch Scan (Github)'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'copado|gitLab') {
      return (
        <Tooltip title={'Copado Branch Scan (Gitlab)'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'copado|bitbucket') {
      return (
        <Tooltip title={'Copado Branch Scan (Bitbucket)'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'copado|azure') {
      return (
        <Tooltip title={'Copado Branch Scan (Azure DevOps)'}>
          <img className={styles.singleIcon} src={Copado} />
        </Tooltip>
      );
    } else if (source === 'scheduledscan') {
      return (
        <Tooltip title={'DigitSec scheduled scan'}>
          <EventAvailableIcon className={styles.schedulerIcon} />
        </Tooltip>
      );
    }
  };

  //See More Info
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCopy = () => {
    if (message) {
      copy(message);
    }
  };

  const scanTypes = [
    'SAST',
    'IAST',
    'SCA',
    'CONFIG',
    '3rdPartyPackages',
    'QUALITY',
  ];

  const getColorForScanType = (type) => {
    switch (type) {
      case 'SAST':
        return 'sastColor';
      case 'IAST':
        return 'iastColor';
      case 'SCA':
        return 'scaColor';
      case 'CONFIG':
        return 'configColor';
      case '3rdPartyPackages':
        return 'thirdPartyColor';
      case 'QUALITY':
        return 'qualityColor';
      default:
        return 'defaultColor';
    }
  };

  return (
    <>
      <div className={styles.singleReport}>
        <div className={styles.securityGateInfo} onClick={clickHandler}>
          <div className={styles.securityGateIcon}>
          <Tooltip
                title={initiatedBy?initiatedBy:''}
              >
                <Avatar
                  style={{
                    alignSelf: 'end',
                    float: 'right',
                    margin: '4px',
                    padding: 0,
                    backgroundColor: '#' + Math.floor(Math.random() * 255),
                  }}
                >
                  {initiatedBy?.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
          </div>
          <div className={styles.name}>
            {name !== '' ? (
              <p>
                {name} @{new Date(ScanDate).toLocaleString('en-us', options1)}{' '}
                {new Date(ScanDate).toLocaleString('en-us', options)}
              </p>
            ) : (
              <p>
                Scanned @ {new Date(ScanDate).toLocaleString('en-us', options1)}{' '}
                {new Date(ScanDate).toLocaleString('en-us', options)}
              </p>
            )}

            <div className={styles.typesMainContainer}>
              <p className={styles.configureTextSection}>
                {source ? getSourceHtml(source) : ''}
                {isSecurityGatePassed === true ? (
                  <div
                    style={{display: 'flex', alignItems: 'center'}}
                    className={
                      source
                        ? styles.configureText
                        : styles.configureTextNoPosition
                    }
                  >
                    Gate
                    <Tooltip title='Security Gate Passed'>
                      <CheckCircleIcon
                        style={{
                          color: '07CB3F',
                          fontSize: '15px',
                          marginLeft: '3px',
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : isSecurityGatePassed === false ? (
                  <div
                    style={{display: 'flex', alignItems: 'center'}}
                    className={
                      source
                        ? styles.configureText
                        : styles.configureTextNoPosition
                    }
                  >
                    Gate
                    <Tooltip title='Security Gate Failed'>
                      <CancelIcon
                        style={{
                          color: 'red',
                          fontSize: '15px',
                          marginLeft: '3px',
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <span
                    className={
                      source
                        ? styles.configureText
                        : styles.configureTextNoPosition
                    }
                  >
                    {/* No Security Gate Configured */}
                  </span>
                )}
              </p>

              <div className={styles.typesContainer}>
                {scanTypes.map(
                  (type, index) =>
                    scan_type.includes(type) && (
                      <Chip
                        size='small'
                        key={index}
                        label={type === '3rdPartyPackages' ? '3rd Party' : type}
                        className={`${styles.scanType} ${
                          styles[getColorForScanType(type)]
                        }`}
                        style={{marginRight: '5px', height: '16px'}}
                      />
                    ),
                )}
                {show_new_findings && (
                <Chip
                    size='small'
                    label={'NEW ISSUES' }
                    className={styles.showNewFindings}
                    style={{marginRight: '5px', height: '16px', fontSize:'0.6125rem'}}
                  />
                )
                }
          </div>
            </div>
          </div>
        </div>

        <div className={styles.numberOfErrors}>
          <div>
            <p className={styles.criticalText}>{Critical} Critical</p>
          </div>
          <div>
            <p className={styles.highText}>{High} High</p>
          </div>
          <div>
            <p className={styles.mediumText}>{Medium} Medium</p>
          </div>
          <div>
            <p className={styles.lowText}>{Low} Low</p>
          </div>
        </div>
        <div className={styles.errorStatus}>
          {status === 'error' && message !== undefined && (
            <div>
              {message.length > 5 ? (
                <div>
                  <p style={{color: 'red'}}>Error</p>
                  <p
                    style={{
                      color: '#209ef7',
                      cursor: 'pointer',
                      fontSize: '10px',
                    }}
                    onClick={handleClick}
                  >
                    See more
                  </p>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        width: '300px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        border: '1px solid #f5e7e7',
                      },
                    }}
                  >
                    <div className='buttons-wrapper'>
                      <Button className='btn-sr' onClick={handleClose}>
                        <CloseIcon></CloseIcon>
                      </Button>
                      <Button className='btn-sr' onClick={handleCopy}>
                        <ContentCopyIcon></ContentCopyIcon>
                      </Button>
                    </div>
                    <div className='modal-content'>{message}</div>
                  </Popover>
                </div>
              ) : (
                <p style={{color: 'red'}}>Error</p>
              )}
            </div>
          )}
          {status == 'completed' && <p>{getDays()} ago</p>}
          {status == 'pending' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Oval
                height={30}
                width={30}
                color='#0A8FDC'
                wrapperStyle={{}}
                wrapperClass=''
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor='#f68913'
                strokeWidth={4}
                strokeWidthSecondary={6}
              />
            </div>
          )}
        </div>
        <div>
          <EllipsisDropdown
            scanId={_id}
            editModalOpen={editModalOpen}
            deleteModalOpen={deleteModalOpen}
            reportsCurPage={reportsCurPage}
            writeable={writeable}
            logsRead = {logsRead}
            orgId={org_id}
          />
        </div>
      </div>
      <div className={styles.customTags}>
        {customFields?.length > 0 &&
          customFields.map((item) => {
            return (
              <ScanCustomTags
                key={item._id}
                fieldName={item.fieldName}
                fieldValue={item.fieldValue}
                href={item.href}
                _id={_id}
                reportsCurPage={reportsCurPage}
              />
            );
          })}
      </div>

      <ModalContainer
        open={deleteModal}
        handleModalClose={deleteModalClose}
        handleModalProceed={deleteHandler}
        proceedButtonText='Delete'
        closeButtonText='Cancel'
      >
        <div className={styles.deleteModal}>
          <DeleteIcon sx={deleteIcon} color='secondary' />
          <h2>Do you want to delete this Scan? </h2>
          <p>Deleted scans are no longer accessible</p>
        </div>
      </ModalContainer>

      <ModalContainer
        open={editModal}
        handleModalClose={editModalClose}
        handleModalProceed={editHandler}
        proceedButtonText='Save'
        closeButtonText='Cancel'
      >
        <form>
          <div className={styles.editModal}>
            <EditIcon sx={editIcon} color='secondary' />
            <h2>Enter a desired name for this scan? </h2>
            <p>Your scan will show up with this name in the future.</p>
          </div>
          <div className={styles.scanName}>
            <TextField
              className={styles.textField}
              id='scanName'
              label='Scan Name'
              variant='outlined'
              type='text'
              placeholder={name}
            />
          </div>
        </form>
      </ModalContainer>
    </>
  );
};

export default SingleReport;

SingleReport.propTypes = {
  Critical: PropTypes.number,
  High: PropTypes.number,
  Medium: PropTypes.number,
  Low: PropTypes.number,
  ScanDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  status: PropTypes.string,
  message: PropTypes.string,
  _id: PropTypes.string,
  reportsCurPage: PropTypes.number,
  isSecurityGatePassed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  name: PropTypes.string,
  customFields: PropTypes.array,
  viewIssues: PropTypes.bool,
  writeable: PropTypes.bool,
  source: PropTypes.string,
  logsRead: PropTypes.bool,
  initiatedBy: PropTypes.string,
  scan_type: PropTypes.string,
  show_new_findings: PropTypes.bool,
};
