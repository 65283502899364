import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ContactSupportSharpIcon from '@mui/icons-material/ContactSupportSharp';
import {DASHBOARD_RESPONSE} from 'shared/constants/ActionTypes.js';
import {useDispatch} from 'react-redux';
import {onPostStartScan} from 'redux/actions/Reports.js';
import {onGetSubscription} from 'redux/actions/Setup.js';
import {onSearchSfdcInfo} from 'redux/actions/Salesforce.js';
import {onGetSingleOrg} from 'redux/actions/Dashboard.js';
import {useParams} from 'react-router-dom';
import {modalTitle} from './UploadScan.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  borderRadius: 5,
  textAlign: 'center',
};
export default function UploadScan() {
  let {org_id} = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [showScanModal, setShowScanModal] = React.useState(false);

  const toggleOpenParent = () => {
    setOpen(!open);
  };
  const toggleScanModal = () => {
    setShowScanModal(!showScanModal);
  };
  const toggleCancel = () => {
    setFile(null);
    dispatch({
      type: DASHBOARD_RESPONSE,
      payload: {status: 'fail', message: 'Upload Cancelled!'},
    });
  };
  const uploadScan = async () => {
    toggleOpenParent();
    setShowScanModal(true);
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  const initiateScan = async () => {
    toggleScanModal();
    dispatch(onPostStartScan({org_id: org_id, file: file}));
    dispatch(onGetSubscription());
    dispatch(onSearchSfdcInfo(1, ''));
    dispatch(onGetSingleOrg({org_id}));
  };
  return (
    <div>
      <Button
        onClick={toggleOpenParent}
        sx={{marginRight: 1}}
        variant='contained'
      >
        <FileUploadIcon sx={{fontSize: 25}} />
        Upload
      </Button>
      <Modal open={open} onClose={toggleOpenParent}>
        <Box sx={{...style, width: 400, textAlign: 'left'}}>
          <Box sx={style}>
            <h2 className={modalTitle}>
              <WarningRoundedIcon color='primary' sx={{fontSize: 25}} />
              <span>Upload zip file to scan </span>
            </h2>
            <h6 style={{margin: 5}}>
              Choose using the file dialog
            </h6>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                margin: '10px',
                alignItems: 'center',
              }}
            >
              <input
                type='file'
                accept='.zip,.rar,.7zip'
                onChange={handleFileChange}
              />
             
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  toggleCancel();
                  toggleOpenParent();
                }}
              >
                Cancel
              </Button>
              <Button disabled={!file} variant='contained' onClick={uploadScan}>
                Upload
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
      <Modal open={showScanModal} onClose={toggleScanModal}>
        <Box sx={{...style, width: 400}}>
          <Box sx={style}>
            <ContactSupportSharpIcon sx={{fontSize: 100}} />
            <h2> Do you want to initate a scan?</h2>
            <h6>You will receive an email once the scan completes</h6>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                margin: '10px',
              }}
            >
            
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  toggleScanModal();
                }}
              >
                No
              </Button>
              <Button variant='contained' onClick={initiateScan}>
                Yes
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
