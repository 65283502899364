import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {onDeleteTagsReport} from 'redux/actions';
import PropTypes from 'prop-types';
import styles from './ScanCustomTags.module.css';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteIcon from '@mui/icons-material/Delete';

const deleteIcon = {fontSize: 60};

const ScanCustomTags = ({_id, reportsCurPage, fieldName, fieldValue, href}) => {
  let {org_id} = useParams();
  const dispatch = useDispatch();

  const [deleteTagsModal, setDeleteTagsModal] = useState(false);

  const deleteTagsModalOpen = () => {
    setDeleteTagsModal(true);
  };
  const deleteTagsModalClose = () => {
    setDeleteTagsModal(false);
  };

  const deleteTagsHandler = () => {
    let fieldToDelete = {
      fieldName: fieldName,
      fieldValue: fieldValue,
    };

    dispatch(
      onDeleteTagsReport({_id, org_id, page: reportsCurPage, fieldToDelete}),
    );
  };

  function onMouseOver(event) {
    if (event.target.href) {
      event.target.style.color = 'blue';
    }
  }

  function onMouseOut(event) {
    event.target.style.color = '#0a8fdc';
  }

  function onMouseOverX(event) {
    event.target.style.cursor = 'pointer';
    event.target.style.color = 'red';
  }

  function onMouseOutX(event) {
    event.target.style.cursor = 'default';
    event.target.style.color = '#0a8fdc';
  }
  function onDeleteTag() {
    deleteTagsModalOpen();
  }

  return (
    <>
      <div className={styles.badgeTag}>
        <a
          className={styles.aTag}
          onMouseEnter={onMouseOver}
          onMouseOut={onMouseOut}
          href={href}
          target='_blank'
          rel='noreferrer'
        >
          {fieldName + ' : ' + fieldValue}
        </a>
        <a
          className={styles.aTag2}
          onMouseEnter={onMouseOverX}
          onMouseLeave={onMouseOutX}
          onClick={onDeleteTag}
        >
          x
        </a>
      </div>
      <ModalContainer
        open={deleteTagsModal}
        handleModalClose={deleteTagsModalClose}
        handleModalProceed={deleteTagsHandler}
        proceedButtonText='Delete'
        closeButtonText='Cancel'
      >
        <div className={styles.deleteModal} style={{
                textAlign: 'center',
              }}>
          <DeleteIcon sx={deleteIcon} color='secondary' />
          <h2>Do you want to delete this tag? </h2>
          <p>Deleted tags will no longer be accessible</p>
        </div>
      </ModalContainer>
    </>
  );
};

export default ScanCustomTags;

ScanCustomTags.propTypes = {
  _id: PropTypes.string,
  reportsCurPage: PropTypes.number,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  href: PropTypes.string,
  deleteTagsModalOpen: PropTypes.func,
};
