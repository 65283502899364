import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import DashboardDropdown from '../../components/salesforce/SalesforceCategoryDropdown/DashboardDropdown';
import {TextField} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {Box} from '@mui/material';
import AddSalesforceDropdown from 'components/salesforce/AddSalesforceCloud/AddSalesforceDropdown';
import {useDispatch, useSelector} from 'react-redux';
import {onSearchSfdcInfo} from 'redux/actions';
import {
  GET_CUR_PAGE,
  GET_SFDC_INFO,
  SALESFORCE_RESPONSE,
} from 'shared/constants/ActionTypes';
import SalesforceCard from 'components/salesforce/SalesforceCard/SalesforceCard';
import Button from '@mui/material/Button';
import styles from './Salesforce.module.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import {APP_BAR_DASHBOARD} from 'shared/constants/ActionTypes';
import {onCheckIfAdmin} from 'redux/actions';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ModalChildrenMessage from 'components/modals/ModalChildrenMessage/ModalChildrenMessage';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import {AppInfoView} from '@crema';
import {checkPermissions} from '@crema/utility/helper/Utils';

const Salesforce = () => {
  const dispatch = useDispatch();
  const sfdcInfo = useSelector(({salesforce}) => salesforce.sfdcInfo);
  const {userInfo} = useSelector(({common}) => common);

  const pageCount = useSelector(({salesforce}) => salesforce.pageCount);
  const curPage = useSelector(({salesforce}) => salesforce.curPage);
  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  const salesforceResponse = useSelector(
    ({salesforce}) => salesforce.salesforceResponse,
  );

  const sfdc = useSelector(({roles}) => roles.sfdc);
  const sfcc = useSelector(({roles}) => roles.sfcc);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  let sfdcInfoList = sfdcInfo?.sfdcinfo || [];
  
  function containsAnyLetter(inputString) {
    const lettersArray = ['*', '(', ')', '"', '?', '[', '\\']; // Include the backslash character
      
    const regex = new RegExp(`[${lettersArray.map(letter => letter === '\\' ? '\\\\' : letter).join('')}]`, 'i');
    return regex.test(inputString);
  }

  const searchHandler = (e) => {
if(!containsAnyLetter(e.target.value)){
  setSearch(e.target.value);
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({
        pageNum: curPage,
        search: e.target.value,
        isSfcc: category,
      }),
    );
}
  
  };

  const categoryHandler = (value = undefined) => {
    setCategory(value);
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({pageNum: curPage, search: search, isSfcc: value}),
    );
  };

  const permissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'sfdcinfo',
    );

  let isSfccPermissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'sfccinfo',
    );
  sfdcInfoList = sfdcInfo?.sfdcinfo?.filter((item) => {
    if (item.isSfcc === false && sfdc?.read) {
      return item;
    }
    if (item.isSfcc === true && sfcc?.read) {
      return item;
    }
  });
  let readOnlySfdcInfo = true;
  if (permissions && permissions?.length !== 0) {
    readOnlySfdcInfo = checkPermissions(permissions);
  }
  let readOnlySfccInfo = true;
  if (isSfccPermissions && isSfccPermissions?.length !== 0) {
    readOnlySfccInfo = checkPermissions(isSfccPermissions);
  }

  // let readOnly =
  //   (readOnlySfdcinfo && readOnlyisSfcc === true) ||
  //   (readOnlyisSfcc && readOnlySfdcinfo == true);

  useEffect(() => {
    dispatch(onCheckIfAdmin());
  }, []);

  useEffect(() => {
    // if (isAdmin) {
      onRefresh();
      dispatch({type: APP_BAR_DASHBOARD, payload: 'dashboard'});
    // }
  }, []);

  // const {role} = useSelector(({roles}) => roles);
  const paginationChange = (event, value) => {
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(
      onSearchSfdcInfo({pageNum: value, search: search, isSfcc: category}),
    );
    dispatch({type: GET_CUR_PAGE, payload: value});
  };

  const onRefresh = () => {
    setSearch('');
    setCategory('');
    dispatch({type: GET_CUR_PAGE, payload: 1});
    dispatch({type: GET_SFDC_INFO, payload: null});
    dispatch(onSearchSfdcInfo({pageNum: 1, search: '', isSfcc: undefined}));
  };

  const handleModalClose = () => {
    dispatch({type: SALESFORCE_RESPONSE, payload: null});
  };
  return (
    <>
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <h1>Workspaces</h1>
          {<AddSalesforceDropdown readOnlySfdcInfo={readOnlySfdcInfo} readOnlySfccInfo={readOnlySfccInfo} />}
        </Box>
        <div className={styles.topSalesforceBarContainer}>
          <div className={styles.searchCategoryContainer}>
            <TextField
              sx={{width: '100%'}}
              id='outlined-basic'
              label='Search'
              variant='outlined'
              onChange={searchHandler}
              value={search}
              className={styles.searchField}
            />

            <div className={styles.dropdownContainer}>
              <DashboardDropdown
                search={search}
                categoryHandler={categoryHandler}
                category={category}
              />
            </div>
          </div>

          <div className={styles.refreshContainer}>
            <Button variant='outlined' onClick={onRefresh}>
              <RefreshIcon />
            </Button>
          </div>
        </div>
        <Grid container spacing={2} sx={{marginBottom: 10}}>
          {sfdcInfoList &&
            sfdcInfo &&
            Object.keys(sfdcInfo)?.length > 0 &&
            Array.isArray(sfdcInfo?.sfdcinfo) &&
            sfdcInfoList?.map((obj) => {
              return (
                <Grid key={obj._id} item xs={12} md={6}>
                  <SalesforceCard
                    status={sfdcInfo?.valid_in_days}
                    data={obj}
                    writeable={obj.isSfcc ? sfcc?.write : sfdc?.write}
                  />
                </Grid>
              );
            })}

          {sfdcInfo?.sfdcinfo?.total == 0 && (
            <Grid item xs={12}>
              <PlaceHolderDiv status='notfound' message='No Workspaces found' />
            </Grid>
          )}
          {sfdcInfoList && Object.keys(sfdcInfoList).length === 0 && (
            <Grid item xs={12}>
              <PlaceHolderDiv
                //status='success'
                message=' ... '
              />
            </Grid>
          )}
        </Grid>
        {pageCount > 1 && Object.keys(sfdcInfoList).length > 0 && (
          <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
            <Pagination
              count={pageCount}
              shape='rounded'
              onChange={paginationChange}
              page={curPage}
              color='primary'
            />
          </Box>
        )}
        {salesforceResponse && (
          <ModalContainer open={true} handleModalClose={handleModalClose}>
            <ModalChildrenMessage
              status={salesforceResponse.status}
              close='Close'
              handleModalClose={handleModalClose}
              message={salesforceResponse.message}
            />
          </ModalContainer>
        )}
      </div>
      <AppInfoView />
    </>
  );
};

export default Salesforce;
