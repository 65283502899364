import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Integrations from './Integrations/Integrations';
import ScanSettings from './ScanSettings/ScanSettings';
import Scheduler from 'components/configure/Scheduler/Scheduler';
import {useSelector} from 'react-redux';
import SecurityGate from 'components/SecurityGate/SecurityGate';
import {useDispatch} from 'react-redux';
import {onPutGlobalConfigs} from 'redux/actions';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import Advanced from 'components/dashboard/Advanced/Advanced';
import {useNavigate, useSearchParams} from 'react-router-dom';


export default function Configure() {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const globalSecurityGate = useSelector(({roles}) => roles.globalSecurityGate);
  const globalScanSettings = useSelector(({roles}) => roles.globalScanSettings);
  const globalIntegrations = useSelector(({roles}) => roles.globalIntegrations);
  const globalScheduler    =    useSelector(({roles}) => roles.globalScheduler);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const showTabs = ({integrations, scanSettings, securityGate, scheduler}) => {
    const canShowIntegrations = integrations?.read || integrations?.write;
    const canShowScanSettings = scanSettings?.read || scanSettings?.write;
    const canShowSecurityGate = securityGate?.read || securityGate?.write;
    const canShowScheduler = scheduler?.read || scheduler?.write;


    return canShowIntegrations || canShowScanSettings || canShowSecurityGate || canShowScheduler;
  };

  const showTabsValue = showTabs({
    securityGate: globalSecurityGate,
    scanSettings: globalScanSettings,
    integrations: globalIntegrations,
    scheduler: globalScheduler,
  });

  const submitHandler = ({config}) => {
    dispatch(onPutGlobalConfigs({config}));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const currentUrl = window.location.pathname.replace('/app', '');
    const existingParams = new URLSearchParams();
    existingParams.append('tab', newValue);
    const updatedUrl = `${currentUrl}?${existingParams.toString()}`;
    navigate(updatedUrl);
  };

  useEffect(() => {
    const tabsWithPermissions = [
      {
        key: 'integrations',
        hasPermission: globalIntegrations?.read || globalIntegrations?.write,
      },
      {
        key: 'scansettings',
        hasPermission: globalScanSettings?.read || globalScanSettings?.write,
      },
      {
        key: 'advanced',
        hasPermission: globalScanSettings?.read || globalScanSettings?.write,
      },
      {
        key: 'securitygate',
        hasPermission: globalSecurityGate?.read || globalSecurityGate?.write,
      },
      {
        key: 'scheduler',
        hasPermission: globalScheduler?.read || globalScheduler?.write,
      },
    ];

    const firstTabWithPermissions = tabsWithPermissions.find(
      (tab) => tab.hasPermission,
    );

    const defaultTab = firstTabWithPermissions
      ? firstTabWithPermissions.key
      : 'integrations';

    const initialTab = searchParams.get('tab') || defaultTab;

    setValue(initialTab);

    return () => {};
  }, [
    searchParams,
    globalIntegrations,
    globalScanSettings,
    globalSecurityGate,
    globalScheduler,
  ]);

  // useEffect(() => {
  //   if (searchParams.get('tab')) {
  //     setValue(searchParams.get('tab'));
  //   } else {
  //     handleChange(null, 'integrations');
  //   }
  //   return () => {};
  // }, []);

  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);
  return (
    <Box sx={{width: '100%', marginTop: '15px'}}>
      {globalConfigs &&
        globalIntegrations &&
        globalScanSettings &&
        globalSecurityGate &&
        globalScheduler && (
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <TabList
                onChange={handleChange}
                aria-label='Setup Tabs'
                variant='scrollable'
                scrollButtons
                allowScrollButtonsMobile
              >
                {showTabsValue && globalIntegrations?.read && (
                  <Tab label='Integrations' value='integrations' />
                )}
                {showTabsValue && globalScanSettings?.read && (
                  <Tab label='Scan Settings' value='scansettings' />
                )}
                {showTabsValue && globalSecurityGate?.read && (
                  <Tab label='Security Gate' value='securitygate' />
                )}
                {showTabsValue && globalScanSettings?.read && (
                  <Tab label='Advanced' value='advanced' />
                )}
                {showTabsValue && globalScheduler?.read && (
                  <Tab label='Scheduler' value='scheduler' />
                )}
              </TabList>
            </Box>
            {showTabsValue && globalIntegrations?.read && (
              <TabPanel value='integrations'>
                <Integrations writeable={globalIntegrations?.write} />
              </TabPanel>
            )}
            {showTabsValue && globalScanSettings?.read && (
              <TabPanel value='scansettings'>
                <ScanSettings writeable={globalScanSettings?.write} />
              </TabPanel>
            )}
            {showTabsValue && globalSecurityGate?.read && (
              <TabPanel value='securitygate'>
                <SecurityGate
                  defaultValue={{
                    critical: globalConfigs?.securityGateConfigs?.Critical,
                    high: globalConfigs?.securityGateConfigs?.High,
                    medium: globalConfigs?.securityGateConfigs?.Medium,
                    low: globalConfigs?.securityGateConfigs?.Low,
                    isEnabled:
                      globalConfigs?.securityGateConfigs?.isEnabled,
                      isEnforced:
                      globalConfigs?.securityGateConfigs?.isEnforced,
                  }}
                  submitHandler={submitHandler}
                  writeable={globalSecurityGate?.write}
                  isGlobal={true}
                />
              </TabPanel>
            )}
            {showTabsValue && globalScanSettings?.read && (
              <TabPanel value='advanced'>
                <Advanced
                  writeable={globalScanSettings?.write}
                  isGlobal={true}
                />
              </TabPanel>
            )}
            {showTabsValue && globalScheduler?.read && (
              <TabPanel value='scheduler'>
                <Scheduler writeable={globalScheduler?.write} />
              </TabPanel>
            )}
          </TabContext>
        )}
      {globalIntegrations &&
        globalScanSettings &&
        globalSecurityGate &&
        globalScheduler &&
        !showTabsValue && (
          <PlaceHolderDiv
            status='permissiondenied'
            message={`You don't have permission to view Configurations`}
          />
        )}
    </Box>
  );
}

Configure.propTypes = {
  writeable: PropTypes.bool,
};
