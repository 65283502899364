import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import AddTags from 'components/details/CommentSection/Add Tags/AddTags';
import {useNavigate} from 'react-router-dom';

const EllipsisDropdown = ({
  reportsCurPage,
  scanId,
  editModalOpen,
  deleteModalOpen,
  writeable,
  orgId,
  logsRead
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  // Tags Modal
  const [tagsModal, setTagsModal] = useState(false);

  const tagsModalOpen = () => {
    setTagsModal(true);
  };
  const tagsModalClose = () => {
    setTagsModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const modalOpen = (name) => {
    switch (name) {
      case 'editModalOpenWrapper':
        editModalOpen();
        handleClose();
        break;
      case 'tagsModalOpenWrapper':
        tagsModalOpen();
        handleClose();
        break;
      case 'deleteModalOpenWrapper':
        deleteModalOpen();
        handleClose();
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon
          sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
          color='primary'
        />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {writeable !== null && writeable !== undefined && (
          <div>
            <MenuItem disabled = {!writeable} onClick={() => modalOpen('editModalOpenWrapper')}>
              Edit
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem  disabled = {!writeable} onClick={() => modalOpen('tagsModalOpenWrapper')}>
              Add Tags
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem onClick={() => navigate(`/scan-statistics/${scanId}/${orgId}`)}>
            Scan Statistics
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem disabled = {!logsRead} onClick={() => navigate(`/setup?tab=logging&object=scan&objectId=${scanId}`)}>
              View Logs
            </MenuItem>
            <Divider sx={{my: 0.5}} />
            <MenuItem disabled = {!writeable} onClick={() => modalOpen('deleteModalOpenWrapper')}>
              Delete
            </MenuItem>
            </div>
        )}
        
      </Menu>
      <AddTags
        scanId={scanId}
        tagsModal={tagsModal}
        tagsModalClose={tagsModalClose}
        reportsCurPage={reportsCurPage}
      />
    </div>
  );
};

export default EllipsisDropdown;

EllipsisDropdown.propTypes = {
  editModalOpen: PropTypes.func,
  deleteModalOpen: PropTypes.func,
  readOnly: PropTypes.bool,
  scanId: PropTypes.string,
  reportsCurPage: PropTypes.number,
  writeable: PropTypes.bool,
  orgId: PropTypes.string,
  logsRead: PropTypes.bool,
};
