import React, {useEffect, useState, Fragment} from 'react';
import {AppCard} from '@crema';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styles from './UserSetup.module.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SingleUserSetup from './SingleUserSetup/SingleUserSetup.js';
import {useDispatch, useSelector} from 'react-redux';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import AddNewRoles from './AddNewRole/AddNewRole';
import {getAllRoles} from 'redux/actions/Roles';
import AssignRoles from './AssignRoles/AssignRole';
import AddUser from './AddUser';
import {AppInfoView} from '@crema';
// import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PropTypes from 'prop-types';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {TextField} from '@mui/material';
import {InputAdornment} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {CSVLink} from 'react-csv';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';

const UserSetup = ({writeable}) => {
  const dispatch = useDispatch();
  const users = useSelector(({common}) => common.users);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  // const isAdmin = useSelector(({authentication}) => authentication.isAdmin);

  const [rolesModal, setRolesModal] = useState(false);
  const [assignUser, setAssignUser] = useState(null);
  const [assignModal, setAssignModal] = useState(false);
  const [searchUser, setSearchUser] = useState('');

  const searchHandler = (event) => {
    setSearchUser(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);
  const [addUserModal, setAddUserModal] = useState(false);
  const addModalClose = () => {
    setAddUserModal(false);
  };
  const assignModalClose = () => {
    setAssignUser(null);
    setAssignModal(false);
  };
  const assignModalOpen = (user) => {
    setAssignUser(user);
    setAssignModal(true);
  };
  const addUserHandler = () => {
    setAddUserModal(true);
  };
  const roleModalClose = () => {
    setRolesModal(false);
  };
  const rolesModalOpen = () => {
    setRolesModal(true);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    //    console.log('Users: ', users);
    //    console.log('All Roles: ', allRoles);
  });

  const rolesMapping = {};
  if (allRoles && Array.isArray(allRoles)) {
    allRoles.forEach((role) => {
      rolesMapping[role._id] = role.name;
    });
  } else {
    console.error;
  }

  //Active users csv
  const activeUsers = [
    {label: 'Email', key: 'email'},
    {label: 'Name', key: 'name'},
    {label: 'Last Login', key: 'last_login'},
    {label: 'Signup Date', key: 'signup_date'},
    {label: 'Environment Role', key: 'role'},
    {label: 'Workspaces Assigned', key: 'salesforceOrgsCount'},
    {label: 'Workspaces', key: 'salesforceOrgs'},
  ];

  const filteredData = users
    ?.filter((user) => user.isDeleted === false)
    .map((user) => {
      const orgsWithAccess = user.salesforceOrgs.filter(
        (org) => org.access === true,
      );
      const orgsCountWithAccess = orgsWithAccess.length;
      const userRoleId = user.roleId || user.role;
      return {
        email: user.email,
        name: user.name,
        last_login: user.last_login,
        signup_date: user.signup_date,
        role: rolesMapping[userRoleId],
        salesforceOrgsCount: orgsCountWithAccess,
        salesforceOrgs: user.salesforceOrgs
        .filter((org) => org.access === true)
        .map(
          (org) => org.sfdc.replace(/"/g, "") + ` : ` + rolesMapping[org.roleId || userRoleId] + `,`,
        )
        .join('\n'),
    };
    });

  //Deleted users users csv
  const deletedUsers = [
    {label: 'Email', key: 'email'},
    {label: 'Name', key: 'name'},
    {label: 'Last Login', key: 'last_login'},
    {label: 'Deleted At', key: 'deletedAt'},
    {label: 'Deleted By', key: 'deletedBy'},
    {label: 'Environment Role', key: 'role'},
    {label: 'Workspaces Assigned', key: 'salesforceOrgsCount'},
    {label: 'Workspaces', key: 'salesforceOrgs'},
  ];

  const filteredDataDeletedUsers = users
    ?.filter((user) => user.isDeleted === true)
    .map((user) => {
      const orgsWithAccess = user.salesforceOrgs.filter(
        (org) => org.access === true,
      );
      const orgsCountWithAccess = orgsWithAccess.length;
      const userRoleId = user.roleId || user.role;
      return {
        email: user.email,
        name: user.name,
        last_login: user.last_login,
        deletedAt: user.deletedAt,
        deletedBy: user.deletedBy,
        role: rolesMapping[userRoleId],
        salesforceOrgsCount: orgsCountWithAccess,
        salesforceOrgs: user.salesforceOrgs
          .filter((org) => org.access === true)
          .map(
            (org) =>
              org.sfdc.replace(/"/g, '') +
              ` : ` +
              rolesMapping[org.roleId || userRoleId].replaceAll('"', '') +
              `,`,
          )
          .join('\n'),
      };
    });

  return (
    <Fragment>
      <AppCard>
        <div className={styles.userHeader}>
          <div className={styles.userHeaderTitle}>
            <AccountCircleIcon></AccountCircleIcon>
            <h4>Manage Users</h4>
          </div>
          <Box my={{xs: 3, sm: 0, md: 0}}>
            <div className={styles.manageUserButton}>
              <div>
                <TextField
                  label='Search User'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <PersonSearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  value={searchUser}
                  onChange={searchHandler}
                />
              </div>
              {/* <Button
                variant='contained'
                onClick={rolesModalOpen}
                startIcon={<AddIcon />}
                disabled={!isAdmin}
              >
                Add Roles
              </Button> */}
              <Button
                variant='contained'
                onClick={addUserHandler}
                startIcon={<PersonAddAlt1Icon />}
                disabled={!writeable}
              >
                Add Users
              </Button>
            </div>
          </Box>
        </div>

        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label='Active Users' />
            <Tab label='Deleted Users' />
          </Tabs>
          <div hidden={value !== 0}>
            {value === 0 && filteredData && (
              <div>
                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                  <CSVLink
                    data={filteredData}
                    headers={activeUsers}
                    filename={'active_users'}
                  >
                    <Tooltip title='Export active users'>
                      <GetAppIcon
                        style={{color: '#0a8fdc', cursor: 'pointer'}}
                      />
                    </Tooltip>
                  </CSVLink>
                </div>
              </div>
            )}

            {users
              ?.filter(
                (user) =>
                  user?.email?.includes(searchUser) &&
                  (user.isDeleted === false || !('isDeleted' in user)),
              )
              .map((user, index) => (
                <SingleUserSetup
                  key={index}
                  user={user}
                  rolesModalOpen={rolesModalOpen}
                  filterGlobalRoles={[]}
                  assignModalOpen={assignModalOpen}
                  assignModalClose={assignModalClose}
                  writeable={writeable}
                />
              ))}
          </div>

          <div hidden={value !== 1}>
            {value === 1 && filteredDataDeletedUsers && (
              <div>
                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                  <CSVLink
                    data={filteredDataDeletedUsers}
                    headers={deletedUsers}
                    filename={'deleted_users'}
                  >
                    <Tooltip title='Export deleted users'>
                      <GetAppIcon
                        style={{color: '#0a8fdc', cursor: 'pointer'}}
                      />
                    </Tooltip>
                  </CSVLink>
                </div>
              </div>
            )}
            {users
              ?.filter(
                (user) =>
                  user?.email?.includes(searchUser) && user.isDeleted === true,
              )
              .map((user, index) => (
                <SingleUserSetup
                  key={index}
                  user={user}
                  rolesModalOpen={rolesModalOpen}
                  filterGlobalRoles={[]}
                  assignModalOpen={assignModalOpen}
                  assignModalClose={assignModalClose}
                  writeable={writeable}
                />
              ))}
          </div>
        </Box>
      </AppCard>

      <ModalContainer open={addUserModal} handleModalClose={addModalClose}>
        <AddUser addModalClose={addModalClose} />
      </ModalContainer>

      <AddNewRoles rolesModal={rolesModal} rolesModalClose={roleModalClose} />

      {assignModal && (
        <>
          <AssignRoles
            assignModal={assignModal}
            assignModalClose={assignModalClose}
            userId={assignUser.user._id || ''}
          />
        </>
      )}
      <AppInfoView />
    </Fragment>
  );
};

export default UserSetup;

UserSetup.propTypes = {
  name: PropTypes.string,
  writeable: PropTypes.bool,
};
