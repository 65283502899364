import React, {useState} from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './Scheduler.css';
import {DataGrid} from '@mui/x-data-grid';
import {
  gridClasses,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {TextField, Button} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem as ContextMenuItem,
} from '@mui/material';
import {MoreHoriz as MoreHorizIcon} from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloudIcon from '@mui/icons-material/Cloud';
import {getAllScheduled} from 'redux/actions/Scheduler';
import {getOrgSchedules} from 'redux/actions/Scheduler';
import {getAllOrgsScheduler} from 'redux/actions/Scheduler';
// import {onGetAllOrgs} from 'redux/actions/Setup';
import {createScheduler} from 'redux/actions/Scheduler';
import {deleteScheduler} from 'redux/actions/Scheduler';
import {deleteScheduleFromQueue} from 'redux/actions/Scheduler';
import {updateScheduler} from 'redux/actions/Scheduler';
import {checkIfOrgInSchedule} from 'redux/actions/Scheduler';
import {Card, CardContent, Typography, Switch, Grid} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import EditIcon from '@mui/icons-material/Edit';
import {onGetCompanyInfo, onPostSubmitJira} from 'redux/actions';
import StyleIcon from '@mui/icons-material/Style';
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import {format} from 'date-fns';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import StartIcon from '@mui/icons-material/Start';
import Tooltip from '@mui/material/Tooltip';
// import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import {onGetSingleOrg} from 'redux/actions';
import {useParams} from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import {useNavigate} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {checkPermissions} from '@crema/utility/helper/Utils';
import PlaceHolderDiv from 'components/PlaceHolderDiv/PlaceHolderDiv';
import PropTypes from 'prop-types';

const orgsIcon = {
  cloudIcon: {
    color: '#0a8fdc',
    marginRight: '8px',
  },
};

const Scheduler = ({writeable}) => {
  const dispatch = useDispatch();
  const schedulerData = useSelector(({scheduler}) => scheduler.schedulerData);
  const orgSchedulerData = useSelector(
    ({scheduler}) => scheduler.orgSchedulerData,
  );
  // const checkedSchedulerData = useSelector(
  //   (state) => state.checkedSchedulerData,
  // );
  // const getAllOrgs = useSelector(({setup}) => setup.getAllOrgs);
  // const globalConfigs = useSelector(({configure}) => configure.globalConfigs);
  const companyInfo = useSelector(({setup}) => setup.companyInfo);
  const userInfo = useSelector(({common}) => common.userInfo);
  const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);
  const schedulerOrgs = useSelector(({scheduler}) => scheduler.schedulerOrgs);
  let navigate = useNavigate();

  const permissions =
    userInfo?.rolesAndPermissionsObject?.global?.permissions?.filter(
      (item) => item.object === 'scheduler',
    );

  let readSchedules;
  if (permissions && permissions?.length !== 0) {
    readSchedules = checkPermissions(permissions);
  }

  useEffect(() => {
    if (singleOrg && singleOrg.name) {
      const orgData = {_id: singleOrg._id, name: singleOrg.name};
      setSingleOrgData([orgData]);
    }
    if (singleOrgData.length > 0) {
      const org = singleOrgData[0];
      const _id = org._id;
      // eslint-disable-next-line no-unused-vars
      const _ = _id; //
      const name = org.name;
      // eslint-disable-next-line no-unused-vars
      const _name = name;
      // console.log('_id:', _id);
      //console.log('name:', name);
    }
  }, [singleOrg]);

  const {org_id} = useParams();
  const filteredOrgs = schedulerOrgs
    ? schedulerOrgs.filter((org) => org._id === org_id)
    : [];
  const [singleOrgData, setSingleOrgData] = useState([]);

  const editIcon = {fontSize: 60};
  const deleteIcon = {fontSize: 60};

  useEffect(() => {
    // dispatch(onGetAllOrgs());
    dispatch(onGetCompanyInfo());
    dispatch(getAllOrgsScheduler());
  }, [dispatch, companyInfo.customer_id]);

  // useEffect(() => {
  //   console.log('company info', companyInfo);
  //   console.log('user info', userInfo);
  //   // console.log(checkedSchedulerData);
  //   console.log('New Get All Orgs: ', schedulerOrgs);
  // }, [globalConfigs, companyInfo, userInfo]);

  // useEffect(() => {
  //   console.log('data', schedulerData);
  //   // console.log('sfdc', getAllOrgs);
  //   console.log('global configs', globalConfigs);
  // }, [schedulerData]);

  const [typeOrg, setType] = useState('');

  useEffect(() => {
    const currentURL = window.location.href;

    const executeDispatches = () => {
      if (currentURL.includes('configure?tab=scheduler')) {
        setType('environment');
        dispatch(getAllScheduled(companyInfo.customer_id));
      } else if (currentURL.includes('app/dashboard')) {
        dispatch(getOrgSchedules(org_id));
        setType('workspace');
      }
    };

    executeDispatches();

    const intervalId = setInterval(executeDispatches, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, org_id, companyInfo.customer_id, readSchedules]);

  const [schedulerId, setSchedulerId] = useState(null);

  //Columns for DataGrid
  const columns = [
    {field: '_id', headerName: 'ID', width: 200},
    {
      field: 'name',
      headerName: 'Title',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          const capitalizedStatus =
            value.charAt(0).toUpperCase() + value.slice(1);
          return capitalizedStatus;
        }
        return '';
      },
    },
    {
      field: 'author',
      headerName: 'Author',
      width: 200,
      editable: false,
      valueFormatter: ({value}) => {
        if (value) {
          return value;
        }
        return '';
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      editable: false,
      hide: true,
      valueFormatter: ({value}) => {
        if (value) {
          const capitalizedStatus =
            value.charAt(0).toUpperCase() + value.slice(1);
          return capitalizedStatus;
        }
        return '';
      },
    },
    {
      field: 'frequency',
      headerName: 'Scheduled Date Time',
      width: 200,
      editable: false,
      valueGetter: (params) => {
        const frequency = params.row.frequency;
        if (frequency) {
          const formattedDate = new Date(
            frequency.startDateTime,
          ).toLocaleString();
          return formattedDate;
        }
        return '';
      },
    },
    {
      field: 'orgs',
      headerName: 'Workspaces',
      width: 200,
      editable: false,
      renderCell: ({row}) => (
        <div
          onClick={() => {
            if (row) {
              fillModalFieldsWithEditingData(row);
              setSchedulerId(row._id);
            }
            handleOpenOrgs();
          }}
          style={{cursor: 'pointer'}}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <CloudIcon style={orgsIcon.cloudIcon} />
            <button style={{background: 'none', border: 'none'}}>
              <span>{row.scandetails.length}</span>
            </button>
          </div>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const row = params.row;
        const statusv = row.status;

        if (statusv === 'scheduled') {
          return (
            <div>
              <Tooltip title='Scheduled'>
                <EventAvailableIcon
                  style={{color: '#0a8fdc', cursor: 'pointer'}}
                />
              </Tooltip>
            </div>
          );
        }
        if (statusv === 'queued') {
          return (
            <div>
              <Tooltip title='Queued'>
                <HourglassBottomIcon
                  style={{color: '#ff75e0', cursor: 'pointer'}}
                />
              </Tooltip>
            </div>
          );
        }
        if (statusv === 'running') {
          return (
            <div>
              <Tooltip title='Running'>
                <PlayCircleFilledWhiteIcon
                  style={{color: '#dcd500', cursor: 'pointer'}}
                />
              </Tooltip>
            </div>
          );
        }
        if (statusv === 'completed') {
          return (
            <div>
              <Tooltip title='Completed'>
                <CheckCircleIcon
                  style={{color: '#00b769', cursor: 'pointer'}}
                />
              </Tooltip>
            </div>
          );
        }
        if (statusv === 'error') {
          return (
            <div>
              <Tooltip title='Error'>
                <ErrorIcon style={{color: '#fc0c07', cursor: 'pointer'}} />
              </Tooltip>
            </div>
          );
        }
        if (statusv === 'removed') {
          return (
            <div>
              <Tooltip title='Removed from queue'>
                <EventBusyIcon style={{color: '#fc0c07', cursor: 'pointer'}} />
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const statev = params.value;
        if (statev === 'ongoing') {
          return (
            <div>
              <Tooltip title='Ongoing'>
                <StartIcon style={{color: '#0a8fdc', cursor: 'pointer'}} />
              </Tooltip>
            </div>
          );
        }
        if (statev === 'completed') {
          return (
            <div>
              <Tooltip title='Completed'>
                <CheckCircleIcon
                  style={{color: '#00b769', cursor: 'pointer'}}
                />
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: '           ',
      headerName: 'Actions',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const row = params.row;
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div>
            <Button
              aria-controls='custom-menu'
              aria-haspopup='true'
              onClick={handleClick}
              variant='text'
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              id='custom-menu-options'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setShowNoRulesMessage(false);
                  editData();
                  setOpen(true);
                  setExecuteDate(false);

                  if (selectedRow) {
                    fillModalFieldsWithEditingData(selectedRow);
                    setIsEditing(true);
                  } else {
                    setIsEditing(false);
                  }

                  setShowUpdateButton(true);
                  setShowDoneButton(false);
                  setShowSecondMenuItem(true);
                  setShowFirstMenuItem(false);
                  setIsValidDate(true);
                  handleClose();
                }}
              >
                Edit
              </MenuItem>

              <MenuItem onClick={deleteModalOpen}>Delete</MenuItem>
              <MenuItem onClick={deleteModalOpenQueue}>
                Delete from Queue
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const objectId = row._id;
                  navigate(
                    `/setup?tab=logging&object=scheduler&objectId=${objectId}`,
                  );
                }}
              >
                View Logs
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  //Rows for DataGrid
  //Custom Toolbar for DataGrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport printOptions={{disableToolbarButton: true}} />
        </GridToolbarContainer>
      </GridToolbarContainer>
    );
  }

  //Modal new scheduler scan box styles
  const modalss = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 420,
    width: 500,
    borderRadius: 5,
    padding: 7,
  };
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
    setOpenInfo(false);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setOpenInfo(false);
  };

  const [openSnackbarWeekday, setOpenSnackbarWeekday] = useState(false);

  const handleSnackbarCloseWeekday = () => {
    setSelectedRecurrence('');
    setOpenSnackbarWeekday(false);
  };

  const [snackbarMessageToday, setSnackbarMessageToday] = useState('');

  const [openSnackbarToday, setOpenSnackbarToday] = useState(false);

  const handleSnackbarCloseToday = () => {
    setOpenSnackbarToday(false);
  };

  //handle for date field
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);

  const [selectedDate, setSelectedDate] = useState(tomorrow);
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const [selectedTime, setSelectedTime] = useState(getCurrentTime());
  const [executeDate, setExecuteDate] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);

  const handleDateChange = (date) => {
    if (!date) {
      setIsValidDate(false);
      return;
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);

    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);

    const selectedDateTime = new Date(date);
    selectedDateTime.setHours(0, 0, 0, 0);

    if ((selectedDateTime < twoDaysLater)) {
      const formattedDate = formatDate(twoDaysLater);
      setSnackbarMessageToday(`The earliest you can schedule these scan(s) is for ${formattedDate}`);
      setOpenSnackbarToday(true);
      setIsValidDate(false);
      return;
    }

    setSelectedDate(date);
    validateDateAndRecurrence(date, selectedRecurrence);

    if (executeDate && selectedDateTime.getTime() === currentDate.getTime()) {
      const formattedDate = formatDate(tomorrow);
      setSnackbarMessageToday(`The earliest you can schedule these scan(s) is for ${formattedDate}`);
      setOpenSnackbarToday(true);
      setIsValidDate(false);
    } else {
      setOpenSnackbarToday(false);
      setIsValidDate(true);
    }
  };
  

  function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  const validateDateAndRecurrence = (date, recurrence) => {
    if (
      recurrence === 'weekday' &&
      date &&
      (date.getDay() === 0 || date.getDay() === 6)
    ) {
      setOpenSnackbarWeekday(true);
    } else {
      setOpenSnackbarWeekday(false);
    }
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  let formattedStartDateTime = null;

  if (selectedDate) {
    const [hoursStr, minutesStr] = selectedTime.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (
      !isNaN(hours) &&
      !isNaN(minutes) &&
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59
    ) {
      const combinedDateTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        hours,
        minutes,
      );
      formattedStartDateTime = combinedDateTime;
    } else {
      formattedStartDateTime = null;
    }
  }

  //time zone
  const timeZone = ['pacific', 'central', 'eastern', 'mountain', 'universal'];

  //info modal (second step)
  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = () => {
    setOpen(false);
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const modalss2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minHeight: '500',
    width: 500,
    borderRadius: 5,
    padding: 7,
    overflowY: 'auto',
  };

  const modalss3 = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minHeight: '500',
    width: '70%',
    borderRadius: 5,
    padding: 7,
    overflowY: 'auto',
  };

  //modals connection
  const handleNextButtonClick = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(0, 0, 0, 0);
  
    const minimumDate = new Date(currentDate);
    minimumDate.setDate(currentDate.getDate() + 2);

    if (selectedDateTime < minimumDate) {
      const formattedDate = formatDate(minimumDate);
      setSnackbarMessageToday(
        `The earliest you can schedule these scan(s) is for ${formattedDate}`,
      );
      setOpenSnackbarToday(true);
      return;
    } else if (selectedDateTime < currentDate) {

      setSnackbarMessageToday("You can't select a previous date");
      setOpenSnackbarToday(true);
      return;
    } else {
      setOpenSnackbarToday(false);
    }
  
    if (selectedRecurrence === 'Weekday' && selectedDate) {
      if (selectedDate.getDay() === 0 || selectedDate.getDay() === 6) {
        setOpenSnackbarWeekday(true);
      } else {
        handleCloseModal();
        handleOpenInfo();
      }
    } else {
      handleCloseModal();
      handleOpenInfo();
    }
  };
  
  useEffect(() => {
    if (filteredOrgs.length > 0) {
      setSelectedOrg(filteredOrgs[0].name);
    }
  }, [filteredOrgs]);

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  //time picker
  // useEffect(() => {
  //   const currentTime = new Date();
  //   const formattedTime = format(currentTime, 'HH:mm');
  //   setSelectedTime(formattedTime);
  // }, []);

  //add org info (second step)
  const [selectedOrg, setSelectedOrg] = useState('');
  const [orgInfoList, setOrgInfoList] = useState([]);
  const [orgInfoListForOrgs, setOrgInfoListForOrgs] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [deletedOrgs, setDeletedOrgs] = useState([]);
  const [orgIdsToSend, setOrgIdsToSend] = useState([]);

  const handleAddOrgInfoUpdate = () => {
    handleMenuClose();
    const selectedOrgData = schedulerOrgs.find(
      (org) => org.name === selectedOrg,
    );

    if (!selectedOrgData) {
      console.error('Org not found:', selectedOrg);
      return;
    }

    if (orgInfoList.some((orgInfo) => orgInfo.org === selectedOrgData.name)) {
      setSnackbarMessageError(
        `The org "${selectedOrgData.name}" already added.`,
      );
      setOpenSnackbarOrgError(true);
      return;
    }

    if (addedOrgs.includes(selectedOrg)) {
      const existingOrgIndex = orgInfoList.findIndex(
        (orgInfo) => orgInfo.org === selectedOrg,
      );
      if (existingOrgIndex !== -1) {
        handleDeleteOrgInfo(existingOrgIndex);
         // eslint-disable-next-line no-unused-vars
         const unusedVariable3 = deletedOrgs;
        //console.log(deletedOrgs);
      }
    }

    setSelectedOrgData(selectedOrgData);
    handleAddOrgToSchedule(0, selectedOrgData);

    if (selectedOrgData) {
      setOrgInfoList((prevList) => [
        ...prevList,
        {
          org: selectedOrgData.name,
          id: selectedOrgData._id,
          timestamp: new Date().toLocaleString(),
        },
      ]);
      setAddedOrgs((prevOrgs) => [...prevOrgs, selectedOrg]);

      const updatedOrgsScanDetailsList = [
        ...orgsScanDetailsList,
        {
          orgId: selectedOrgData._id,
        },
      ];

      const updatedData = {
        name: scheduleName,
        customerName: companyInfo.company_name,
        customerId: companyInfo.customer_id,
        author: userInfo.usr.email,
        type: typeOrg,
        state: state,
        status: status,
        frequency: {
          noOfOccurrences: valueRepeat2,
          occurrenceType: selectedRecurrence,
          startDateTime: selectedDate,
          repeats: valueRepeat,
          repeatFormat: selectedRepeatOption,
          // weekday: selectedDay,
          ends: selectedOption,
          endDateTime: customEndDate,
          timeZone: selectedTimeZone,
        },
        scandetails: updatedOrgsScanDetailsList,
      };
      const currentURL = window.location.href;

      if (currentURL.includes('configure?tab=scheduler')) {
        dispatch(updateScheduler(selectedRow._id, updatedData));
      } else if (currentURL.includes('app/dashboard')) {
        updatedData.orgId = org_id;
        dispatch(updateScheduler(selectedRow._id, updatedData, true));
      }
    }

    setSelectedOrg('');
  };

  const handleAddOrgInfo = () => {
    const selectedOrgData = schedulerOrgs.find(
      (org) => org.name === selectedOrg,
    );

    if (!selectedOrgData) {
      console.error('Org not found:', selectedOrg);
      return;
    }

    if (orgInfoList.some((orgInfo) => orgInfo.org === selectedOrgData.name)) {
      setSnackbarMessageError(
        `The org "${selectedOrgData.name}" already added.`,
      );
      setOpenSnackbarOrgError(true);
      return;
    }

    if (addedOrgs.includes(selectedOrg)) {
      const existingOrgIndex = orgInfoList.findIndex(
        (orgInfo) => orgInfo.org === selectedOrg,
      );
      if (existingOrgIndex !== -1) {
        handleDeleteOrgInfo(existingOrgIndex);
      }
    }

    setSelectedOrgData(selectedOrgData);
    handleAddOrgToSchedule(0, selectedOrgData);

    if (selectedOrgData) {
      setOrgInfoList((prevList) => [
        ...prevList,
        {
          org: selectedOrgData.name,
          id: selectedOrgData._id,
          timestamp: new Date().toLocaleString(),
        },
      ]);

      setOrgIdsToSend((prevIdsList) => [...prevIdsList, selectedOrgData._id]);

      setAddedOrgs((prevOrgs) => [...prevOrgs, selectedOrg]);
    }
    setSelectedOrg('');
  };

  const handleDeleteOrgInfo = (index) => {
    const deletedOrg = orgInfoList[index];

    const updatedOrgInfoList = [...orgInfoList];
    updatedOrgInfoList.splice(index, 1);

    const updatedOrgsScanDetailsList = orgsScanDetailsList.filter(
      (scanDetail) => scanDetail.orgId !== deletedOrg.id,
    );

    setDeletedOrgs((prevDeletedOrgs) => [...prevDeletedOrgs, deletedOrg]);

    setOrgInfoList(updatedOrgInfoList);
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
  };

  const handleDeleteOrgInfoUpdate = (index) => {
    const orgIdToDelete = orgInfoList[index].orgId;

    const updatedOrgsScanDetailsList = orgsScanDetailsList.filter(
      (scanDetails) => scanDetails.orgId !== orgIdToDelete,
    );

    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOrgInfoList((prevList) => prevList.filter((orgInfo, i) => i !== index));
    setAddedOrgs((prevOrgs) => prevOrgs.filter((org) => org !== orgIdToDelete));

    const updatedData = {
      name: scheduleName,
      customerName: companyInfo.company_name,
      customerId: companyInfo.customer_id,
      author: userInfo.usr.email,
      type: typeOrg,
      state: state,
      status: status,
      frequency: {
        noOfOccurrences: valueRepeat2,
        occurrenceType: selectedRecurrence,
        startDateTime: selectedDate,
        repeats: valueRepeat,
        repeatFormat: selectedRepeatOption,
        // weekday: selectedDay,
        ends: selectedOption,
        endDateTime: customEndDate,
        timeZone: selectedTimeZone,
      },
      scandetails: updatedOrgsScanDetailsList,
    };

    const currentURL = window.location.href;

    if (currentURL.includes('configure?tab=scheduler')) {
      dispatch(updateScheduler(selectedRow._id, updatedData));
    } else if (currentURL.includes('app/dashboard')) {
      updatedData.orgId = org_id;
      dispatch(updateScheduler(selectedRow._id, updatedData, true));
    }
  };

  const addAllOrgs = () => {
    const newOrgs = schedulerOrgs
      .filter((org) => !addedOrgs.includes(org.name))
      .map((org) => ({
        org: org.name,
        id: org._id || null,
        timestamp: new Date().toLocaleString(),
      }));

    if (newOrgs.length === 0) {
      return;
    }

    const updatedOrgInfoList = [...orgInfoList];
    const updatedOrgsScanDetailsList = [...orgsScanDetailsList];

    const orgIdsToAdd = [];

    newOrgs.forEach((newOrg) => {
      const existingOrgIndex = updatedOrgInfoList.findIndex(
        (orgInfo) => orgInfo.org === newOrg.org,
      );

      if (existingOrgIndex === -1) {
        updatedOrgInfoList.push(newOrg);

        const orgScanData = schedulerOrgs.find(
          (org) => org.name === newOrg.org,
        );
        const scanrules = orgScanData ? orgScanData.scanrules : [];

        if (
          !updatedOrgsScanDetailsList.some((org) => org.orgId === newOrg.id)
        ) {
          updatedOrgsScanDetailsList.push({
            orgId: newOrg.id || null,
            scanrules: scanrules,
          });
          orgIdsToAdd.push(newOrg.id);
        }
      }
    });

    setOrgInfoList(updatedOrgInfoList);
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);

    setOrgIdsToSend((prevIdsList) => [...prevIdsList, ...orgIdsToAdd]);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  //handler for new scan rules
  const [visibleRecords, setVisibleRecords] = useState(10);

  const handleMoreClick = (event, index, orgInfo) => {
    const selectedOrgId = orgInfo.id;

    const selectedOrgDetails = orgsScanDetailsList.find(
      (orgDetails) => orgDetails.orgId === selectedOrgId,
    );

    setSelectedOrgData({
      scanrules: selectedOrgDetails?.scanrules || null,
    });

    const orgSettings = selectedOrgDetails?.scansettings || {};
    setStaticCode(orgSettings.static_code);
    setRuntimeTesting(orgSettings.runtime_testing);
    setSoftwareCompositionAnalysis(orgSettings.software_composition_analysis);
    setConfigurationTesting(orgSettings.configuration_testing);
    setThirdPartyPackages(orgSettings.third_party_packages);
    // setAutoScanning(orgSettings.auto_scanning);
    // setSendResultsToAllUsers(orgSettings.send_results_to_all_users);
    setCodeQuality(orgSettings.code_quality);
    setTrackFindingPerOrg(orgSettings.track_finding_per_org);
    setShowNewFindings(orgSettings.show_new_findings);

    const orgJira = selectedOrgDetails?.jirasettings || {};
    setJiraUserName(orgJira.jira_username);
    setJiraEmail(orgJira.jira_email);
    setApiToken(orgJira.api_token);
    setProjectId(orgJira.project_id);
    setProjectKey(orgJira.project_key);

    const orgScanName = selectedOrgDetails?.name || '';
    setScanName(orgScanName);

    const orgTags = selectedOrgDetails?.tags || [];
    setTags(orgTags);

    const orgIgnoreList = selectedOrgDetails?.ignorelist || [];
    setRules(orgIgnoreList);

    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(index);
    setSelectedOrgId(orgInfo.id);
  };

  const handleMoreClickUpdate = (event, index, orgInfo) => {
    const selectedOrgId = orgInfo.id;

    const selectedOrgDetails = orgsScanDetailsList.find(
      (orgDetails) => orgDetails.orgId === selectedOrgId,
    );

    if (selectedOrgDetails) {
      const orgScanRules = selectedOrgDetails.scanrules;
      setScanRulesData(orgScanRules);

      const orgSettings = selectedOrgDetails.scansettings;
      setStaticCode(orgSettings?.static_code || null);
      setRuntimeTesting(orgSettings?.runtime_testing || null);
      setSoftwareCompositionAnalysis(
        orgSettings?.software_composition_analysis || null,
      );
      setConfigurationTesting(orgSettings?.configuration_testing || null);
      setThirdPartyPackages(orgSettings?.third_party_packages || null);
      // setAutoScanning(orgSettings?.auto_scanning || null);
      // setSendResultsToAllUsers(orgSettings?.send_results_to_all_users || null);
      setCodeQuality(orgSettings?.code_quality || null);
      setTrackFindingPerOrg(orgSettings?.track_finding_per_org || null);
      setShowNewFindings(orgSettings?.show_new_findings || null);
      const orgJira = selectedOrgDetails.jirasettings;
      setJiraUserName(orgJira?.jira_username || null);
      setJiraEmail(orgJira?.jira_email || null);
      setApiToken(orgJira?.api_token || null);
      setProjectId(orgJira?.project_id || null);
      setProjectKey(orgJira?.project_key || null);

      const orgScanName = selectedOrgDetails.name;
      setScanName(orgScanName);

      const orgTags = selectedOrgDetails.tags;
      setTags(orgTags);

      const orgIgnoreList = selectedOrgDetails.ignorelist;
      setRules(orgIgnoreList);
    }

    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(index);

    if (orgInfo.data && orgInfo.data.orgId !== null) {
      setSelectedOrgId(orgInfo.data.orgId);
    } else {
      console.warn();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowIndex(null);
  };

  const handleSeverityChange = (index, newSeverity) => {
    const updatedOrgData = {...selectedOrgData};
    updatedOrgData.scanrules[index].severity = newSeverity;

    setSelectedOrgData(updatedOrgData);
  };

  const handleAddScanRules = () => {
    const scanRulesInfo = selectedOrgData.scanrules.map((rule) => ({
      vulnid: rule.vulnid,
      title: rule.title,
      severity: rule.severity,
      active: rule.active,
      description: rule.description,
      remediation: rule.remediation,
      additional: rule.additional,
      category: rule.category,
      complianceViolations: rule.complianceViolations,
      complianceTypes: rule.complianceTypes,
      cweViolation: rule.cweViolation,
      id: rule.id,
      sast: rule.sast,
    }));

    setScanRulesData(scanRulesInfo);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            scanrules: scanRulesInfo,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenRules(false);
  };

  const [scanRulesData, setScanRulesData] = useState([]);

  const handleAddScanRulesUpdate = () => {
    const scanRulesInfo = selectedOrgData.scanrules.map((rule) => ({
      vulnid: rule.vulnid,
      title: rule.title,
      severity: rule.severity,
      active: rule.active,
      description: rule.description,
      remediation: rule.remediation,
      additional: rule.additional,
      category: rule.category,
      complianceViolations: rule.complianceViolations,
      complianceTypes: rule.complianceTypes,
      cweViolation: rule.cweViolation,
      id: rule.id,
      sast: rule.sast,
    }));

    setScanRulesData(scanRulesInfo);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            scanrules: scanRulesInfo,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenRules(false);
  };

  const handleSwitchChange = (index) => {
    const updatedOrgData = {...selectedOrgData};
    updatedOrgData.scanrules[index].active =
      !updatedOrgData.scanrules[index].active;
    setSelectedOrgData(updatedOrgData);
  };

  //modal for settings
  // const [selectedEditOrg, setSelectedEditOrg] = useState({});

  const [openSettings, setOpenSettings] = useState(false);
  const [orgSettings, setOrgSettings] = useState({
    data: {},
    index: null,
  });

  // useEffect(() => {
  //   console.log('org updated settings', orgSettings);
  // }, [orgSettings]);

  // useEffect(() => {
  //   console.log('selected edit org:', selectedEditOrg);
  //   handleOpenSettingsNew(selectedEditOrg.org, selectedEditOrg.index);
  // }, [selectedEditOrg]);

  const handleOpenSettingsNew = (data, index) => {
    // eslint-disable-next-line no-unused-vars
    const unusedVariable = orgSettings;
    // console.log(orgSettings);

    if (sendScheduleSettings) {
      if (index != null && sendScheduleSettings.length > index) {
        if (data.data && data.data.scansettings) {
          setOrgSettings({data: data.data.scansettings});
        } else {
          console.warn();
        }

        if (data.data && data.data.scansettings) {
          setScanName({data: data.data.scansettings});
        } else {
          console.warn();
        }

        if (data.data && data.data.jirasettings) {
          setJiraSettings({data: data.data.jirasettings});
        } else {
          console.warn();
        }

        if (data.data && Array.isArray(data.data.tags)) {
          setTags(data.data.tags);
        } else {
          console.warn();
        }

        if (data.data && data.data.scanrules) {
          setSelectedOrgData({scanrules: data.data.scanrules});
        } else {
          console.warn();
        }

        if (data.data && data.data.scansettings) {
          setRuntimeTesting(data.data.scansettings.runtime_testing);
          setStaticCode(data.data.scansettings.static_code);
          setSoftwareCompositionAnalysis(
            data.data.scansettings.software_composition_analysis,
          );
          setConfigurationTesting(data.data.scansettings.configuration_testing);
          setThirdPartyPackages(data.data.scansettings.third_party_packages);
          // setAutoScanning(data.data.scansettings.auto_scanning);
          // setSendResultsToAllUsers(
          //   data.data.scansettings.send_results_to_all_users,
          // );
          //jira
          setJiraUserName(data.data.jirasettings.jira_username);
          setJiraEmail(data.data.jirasettings.jira_email);
          setApiToken(data.data.jirasettings.api_token);
          setProjectId(data.data.jirasettings.project_id);
          setProjectKey(data.data.jirasettings.project_key);
          //advanced
          setCodeQuality(data.data.scansettings.code_quality);
          setTrackFindingPerOrg(data.data.scansettings.track_finding_per_org);
          setShowNewFindings(data.data.scansettings.show_new_findings);
        } else {
          console.warn();
        }

        if (data.data && data.data.ignorelist) {
          setRules(data.data.ignorelist);
        } else {
          console.warn();
        }

        if (data.data && data.data.scanrules) {
          setScanName(data.data.name);
          setScanRulesData(data.data.scanrules);
        } else {
          console.warn();
        }

        // eslint-disable-next-line no-unused-vars
        const unusedVariable2 = scanRulesData;
        // console.log('Scan Rules on Edit:', scanRulesData);
      } else {
        console.error();
      }
    } else {
      console.error();
    }
  };

  const editData = (data, index) => {
    if (sendScheduleSettings && sendScheduleSettings.length > index) {
      setOrgSettings({data: data.data.scansettings});
      setJiraSettings({data: data.data.jirasettings});
      setTags({data: data.data.tags});
      setRuntimeTesting(data.data.scansettings.runtime_testing);
      setStaticCode(data.data.scansettings.static_code);
      setSoftwareCompositionAnalysis(
        data.data.scansettings.software_composition_analysis,
      );
      setConfigurationTesting(data.data.scansettings.configuration_testing);
      setThirdPartyPackages(data.data.scansettings.third_party_packages);
      // setAutoScanning(data.data.scansettings.auto_scanning);
      // setSendResultsToAllUsers(
      //   data.data.scansettings.send_results_to_all_users,
      // );
      //jira
      setJiraUserName(data.data.jirasettings.jira_username);
      setJiraEmail(data.data.jirasettings.jira_email);
      setApiToken(data.data.jirasettings.api_token);
      setProjectId(data.data.jirasettings.project_id);
      setProjectKey(data.data.jirasettings.project_key);
      //advanced
      setCodeQuality(data.data.scansettings.code_quality);
      setTrackFindingPerOrg(data.data.scansettings.track_finding_per_org);
      setShowNewFindings(data.data.scansettings.show_new_findings);
      //ignorelist
      setRules(data.data.ignorelist);
      //tags
      setTags(data.data.tags);
    } else {
      console.error;
    }
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  //modal for scan ignorelist
  const [openRules, setOpenRules] = useState(false);

  const handleOpenRules = () => {
    setOpenRules(true);
  };

  const handleCloseRules = () => {
    setOpenRules(false);
  };

  //modal for ignore list
  const [openIgnore, setOpenIgnore] = useState(false);

  const handleOpenIgnore = () => {
    setOpenIgnore(true);
  };

  const handleCloseIgnore = () => {
    setOpenIgnore(false);
  };

  const styleCustomRules = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '350px',
      sm: '500px',
      md: '500px',
      lg: '500px',
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  };

  //modal for advanced
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const handleOpenAdvanced = () => {
    setOpenAdvanced(true);
  };

  const handleCloseAdvanced = () => {
    setOpenAdvanced(false);
  };

  //modal for custom recurrence
  const recurrenceOptions = [
    'does not repeat',
    'daily',
    'weekday',
    'weekly',
    'monthly',
    'annually',
    'custom',
  ];

  const [openRecurrence, setOpenRecurrence] = useState(false);
  const [dateValid, setDateValid] = useState(true);

  const handleCloseRecurrenceModal = () => {
    setOpenRecurrence(false);
  };

  const handleCloseRecurrence = () => {
    if (dateValid) {
      setOpenRecurrence(false);
    } else {
      setOpenSnackbarToday(true);
    }
  };

  //event on last recurrence option
  const [selectedRecurrence, setSelectedRecurrence] =
    useState('does not repeat');

  const handleRecurrenceChange = (event) => {
    const newRecurrence = event.target.value;
    setSelectedRecurrence(newRecurrence);
    validateDateAndRecurrence(selectedDate, newRecurrence);
  };

  const handleCustomClick = () => {
    setOpenRecurrence(true);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || !isNaN(inputValue)) {
      setValueRepeat(inputValue === '' ? '' : parseInt(inputValue, 10));
    }
  };

  const sValidation = (valueRepeat, option) => {
    if (valueRepeat > 1) {
      return option + 's';
    }
    return option;
  };

  const [valueRepeat, setValueRepeat] = useState(0);
  const [scheduleName, setScheduleName] = useState('');
  const [selectedRepeatOption, setSelectedRepeatOption] = useState('day');

  const repeatOptions = ['day', 'week', 'month', 'year'];

  // const weekday = [
  //   'sunday',
  //   'monday',
  //   'tuesday',
  //   'wednesday',
  //   'thursday',
  //   'friday',
  //   'saturday',
  // ];

  // const currentDate = new Date();

  // let currentDayNumber = currentDate.getDay();

  // const currentDay = weekday[currentDayNumber];

  // const [selectedDay, setSelectedDay] = useState(currentDay);

  // const toggleDayWeek = (day) => {
  //   setSelectedDay(day === selectedDay ? null : day);
  // };

  const [selectedOption, setSelectedOption] = useState('never');
  const [isDatePickerEnabled, setDatePickerEnabled] = useState(false);
  const [isTextFieldEnabled, setTextFieldEnabled] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value === 'date') {
      setDatePickerEnabled(true);
      setCustomEndDate(null);
    } else {
      setDatePickerEnabled(false);
    }

    setTextFieldEnabled(value === 'after');
  };

  const [valueRepeat2, setValueRepeat2] = useState(0);
  const handleTextFieldChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || !isNaN(inputValue)) {
      setValueRepeat2(inputValue === '' ? '' : parseInt(inputValue, 10));
    }
  };

  //orgs modal
  const [openOrgs, setOpenOrgs] = useState(false);

  const handleOpenOrgs = () => {
    setOpenOrgs(true);
  };

  const handleCloseOrgs = () => {
    setOpenOrgs(false);
  };

  const [selectedEndTime, setSelectedEndTime] = useState('00:00');

  const [customEndDate, setCustomEndDate] = useState(tomorrow);

  const handleEndTimeChange = (event) => {
    const value = event.target.value;

    if (/^\d{2}:\d{2}$/.test(value)) {
      const combinedTime = '23:59:59';
      setSelectedEndTime(combinedTime);
    } else {
      console.error('Not valid time');
    }
  };

  let formattedEndDateTime = null;

  if (customEndDate) {
    const hours = 23;
    const minutes = 59;
    const seconds = 59;

    const combinedDateTime = new Date(
      customEndDate.getFullYear(),
      customEndDate.getMonth(),
      customEndDate.getDate(),
      hours,
      minutes,
      seconds,
    );

    formattedEndDateTime = combinedDateTime;
  }

  // useEffect(() => {
  //   console.log('orgs id after click: ', orgIdsToSend);
  // }, [orgIdsToSend]);

  const [selectedTmeZone, setSelectedTmeZone] = useState(timeZone[0]);
  const [sendScheduleSettings, setSendScheduleSettings] = useState([]);
  const [addedOrgs, setAddedOrgs] = useState([]);
  // const [scanRulesForRecord, setScanRulesForRecord] = useState([]);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openErrorSnackbar = () => {
    setErrorSnackbarOpen(true);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackbarOpen(false);
  };

  useEffect(() => {
    if (errorSnackbarOpen) {
      const timeout = setTimeout(() => {
        closeErrorSnackbar();
      }, 7000);
      return () => clearTimeout(timeout);
    }
  }, [errorSnackbarOpen]);

  const [openSnackbarCreated, setOpenSnackbarCreated] = useState(false);

  const handleCloseSnackbarCreated = () => {
    setOpenSnackbarCreated(false);
  };

  const sendScheduleReq = async () => {
    const schedulData = {
      name: scheduleName || '',
      customerId: companyInfo.customer_id,
      customerName: companyInfo.company_name,
      author: userInfo.usr.email,
      type: typeOrg,
      frequency: {
        noOfOccurrences: valueRepeat2,
        occurrenceType: selectedRecurrence || '',
        startDateTime: formattedStartDateTime || '',
        repeats: valueRepeat,
        repeatFormat: selectedRepeatOption || '',
        ends: selectedOption || '',
        endDateTime: formattedEndDateTime || '',
        timeZone: selectedTimeZone || '',
      },
      scandetails: orgsScanDetailsList,
    };

    try {
      if (!orgsScanDetailsList.length) {
        setSnackbarMessageError(
          `Please add at least 1 workspace to this schedule`,
        );
        setOpenSnackbarOrgError(true);
      } else {
        const isScanSettingsEmpty = orgsScanDetailsList.some((scandetails) =>
          Object.values(scandetails.scansettings).every(
            (value) => value === false,
          ),
        );

        if (isScanSettingsEmpty) {
          setOpenSnackbarOrgSettings(true);
          setSnackbarMessageSettings(
            `You must select at least 1 setting for scan`,
          );
          handleOpenSettings();
          return;
        }

        handleCloseInfo();
        const currentURL = window.location.href;
        let response;
        if (currentURL.includes('configure?tab=scheduler')) {
          response = await dispatch(
            checkIfOrgInSchedule({
              orgIdsToSend: orgIdsToSend,
              startDateTime: schedulData.frequency.startDateTime,
            }),
          );
        } else if (currentURL.includes('app/dashboard')) {
          const orgId = org_id;
          response = await dispatch(
            checkIfOrgInSchedule({
              orgIdsToSend: orgIdsToSend,
              startDateTime: schedulData.frequency.startDateTime,
              isOrgSchedule: true,
              orgId: orgId,
            }),
          );
        }

        const orgsScanDetailIds = orgsScanDetailsList.map((scan) => scan.orgId);

        const commonIds = response.filter((id) =>
          orgsScanDetailIds.includes(id),
        );

        if (commonIds.length > 0) {
          const commonNames = commonIds.map((id) => {
            const org = schedulerOrgs.find((org) => org._id === id);
            return org ? org.name : '';
          });

          // setSnackbarOpenId(true);
          const listItems = commonNames.join('\n');

          // const snackbarContent = `The following organizations are already added to a schedule:\n${listItems}`;
          openErrorSnackbar();
          // setSnackbarMessage(snackbarContent);
          setModalContent(listItems);
          setOpenInfo(true);
        } else {
          if (currentURL.includes('configure?tab=scheduler')) {
            await dispatch(createScheduler(schedulData));
            setOpenSnackbarCreated(true);
            setTimeout(() => {
              dispatch(getAllScheduled(companyInfo.customer_id));
            }, 1000);
          } else if (currentURL.includes('app/dashboard')) {
            schedulData.orgId = org_id;
            await dispatch(createScheduler(schedulData, true));
            setOpenSnackbarCreated(true);
            setTimeout(() => {
              dispatch(getOrgSchedules(org_id));
            }, 1000);
          }
          setOpenInfo(false);
        }
      }
    } catch (error) {
      console.error('Error in checkIfOrgInSchedule', error);
    }
  };

  const [openSnackbarDeleted, setOpenSnackbarDeleted] = useState(false);

  const handleCloseSnackbarDeleted = () => {
    setOpenSnackbarDeleted(false);
  };

  const handleDeleteClick = () => {
    const currentURL = window.location.href;

    if (currentURL.includes('configure?tab=scheduler')) {
      dispatch(deleteScheduler(selectedRow._id));
      dispatch(getAllScheduled(companyInfo.customer_id));
      setOpenSnackbarDeleted(true);
    } else if (currentURL.includes('app/dashboard')) {
      dispatch(deleteScheduler(selectedRow._id, org_id, true));
      setTimeout(() => {
        dispatch(getOrgSchedules(org_id));
        setOpenSnackbarDeleted(true);
      }, 1000);
    }
    setDeleteModal(false);
    dispatch(getAllScheduled(companyInfo.customer_id));
  };

  //delete modal
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteModalOpen = () => {
    setDeleteModal(true);
  };
  const deleteModalClose = () => {
    setDeleteModal(false);
  };

  //delete modal queue
  const [deleteModalQueue, setDeleteModalQueue] = useState(false);

  const deleteModalOpenQueue = () => {
    setDeleteModalQueue(true);
  };
  const deleteModalCloseQueue = () => {
    setDeleteModalQueue(false);
  };

  const handleDeleteClickQueue = () => {
    const currentURL = window.location.href;

    if (currentURL.includes('configure?tab=scheduler')) {
      dispatch(deleteScheduleFromQueue(selectedRow._id));
    } else if (currentURL.includes('app/dashboard')) {
      dispatch(deleteScheduleFromQueue(selectedRow._id, org_id, true));
    }
    setDeleteModalQueue(false);
    dispatch(getAllScheduled(companyInfo.customer_id));
  };

  //update
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showDoneButton, setShowDoneButton] = useState(true);
  const [state] = useState('ongoing');
  const [status, setStatus] = useState('scheduled');

  //prefilled modal
  const fillModalFieldsWithEditingData = (record) => {
    setOrgsScanDetailsList([]);
    setScheduleName(record.name || '');
    // setState(record.state || '');
    setStatus(record.status || '');
    setSelectedRecurrence(record.frequency.occurrenceType || '');
    // setSelectedDay(record.frequency.weekday || '');
    setSelectedTmeZone(record.frequency.timeZone || '');
    setSelectedOption(record.frequency.ends || '');
    setValueRepeat(record.frequency.repeats || '');
    setSelectedRepeatOption(record.frequency.repeatFormat || '');
    setValueRepeat2(record.frequency.noOfOccurrences || '');
    setSelectedRecurrence(record.frequency.occurrenceType || '');

    const startDateTime = new Date(record.frequency.startDateTime);

    setSelectedDate(startDateTime);

    const hours = startDateTime.getHours().toString().padStart(2, '0');
    const minutes = startDateTime.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    setSelectedTime(formattedTime);

    setOrgInfoList([]);
    setSendScheduleSettings([]);
    setOrgInfoListForOrgs([]);

    record.scandetails.forEach((element) => {
      setOrgsScanDetailsList((prevOrgs) => [...prevOrgs, element]);
      const selectedOrgData = schedulerOrgs.find(
        (org) => org._id === element.orgId,
      );

      // setSelectedOrgData({scanrules: element.data.scanrules});

      if (selectedOrgData) {
        setOrgInfoList((prevList) => [
          ...prevList,
          {
            data: element,
            org: selectedOrgData.name,
            timestamp: new Date().toLocaleString(),
            orgId: selectedOrgData._id,
          },
        ]);

        setOrgInfoListForOrgs((prevList) => [
          ...prevList,
          {
            data: element,
            org: selectedOrgData.name,
            timestamp: new Date().toLocaleString(),
          },
        ]);

        setSendScheduleSettings((prevList) => [
          ...prevList,
          element.scansettings,
        ]);
      }
    });
  };
  //fill workspaces
  const [orgsScanDetailsList, setOrgsScanDetailsList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // const [orgClickedMap, setOrgClickedMap] = useState({});

  const handleAddOrgToSchedule = (index, org = null) => {
    const selectedOrgData = schedulerOrgs.find(
      (org) => org.name === selectedOrg,
    );
    // if (orgClickedMap[index]) {
    //   setSnackbarMessage('Org is already added to the schedule.');
    //   setSnackbarOpen(true);
    //   return;
    // }

    const orgToAdd = {
      name: scanName,
      orgId: org ? org._id : selectedOrgData ? selectedOrgData._id : null,
      tags: tags,
      scansettings: scansSettings || {
        static_code: true,
        runtime_testing: false,
        software_composition_analysis: true,
        configuration_testing: true,
        third_party_packages: false,
        code_quality: false,
        track_finding_per_org: false,
        show_new_findings: false,
        // auto_scanning: false,
        // send_results_to_all_users: false,
      },
      ignorelist: ignorelist,
      scanrules: selectedOrgData.scanrules,
      jirasettings: jiraSettings,
    };

    // setOrgClickedMap((prevState) => ({
    //   ...prevState,
    //   [index]: true,
    // }));

    const orgAlreadyAdded = orgsScanDetailsList.some(
      (org) => org.orgId === orgToAdd.orgId,
    );

    if (!orgAlreadyAdded) {
      setOrgsScanDetailsList((prevOrgs) => [...prevOrgs, orgToAdd]);
      setSnackbarMessage('Org added to the schedule successfully.');
    } else {
      setSnackbarMessage('Org is already added to the schedule.');
    }
    setSnackbarOpen(true);
  };

  // const handleAddAllOrgsToSchedule = () => {
  //   orgInfoList.forEach((orgInfo) => {
  //     handleAddOrgToSchedule(orgInfo, selectedOrgData);
  //   });
  // };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const [snackbarMessageOrg, setSnackbarMessageError] = useState('');
  const [openSnackbar, setOpenSnackbarOrgError] = useState(false);
  const [snackbarMessageSettings, setSnackbarMessageSettings] = useState('');
  const [openSnackbarSettings, setOpenSnackbarOrgSettings] = useState(false);

  const handleSnackbarCloseSettings = () => {
    setOpenSnackbarOrgSettings(false);
  };

  const handleSnackbarCloseOrgError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarOrgError(false);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  //settings handler
  const [staticCode, setStaticCode] = useState(true);
  const [runtimeTesting, setRuntimeTesting] = useState(false);
  const [softwareCompositionAnalysis, setSoftwareCompositionAnalysis] =
    useState(true);
  const [configurationTesting, setConfigurationTesting] = useState(true);
  const [thirdPartyPackages, setThirdPartyPackages] = useState(false);
  // const [autoScanning, setAutoScanning] = useState(false);
  // const [sendResultsToAllUsers, setSendResultsToAllUsers] = useState(false);

  const [scansSettings] = useState({
    static_code: true,
    runtime_testing: false,
    software_composition_analysis: true,
    configuration_testing: true,
    third_party_packages: false,
    code_quality: false,
    track_finding_per_org: false,
    show_new_findings: false,
    // auto_scanning: false,
    // send_results_to_all_users: false,
  });

  const handleSaveSettings = () => {
    setOpenAdvanced(false);
    setOpenSettings(false);
    const updatedSettings = {
      static_code: staticCode,
      runtime_testing: runtimeTesting,
      software_composition_analysis: softwareCompositionAnalysis,
      configuration_testing: configurationTesting,
      third_party_packages: thirdPartyPackages,
      code_quality: codeQuality,
      track_finding_per_org: trackFindingPerOrg,
      show_new_findings: showNewFindings,
      // auto_scanning: autoScanning,
      // send_results_to_all_users: sendResultsToAllUsers,
    };

    if (selectedOrgData) {
      const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
        (scandetails) => {
          if (scandetails.orgId !== selectedOrgId) {
            return scandetails;
          } else {
            return {
              ...scandetails,
              scansettings: updatedSettings,
            };
          }
        },
      );
      setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    }
  };

  const handleSaveSettingsUpdate = () => {
    setOpenAdvanced(false);
    setOpenSettings(false);
    const updatedSettings = {
      static_code: staticCode,
      runtime_testing: runtimeTesting,
      software_composition_analysis: softwareCompositionAnalysis,
      configuration_testing: configurationTesting,
      third_party_packages: thirdPartyPackages,
      code_quality: codeQuality,
      track_finding_per_org: trackFindingPerOrg,
      show_new_findings:showNewFindings,
      // auto_scanning: autoScanning,
      // send_results_to_all_users: sendResultsToAllUsers,
    };
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            scansettings: updatedSettings,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    // setScansSettings(updatedSettings);
  };

  const [openSnackbarUpdated, setOpenSnackbarUpdated] = useState(false);

  const handleCloseSnackbarUpdated = () => {
    setOpenSnackbarUpdated(false);
  };

  const handleSaveChanges = () => {
    const uniqueOrgsScanDetailsList = Array.from(new Set(orgsScanDetailsList));
    const updatedData = {
      name: scheduleName,
      customerName: companyInfo.company_name,
      customerId: companyInfo.customer_id,
      author: userInfo.usr.email,
      type: typeOrg,
      state: state,
      status: status,
      frequency: {
        noOfOccurrences: valueRepeat2,
        occurrenceType: selectedRecurrence,
        startDateTime: formattedStartDateTime,
        repeats: valueRepeat,
        repeatFormat: selectedRepeatOption,
        // weekday: selectedDay,
        ends: selectedOption,
        endDateTime: formattedEndDateTime,
        timeZone: selectedTmeZone,
      },
      scandetails: uniqueOrgsScanDetailsList,
    };

    if (!uniqueOrgsScanDetailsList.length) {
      setSnackbarMessageError(
        `Please add atleast 1 workspace to this schedule`,
      );
      setOpenSnackbarOrgError(true);
    } else {
      handleCloseInfo();

      const isScanSettingsEmpty = orgsScanDetailsList.some((scandetails) =>
        Object.values(scandetails.scansettings).every(
          (value) => value === false,
        ),
      );

      if (isScanSettingsEmpty) {
        setOpenSnackbarOrgSettings(true);
        setSnackbarMessageSettings(
          `You must select at least 1 setting for scan`,
        );
        handleOpenSettings();
        setOpenInfo(true);
        return;
      }

      const currentURL = window.location.href;

      if (currentURL.includes('configure?tab=scheduler')) {
        dispatch(updateScheduler(selectedRow._id, updatedData));
        setOpenSnackbarUpdated(true);
        setTimeout(() => {
          dispatch(getAllScheduled(companyInfo.customer_id));
        }, 1000);
      } else if (currentURL.includes('app/dashboard')) {
        updatedData.orgId = org_id;
        dispatch(updateScheduler(selectedRow._id, updatedData, true));
        setOpenSnackbarUpdated(true);
        setTimeout(() => {
          dispatch(getOrgSchedules(org_id));
        }, 1000);
      }

      setOpen(false);
    }
  };


  //advanced handler
  const [codeQuality, setCodeQuality] = useState(false);
  const [trackFindingPerOrg, setTrackFindingPerOrg] = useState(false);
  const [showNewFindings, setShowNewFindings] = useState(false);


  //ignore list handler
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [reason, setReason] = useState('');
  const [ignorelist, setRules] = useState([]);
  const [showNoRulesMessage, setShowNoRulesMessage] = useState(true);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleAddRule = () => {
    setShowNoRulesMessage(false);
    const newRule = {
      filename: fileName,
      reason: reason,
      addedBy: userInfo.usr.email,
      date: new Date().toLocaleDateString(),
    };
    setRules([...ignorelist, newRule]);

    setFileName('');
    setReason('');

    handleClose();
  };

  const handleDone = () => {
    handleClose();
    setOpenIgnore(false);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            ignorelist: ignorelist,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setRules([]);
  };

  const handleDoneUpdate = () => {
    handleClose();
    setOpenIgnore(false);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            ignorelist: ignorelist,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
  };

  const handleDeleteRule = (index) => {
    const updatedRules = [...ignorelist];
    updatedRules.splice(index, 1);
    setRules(updatedRules);

    if (updatedRules.length === 0) {
      setShowNoRulesMessage(true);
    }
  };

  //handle tags
  const [tags, setTags] = useState([]);
  const [newTagData, setNewTagData] = useState({
    addLink: false,
    fieldName: '',
    fieldValue: '',
    href: '',
  });

  const handleTagDataChange = (e, index) => {
    const {name, value, checked} = e.target;
    const updatedTags = [...tags];
    updatedTags[index] = {
      ...updatedTags[index],
      [name]: name === 'addLink' ? checked : value,
    };
    setTags(updatedTags);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            tags: updatedTags,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
  };

  const handleTagDataChangeUpdate = (e, index) => {
    const {name, value, checked} = e.target;
    const updatedTags = [...tags];
    updatedTags[index] = {
      ...updatedTags[index],
      [name]: name === 'addLink' ? checked : value,
    };
    setTags(updatedTags);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            tags: updatedTags,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
  };

  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleAddTag = () => {
    setTags([...tags, newTagData]);
    setNewTagData({
      addLink: false,
      fieldName: '',
      fieldValue: '',
      href: '',
    });
  };

  const [tagsModal1, setTagsModal1] = useState(false);

  const tagsModalOpen1 = () => {
    setTagsModal1(true);
  };
  const tagsModalClose = () => {
    setTagsModal1(false);
  };

  const handleSaveTags = () => {
    if (tags.some((tag) => tag.addLink && !tag.href)) {
      openSnackbarTags();
      return;
    }
    setTagsModal1(false);
    setTags([]);
  };

  const handleSaveTagsUpdate = () => {
    if (tags.some((tag) => tag.addLink && !tag.href)) {
      openSnackbarTags();
      return;
    }
    setTagsModal1(false);
  };

  const [snackbarOpenTags, setSnackbarOpenTags] = useState(false);
  const openSnackbarTags = () => {
    setSnackbarOpenTags(true);
  };

  const closeSnackbarTags = () => {
    setSnackbarOpenTags(false);
  };

  //Jira Modal
  const [openJira, setOpenJira] = useState(false);
  const [jiraUsername, setJiraUserName] = useState('');
  const [jiraEmail, setJiraEmail] = useState('');
  const [apiToken, setApiToken] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [jiraSettings, setJiraSettings] = useState([]);

  const handleSaveJira = () => {
    const jiraSettings = {
      jira_output: false,
      jira_username: jiraUsername,
      jira_email: jiraEmail,
      api_token: apiToken,
      project_id: projectId,
      project_key: projectKey,
    };
    dispatch(onPostSubmitJira({jiraForm: jiraSettings}));

    setJiraSettings(jiraSettings);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            jirasettings: jiraSettings,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenJira(false);
    setJiraSettings([]);
  };

  const handleSaveJiraUpdate = () => {
    const jiraSettings = {
      jira_output: false,
      jira_username: jiraUsername,
      jira_email: jiraEmail,
      api_token: apiToken,
      project_id: projectId,
      project_key: projectKey,
    };
    dispatch(onPostSubmitJira({jiraForm: jiraSettings}));
    setJiraSettings(jiraSettings);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            jirasettings: jiraSettings,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenJira(false);
  };

  const handleOpenJira = () => {
    setOpenJira(true);
  };

  const handleJiraClose = () => {
    setOpenJira(false);
  };

  //Edit modal
  const [openEditName, setOpenEditName] = useState(false);
  const [scanName, setScanName] = useState('');

  const handleOpenEditName = () => {
    setOpenEditName(true);
  };

  const handleEditNameClose = () => {
    setOpenEditName(false);
  };

  const handleSaveName = () => {
    setScanName(scanName);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            name: scanName,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenEditName(false);
    setScanName('');
  };

  const handleSaveNameUpdate = () => {
    setScanName(scanName);
    const updatedOrgsScanDetailsList = orgsScanDetailsList.map(
      (scandetails) => {
        if (scandetails.orgId != selectedOrgId) {
          return scandetails;
        } else {
          return {
            ...scandetails,
            name: scanName,
          };
        }
      },
    );
    setOrgsScanDetailsList(updatedOrgsScanDetailsList);
    setOpenEditName(false);
  };

  // const handleDelete = () => {
  //   deleteModalTagOpen();
  //   console.info('You clicked the delete icon.');
  // };

  const emptyData = () => {
    setScheduleName('');
    setSelectedDate(tomorrow);
    setSelectedRecurrence('does not repeat');
    // setSelectedDay('monday');
    setSelectedTmeZone(timeZone[0]);
    setSelectedOption('never');
    setApiToken('');
    setJiraEmail('');
    // setJiraOutput('');
    setJiraUserName('');
    setProjectId('');
    setProjectKey('');
    setFileName('');
    setReason('');
    setRules([]);
    setTags([]);
    setNewTagData({
      addLink: false,
      fieldName: '',
      fieldValue: '',
      href: '',
    });
    // setScanName('');
    setStaticCode(false);
    setRuntimeTesting(false);
    setSoftwareCompositionAnalysis(false);
    setConfigurationTesting(false);
    setThirdPartyPackages(false);
    // setAutoScanning(false);
    // setSendResultsToAllUsers(false);
    setCodeQuality(false);
    setTrackFindingPerOrg(false);
    setShowNewFindings(false);
    setOrgInfoList([]);
    setSelectedOrg('');
    setOrgsScanDetailsList([]);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tagIndexToDelete, setTagIndexToDelete] = useState(null);

  // const handleTagRemove = (index) => {
  //   setConfirmDelete(true);
  //   setTagIndexToDelete(index);
  // };

  const confirmDeletion = () => {
    if (confirmDelete && tagIndexToDelete !== null) {
      removeTag(tagIndexToDelete);
    }
    setConfirmDelete(false);
    setTagIndexToDelete(null);
  };

  //validation for edit o menu
  const [showFirstMenuItem, setShowFirstMenuItem] = useState(true);
  const [showSecondMenuItem, setShowSecondMenuItem] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(false);

  const orderQueueOrgInfoList = [...orgInfoListForOrgs].sort(
    (a, b) => a.data.queueorder - b.data.queueorder,
  );
  const [snackbarOpenId, setSnackbarOpenId] = useState(false);

  const handleCloseSnackbarId = () => {
    setSnackbarOpenId(false);
  };

  const currentTimeZone = getUserTimeZone();
  const [selectedTimeZone] = useState(currentTimeZone);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  function getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(undefined, {
      timeZoneName: 'long',
      hour: '2-digit',
      minute: '2-digit',
    })
      .formatToParts(new Date())
      .filter((part) => part.type === 'timeZoneName')[0].value;
    return userTimeZone;
  }

  return (
    <>
      {permissions && (
        <div className='main'>
          <div className='header-container'>
            <h2>Scheduler</h2>
            <Button
              onClick={() => {
                emptyData();
                setOpen(true);
                setShowUpdateButton(false);
                setShowDoneButton(true);
                setShowSecondMenuItem(false);
                setShowFirstMenuItem(true);
                setOrgInfoListForOrgs([]);
                setIsEditing(false);
                setIsValidDate(true);
                orgIdsToSend.length = 0;
                // setOrgClickedMap({});
              }}
              sx={{marginRight: 1}}
              variant='contained'
              disabled= {!writeable}
            >
              + Schedule a scan
            </Button>
          </div>
          <div className='dt-container'>
            <div style={{height: 500, width: '100%', overflow: 'auto'}}>
              {location.pathname.includes('app/dashboard') ? (
                <DataGrid
                  id='logs-dt'
                  rows={orgSchedulerData || []}
                  getRowId={(row) => row._id}
                  hideFooterPagination={true}
                  hideFooter={true}
                  hideFooterSelectedRowCount={true}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      lineHeight: 'unset !important',
                      maxHeight: 'none !important',
                      whiteSpace: 'normal',
                      justifyContent: 'center !important',
                      textAlign: 'center !important',
                    },
                  }}
                  onRowClick={(params) => {
                    setSelectedRow(params.row);
                  }}
                />
              ) : (
                <DataGrid
                  id='logs-dt'
                  rows={schedulerData.schedules || []}
                  getRowId={(row) => row._id}
                  hideFooterPagination={true}
                  hideFooter={true}
                  hideFooterSelectedRowCount={true}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      lineHeight: 'unset !important',
                      maxHeight: 'none !important',
                      whiteSpace: 'normal',
                      justifyContent: 'center !important',
                      textAlign: 'center !important',
                    },
                  }}
                  onRowClick={(params) => {
                    setSelectedRow(params.row);
                  }}
                />
              )}
            </div>
          </div>
          {/* Main Modal */}
          <Modal open={open} onClose={handleCloseModal}>
            <Box sx={modalss}>
              <div>
                <TextField
                  fullWidth
                  label='Scan Scheduler Name'
                  id='fullWidth'
                  variant='filled'
                  value={scheduleName}
                  onChange={(e) => setScheduleName(e.target.value)}
                />
              </div>
              <br></br>
              <div className='first-container'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Select a date'
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                &nbsp;
                <TextField
                  className='hour-sf'
                  label='Select a time'
                  type='time'
                  value={selectedTime}
                  onChange={handleTimeChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <br></br>
              <div style={{display: 'flex', padding: '5px'}}>
                {/* <FormControl fullWidth>
                  <TextField
                    disabled
                    id='outlined-disabled'
                    label='Time Zone'
                    // defaultValue={currentTimeZone}
                    value={selectedTimeZone}
                    onChange={(e) => setSelectedTimeZone(e.target.value)}
                  />
                </FormControl> */}
                <FormControl fullWidth>
                  <p style={{fontSize: '10px'}}>Time Zone:</p>
                  <Typography>
                    {capitalizeFirstLetter(selectedTimeZone)}
                  </Typography>
                </FormControl>
              </div>
              <br></br>
              <div className='info-container'>
                <FormControl fullWidth>
                  <InputLabel id='recurrence-label'>Recurrence</InputLabel>
                  <Select
                    labelId='recurrence-label'
                    label='Recurrence'
                    value={selectedRecurrence}
                    onChange={handleRecurrenceChange}
                  >
                    {recurrenceOptions.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        onClick={
                          option === 'custom' ? handleCustomClick : undefined
                        }
                      >
                        {option.charAt(0).toUpperCase() + option.slice(1)}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <br></br>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button 
                variant='contained'
                disabled={openSnackbarToday || !isValidDate} 
                onClick={handleNextButtonClick}>
                  Next
                </Button>
              </div>
            </Box>
          </Modal>
          {/* Main Modal */}

          {/* Info Modal */}
          <Modal open={openInfo} onClose={handleCloseInfo}>
            <Box sx={modalss2}>
              <div>
                <h2>Scheduler Info</h2>
                <br></br>
                <div className='header-container'>
                  <FormControl sx={{minWidth: 300}}>
                    <InputLabel id='org-label'>Select Workspace</InputLabel>
                    {location.pathname.includes('app/dashboard') ? (
                      <Select
                        labelId='org-label'
                        label='Select Workspace'
                        value={selectedOrg}
                        onChange={handleOrgChange}
                      >
                        {filteredOrgs.length > 0 &&
                          filteredOrgs.map((org) => (
                            <MenuItem key={org._id} value={org.name}>
                              {org.name}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        labelId='org-label'
                        label='Select Workspace'
                        value={selectedOrg}
                        onChange={handleOrgChange}
                      >
                        {schedulerOrgs !== null &&
                          schedulerOrgs.map((org) => (
                            <MenuItem key={org._id} value={org.name}>
                              {org.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </FormControl>
                </div>
                &nbsp;
                <div>
                  {showFirstMenuItem && (
                    <Button variant='contained' onClick={handleAddOrgInfo}>
                      + Add Workspace
                    </Button>
                  )}
                  {showSecondMenuItem && (
                    <Button
                      variant='contained'
                      onClick={handleAddOrgInfoUpdate}
                    >
                      + Add Workspace
                    </Button>
                  )}
                  &nbsp; &nbsp;
                  {window.location.pathname.includes('app/dashboard') ? null : (
                    <Button variant='contained' onClick={addAllOrgs}>
                      Add all workspaces
                    </Button>
                  )}
                </div>
                <TableContainer
                  component={Paper}
                  sx={{marginTop: 20, maxHeight: '300px'}}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Workspace</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orgInfoList.map((orgInfo, index) => (
                        <TableRow key={index}>
                          <TableCell>{orgInfo.org}</TableCell>
                          <TableCell>
                            {showFirstMenuItem && (
                              <IconButton
                                onClick={(event) => {
                                  handleMoreClick(event, index, orgInfo);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            )}
                            {showSecondMenuItem && (
                              <IconButton
                                onClick={(event) => {
                                  handleMoreClickUpdate(event, index, orgInfo);
                                  handleOpenSettingsNew(orgInfo, index);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            )}
                            <Menu
                              anchorEl={anchorEl}
                              open={
                                Boolean(anchorEl) && selectedRowIndex === index
                              }
                              onClose={handleMenuClose}
                            >
                              {showFirstMenuItem && (
                                <ContextMenuItem
                                  onClick={() => {
                                    handleOpenSettings(orgInfo, index);
                                    handleMenuClose();
                                  }}
                                >
                                  Scan Settings
                                </ContextMenuItem>
                              )}
                              {showSecondMenuItem && (
                                <ContextMenuItem
                                  onClick={() => {
                                    handleOpenSettings(orgInfo, index);
                                    handleMenuClose();
                                  }}
                                >
                                  Scan Settings
                                </ContextMenuItem>
                              )}
                              <ContextMenuItem
                                onClick={() => {
                                  handleOpenRules();
                                  handleMenuClose();
                                }}
                              >
                                Scan Rules
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => {
                                  handleOpenIgnore();
                                  handleMenuClose();
                                }}
                              >
                                Ignore List
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => {
                                  handleOpenAdvanced();
                                  handleMenuClose();
                                }}
                              >
                                Advanced
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => {
                                  handleOpenEditName();
                                  handleMenuClose();
                                }}
                              >
                                Edit Scan name
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => {
                                  tagsModalOpen1();
                                  handleMenuClose();
                                }}
                              >
                                Tags
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => {
                                  handleOpenJira();
                                  handleMenuClose();
                                }}
                              >
                                Jira
                              </ContextMenuItem>
                              {showFirstMenuItem && (
                                <ContextMenuItem>
                                  <p
                                    onClick={() => {
                                      handleDeleteOrgInfo(index);
                                      handleMenuClose();
                                      orgIdsToSend.length = 0;
                                    }}
                                  >
                                    Delete Workspace
                                  </p>
                                </ContextMenuItem>
                              )}
                              {showSecondMenuItem && (
                                <ContextMenuItem>
                                  <p
                                    onClick={() => {
                                      handleDeleteOrgInfoUpdate(index);
                                      orgIdsToSend.length = 0;
                                    }}
                                  >
                                    Delete Workspace
                                  </p>
                                </ContextMenuItem>
                              )}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Button onClick={handleAddAllOrgsToSchedule}>Add All</Button> */}
              </div>

              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseInfo}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button variant='contained' onClick={handleOpenModal}>
                  Back
                </Button>
                &nbsp;
                {showUpdateButton && (
                  <Button
                    id='update-btn'
                    variant='contained'
                    onClick={() => {
                      handleSaveChanges();
                      // handleCloseInfo();
                    }}
                  >
                    Update
                  </Button>
                )}
                {showDoneButton && (
                  <Button
                    id='create-schedule'
                    variant='contained'
                    onClick={() => {
                      sendScheduleReq();

                      // handleCloseInfo();
                    }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
          {/* Info Modal */}

          {/* Settings Modal */}
          <Modal open={openSettings} onClose={handleCloseSettings}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: 500,
                borderRadius: 5,
                padding: 7,
              }}
            >
              <Card>
                <CardContent>
                  <div className={'headerSettings'}>
                    <SettingsOutlinedIcon />
                    <h3>Edit Scan Types</h3>
                  </div>
                  <Grid container spacing={2}>
                    {/* Static Code */}
                    <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Static Code Analysis (SAST)</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={staticCode}
                              onChange={() => setStaticCode(!staticCode)}
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid>

                    {/* Runtime Testing */}
                    <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Run Time Testing (IAST)</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={runtimeTesting}
                              onChange={() =>
                                setRuntimeTesting(!runtimeTesting)
                              }
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid>

                    {/* Software Composition Analysis */}
                    <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Software Composition Analysis (SCA)</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={softwareCompositionAnalysis}
                              onChange={() =>
                                setSoftwareCompositionAnalysis(
                                  !softwareCompositionAnalysis,
                                )
                              }
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid>

                    {/* Configuration Testing */}
                    <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Configuration Testing</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={configurationTesting}
                              onChange={() =>
                                setConfigurationTesting(!configurationTesting)
                              }
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid>

                    {/* Third Party Packages */}
                    <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Third Party Packages</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={thirdPartyPackages}
                              onChange={() =>
                                setThirdPartyPackages(!thirdPartyPackages)
                              }
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid>

                    {/* Auto Scanning */}
                    {/* <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Auto Scanning</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={autoScanning}
                              onChange={() => setAutoScanning(!autoScanning)}
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid> */}

                    {/* Send Results to All Users */}
                    {/* <Grid item xs={6}>
                      <div className={'scanTypeContainer'}>
                        <div className={'scanIconName'}>
                          <div className={'settingIcon'}>
                            <SettingsOutlinedIcon color='primary' />
                          </div>
                          <div className={'scanTypeName'}>
                            <p>Send Results to All Users</p>
                          </div>
                        </div>
                        &nbsp;
                        <FormControlLabel
                          control={
                            <Switch
                              checked={sendResultsToAllUsers}
                              onChange={() =>
                                setSendResultsToAllUsers(!sendResultsToAllUsers)
                              }
                              color='primary'
                            />
                          }
                        />
                      </div>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseSettings}
                >
                  Cancel
                </Button>
                &nbsp;
                {showFirstMenuItem && (
                  <Button variant='contained' onClick={handleSaveSettings}>
                    Done
                  </Button>
                )}
                {showSecondMenuItem && (
                  <Button
                    variant='contained'
                    onClick={handleSaveSettingsUpdate}
                  >
                    Done
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
          {/* Settings Modal */}

          {/* Scan Rules Modal */}
          <Modal open={openRules} onClose={handleCloseRules}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '50%',
                height: '500px',
                overflowY: 'auto',
              }}
            >
              <div>
                <div className={'scanRulesHeader'}>
                  <SecurityOutlinedIcon />
                  <h3>Edit Scan Ruleset</h3>
                </div>
                <div>
                  {selectedOrgData &&
                    selectedOrgData.scanrules &&
                    selectedOrgData.scanrules
                      .slice(0, visibleRecords)
                      .map((rule, index) => (
                        <div className='singleScanRuleContainer' key={index}>
                          <div className='rulesetInfo'>
                            <div className='rulesetIcon'>
                              <FilterDramaIcon color='primary' />
                            </div>
                            <div>
                              <p>{rule.vulnid}</p>
                              <p className='rulesetSubheading'>{rule.title}</p>
                            </div>
                          </div>
                          <div className='severity'>
                            <FormControl
                              sx={{m: 1, minWidth: 120}}
                              size='small'
                            >
                              <Select
                                id={`severity-select-${index}`}
                                value={rule.severity}
                                onChange={(e) =>
                                  handleSeverityChange(index, e.target.value)
                                }
                                inputProps={{'aria-label': 'Without label'}}
                              >
                                <MenuItem value='critical'>Critical</MenuItem>
                                <MenuItem value='high'>High</MenuItem>
                                <MenuItem value='medium'>Medium</MenuItem>
                                <MenuItem value='low'>Low</MenuItem>
                                <MenuItem value='info'>Info</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className='rulesetSwitch'>
                            <Switch
                              checked={rule.active}
                              onChange={() => handleSwitchChange(index)}
                            />
                          </div>
                        </div>
                      ))}
                </div>
                {selectedOrgData &&
                  selectedOrgData.scanrules &&
                  selectedOrgData.scanrules.length > visibleRecords && (
                    <Box className={'scanRulesHeader'} sx={{m: 2}}>
                      <Button
                        variant='contained'
                        onClick={() => setVisibleRecords(visibleRecords + 10)}
                      >
                        Show more
                      </Button>
                    </Box>
                  )}
              </div>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseRules}
                >
                  Cancel
                </Button>
                &nbsp;
                {showFirstMenuItem && (
                  <Button variant='contained' onClick={handleAddScanRules}>
                    Done
                  </Button>
                )}
                {showSecondMenuItem && (
                  <Button
                    variant='contained'
                    onClick={handleAddScanRulesUpdate}
                  >
                    Done
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
          {/* Scan Rules Modal */}

          {/* Ignore List Modal */}
          <Modal open={openIgnore} onClose={handleCloseIgnore}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '50%',
                minHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <div>
                <div>
                  <div className={'customRulesHeader'}>
                    <WebhookOutlinedIcon />
                    <h3>Custom Rules</h3>
                  </div>
                  <h4 className={'subHeader'}>
                    These files will be ignored from scans
                  </h4>
                  <div className={'addButton'}>
                    <Button variant='contained' onClick={handleOpen}>
                      ADD
                    </Button>
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Filename</TableCell>
                          <TableCell>Reason</TableCell>
                          <TableCell>Added By</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showNoRulesMessage && (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <h2 className='heading'>
                                There are currently no rules present.
                              </h2>
                            </TableCell>
                          </TableRow>
                        )}
                        {ignorelist.map((rule, index) => (
                          <TableRow key={index}>
                            <TableCell>{rule.filename}</TableCell>
                            <TableCell>{rule.reason}</TableCell>
                            <TableCell>{rule.addedBy}</TableCell>
                            <TableCell>{rule.date}</TableCell>
                            <TableCell>
                              <Button onClick={() => handleDeleteRule(index)}>
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <Modal open={isModalOpen} onClose={handleClose}>
                  <Box sx={styleCustomRules}>
                    <div className={'heading'}>
                      <InfoRoundedIcon color='primary' sx={{fontSize: 40}} />
                      <h2>Filenames to ignore</h2>
                    </div>
                    <div className={'subHeading'}>
                      <p>
                        Please enter full name including extention of the file
                        for example: ignoreController.cls
                      </p>
                    </div>
                    <div className={'formContainer'}>
                      <div className={'inputContainer'}>
                        <InputLabel className={'labelInput'}>
                          File Name
                        </InputLabel>
                        <TextField
                          label='File Name'
                          value={fileName}
                          onChange={(e) => setFileName(e.target.value)}
                          margin='normal'
                          variant='filled'
                        />
                      </div>
                      <div className={'inputContainer'}>
                        <InputLabel className={'labelInput'}>Reason</InputLabel>
                        <TextField
                          label='Reason'
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          margin='normal'
                          variant='filled'
                        />
                      </div>
                    </div>
                    <div className={'actionButtons'}>
                      <Button
                        onClick={handleAddRule}
                        variant='contained'
                        color='primary'
                      >
                        Save
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant='contained'
                        color='secondary'
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseIgnore}
                >
                  Cancel
                </Button>
                &nbsp;
                {showFirstMenuItem && (
                  <Button variant='contained' onClick={handleDone}>
                    Done
                  </Button>
                )}
                {showSecondMenuItem && (
                  <Button variant='contained' onClick={handleDoneUpdate}>
                    Done
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
          {/* Ignore List Modal */}

          {/* Advanced Modal */}
          <Modal open={openAdvanced} onClose={handleCloseAdvanced}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
              }}
            >
              <Card>
                <CardContent>
                  <div className={'advanceHeader'}>
                    <SettingsOutlinedIcon />
                    <h3>Advanced</h3>
                  </div>
                  <Grid item xs={6}>
                    <div className={'singleScanRuleContainer2'}>
                      <div className={'rulesetIcon2'}>
                        <SettingsOutlinedIcon color='primary' />
                        <div className={'rulesetIcon2div'}>
                          <p>Code Quality</p>
                          <p className={'rulesetSubheading'}>by PMD</p>
                        </div>
                      </div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={codeQuality}
                            onChange={() => setCodeQuality(!codeQuality)}
                            color='primary'
                          />
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={'singleScanRuleContainer'}>
                      <div className={'rulesetIcon'}>
                        <SettingsOutlinedIcon color='primary' />
                      </div>
                      <div>
                        <p>Track Findings per Workspace</p>
                        <p className={'rulesetSubheading'}>
                          Create and track findings per workspace
                        </p>
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <FormControlLabel
                        control={
                          <Switch
                            checked={trackFindingPerOrg}
                            onChange={() =>
                              setTrackFindingPerOrg(!trackFindingPerOrg)
                            }
                            color='primary'
                          />
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={'singleScanRuleContainer'}>
                      <div className={'rulesetIcon'}>
                        <SettingsOutlinedIcon color='primary' />
                      </div>
                      <div>
                        <p>New Issues Scan</p>
                        <p className={'rulesetSubheading'}>
                          Scan will default to displaying new issues only
                        </p>
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showNewFindings}
                            onChange={() =>
                              setShowNewFindings(!showNewFindings)
                            }
                            color='primary'
                          />
                        }
                      />
                    </div>
                  </Grid>
                </CardContent>
              </Card>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseAdvanced}
                >
                  Cancel
                </Button>
                &nbsp;
                {showFirstMenuItem && (
                  <Button variant='contained' onClick={handleSaveSettings}>
                    Done
                  </Button>
                )}
                {showSecondMenuItem && (
                  <Button
                    variant='contained'
                    onClick={handleSaveSettingsUpdate}
                  >
                    Done
                  </Button>
                )}
              </div>
            </Box>
          </Modal>
          {/* Advanced Modal */}

          {/* Tags Modal */}
          <Modal open={tagsModal1} onClose={tagsModalClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '50%',
                minHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <div>
                <div className={'headerTag'}>
                  <StyleIcon />
                  <Typography variant='h2'>Add Tag</Typography>
                </div>
                <div className={'addBtnContainer'}>
                  <Button
                    className={'closeBtn'}
                    variant='contained'
                    color='secondary'
                    onClick={tagsModalClose}
                  >
                    Close
                  </Button>
                  <Button
                    className={'closeBtn'}
                    variant='contained'
                    color='primary'
                    onClick={handleAddTag}
                  >
                    Add Tag
                  </Button>
                </div>
                &nbsp;
                <TableContainer component={Paper}>
                  <Table
                    sx={{minWidth: 650}}
                    aria-label='simple table'
                    stickyHeader={true}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Remove</TableCell>
                        <TableCell align='left'>Add Link</TableCell>
                        <TableCell align='left'>Tag</TableCell>
                        <TableCell align='left'>Value</TableCell>
                        <TableCell align='left'>URL</TableCell>
                      </TableRow>
                    </TableHead>
                    {showFirstMenuItem && (
                      <TableBody>
                        {tags.map((tag, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Button
                                className={'removeTag'}
                                onClick={() => removeTag(index)}
                                size='small'
                                variant='contained'
                                color='secondary'
                              >
                                Remove
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                name='addLink'
                                checked={tag.addLink}
                                onChange={(e) => handleTagDataChange(e, index)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                label='Tag'
                                name='fieldName'
                                value={tag.fieldName}
                                onChange={(e) => handleTagDataChange(e, index)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                label='Value'
                                name='fieldValue'
                                value={tag.fieldValue}
                                onChange={(e) => handleTagDataChange(e, index)}
                              />
                            </TableCell>
                            <TableCell>
                              {tag.addLink ? (
                                <TextField
                                  label='URL'
                                  name='href'
                                  value={tag.href}
                                  onChange={(e) =>
                                    handleTagDataChange(e, index)
                                  }
                                  required={tag.addLink}
                                />
                              ) : (
                                <p>No URL</p>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                    {showSecondMenuItem && (
                      <TableBody>
                        {tags.map((tag, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Button
                                className={'removeTag'}
                                onClick={() => removeTag(index)}
                                size='small'
                                variant='contained'
                                color='secondary'
                              >
                                Remove
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                name='addLink'
                                checked={tag.addLink}
                                onChange={(e) =>
                                  handleTagDataChangeUpdate(e, index)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                label='Tag'
                                name='fieldName'
                                value={tag.fieldName}
                                onChange={(e) =>
                                  handleTagDataChangeUpdate(e, index)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                label='Value'
                                name='fieldValue'
                                value={tag.fieldValue}
                                onChange={(e) =>
                                  handleTagDataChangeUpdate(e, index)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {tag.addLink ? (
                                <TextField
                                  label='URL'
                                  name='href'
                                  value={tag.href}
                                  onChange={(e) =>
                                    handleTagDataChangeUpdate(e, index)
                                  }
                                  required={tag.addLink}
                                />
                              ) : (
                                <p>No URL</p>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className={'submitBtnContainer'}>
                  {showFirstMenuItem && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSaveTags}
                    >
                      Submit
                    </Button>
                  )}
                  {showSecondMenuItem && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSaveTagsUpdate}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Modal>
          {/* Tags Modal */}

          {/* Custom Recurrence Modal */}
          <Modal open={openRecurrence} onClose={handleCloseRecurrenceModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: 500,
                minHeight: 500,
                borderRadius: 5,
                padding: 7,
              }}
            >
              <div>
                <h1>Custom recurrence</h1>
                &nbsp;
                <div className='first-container'>
                  <p>Repeat every</p>
                  <TextField
                    size='medium'
                    type='number'
                    value={valueRepeat}
                    onInput={handleInputChange}
                    style={{display: 'block', margin: '0 auto'}}
                    inputProps={{min: 0}}
                  />
                  &nbsp;
                  <FormControl fullWidth>
                    <Select
                      value={selectedRepeatOption}
                      onChange={(e) => setSelectedRepeatOption(e.target.value)}
                    >
                      {repeatOptions.map((repeat) => (
                        <MenuItem key={repeat} value={repeat}>
                          {sValidation(valueRepeat, repeat)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <br></br>
                {/* <div className='days-container'>
              <p>Repeat on</p>
              &nbsp;
              <div className='weekdays-container'>
                {weekday.map((day, index) => (
                  <div
                    key={index}
                    className={`day-week ${
                      day === currentDay ? 'selected' : ''
                    }`}
                    onClick={() => toggleDayWeek(day)}
                  >
                    {day.substring(0, 2)}{' '}
                  </div>
                ))}
              </div>
            </div> */}
                <br></br>
                <div>
                  <FormControl>
                    <p>Ends On</p>
                    <div className='radio-btn-container'>
                      <div>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          value={selectedOption}
                          onChange={handleOptionChange}
                          name='radio-buttons-group'
                        >
                          <FormControlLabel
                            value='never'
                            control={<Radio />}
                            label='Never'
                          />
                          <FormControlLabel
                            value='date'
                            control={<Radio />}
                            label='On'
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              id='date'
                              value={customEndDate}
                              onChange={(date) => {
                                const currentDate = new Date();
                                currentDate.setHours(0, 0, 0, 0);

                                const selectedDateTime = new Date(date);
                                selectedDateTime.setHours(0, 0, 0, 0);

                                if (
                                  !isEditing &&
                                  selectedDateTime < currentDate
                                ) {
                                  setSnackbarMessageToday(
                                    "You can't select a previous date",
                                  );
                                  setOpenSnackbarToday(true);
                                  setDateValid(false);
                                } else {
                                  setOpenSnackbarToday(false);
                                  setDateValid(true);

                                  if (
                                    !isEditing &&
                                    selectedDateTime.getTime() ===
                                      currentDate.getTime()
                                  ) {
                                    const tomorrow = new Date(currentDate);
                                    tomorrow.setDate(currentDate.getDate() + 2);
                                    const formattedDate = formatDate(tomorrow);
                                    setSnackbarMessageToday(
                                      `The earliest you can schedule these scan(s) is for ${formattedDate}`,
                                    );
                                    setOpenSnackbarToday(true);
                                    setDateValid(false);
                                  }
                                }

                                setCustomEndDate(date);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={!isDatePickerEnabled}
                            />
                          </LocalizationProvider>
                          &nbsp;
                          <TextField
                            style={{display: 'none'}}
                            className='hour-sf'
                            label='Select a time'
                            type='time'
                            value={selectedEndTime}
                            onChange={handleEndTimeChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <FormControlLabel
                            value='after'
                            control={<Radio />}
                            label='After'
                          />
                        </RadioGroup>
                        <TextField
                          fullWidth
                          size='medium'
                          type='number'
                          value={valueRepeat2}
                          onChange={handleTextFieldChange}
                          style={{display: 'block', margin: '0 auto'}}
                          disabled={!isTextFieldEnabled}
                          inputProps={{min: 0}}
                          label='Occurrences'
                        />
                      </div>
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseRecurrenceModal}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button variant='contained' onClick={handleCloseRecurrence}>
                  Done
                </Button>
              </div>
            </Box>
          </Modal>
          {/* Custom Recurrence Modal */}

          {/* Orgs Modal */}
          <Modal
            open={openOrgs}
            onClose={handleCloseOrgs}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
          >
            <Box sx={modalss3}>
              <div>
                <h2>Workspaces</h2>
                <TableContainer component={Paper} sx={{maxHeight: '300px'}}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Queue Order</TableCell>
                        <TableCell>Workspace</TableCell>
                        <TableCell>Scan Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Tags</TableCell>
                        <TableCell>Scan Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderQueueOrgInfoList.map((orgData, index) => (
                        <TableRow key={index}>
                          <TableCell>{orgData.data.queueorder}</TableCell>
                          <TableCell>{orgData.org}</TableCell>
                          <TableCell>{orgData.data.name}</TableCell>
                          <TableCell>
                            <div>
                              {orgData.data.status === 'scheduled' && (
                                <Tooltip title='Scheduled'>
                                  <EventAvailableIcon
                                    style={{
                                      color: '#0a8fdc',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {orgData.data.status === 'queued' && (
                                <Tooltip title='Queued'>
                                  <HourglassBottomIcon
                                    style={{
                                      color: '#ff75e0',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {orgData.data.status === 'running' && (
                                <Tooltip title='Running'>
                                  <PlayCircleFilledWhiteIcon
                                    style={{
                                      color: '#dcd500',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {orgData.data.status === 'completed' && (
                                <>
                                  <Tooltip title='Completed'>
                                    <CheckCircleIcon
                                      style={{
                                        color: '#00b769',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                  &nbsp;
                                  <Tooltip title='View Results'>
                                    <LinkIcon
                                      onClick={() => {
                                        if (
                                          orgData.data.scanIds &&
                                          Array.isArray(orgData.data.scanIds) &&
                                          orgData.data.scanIds.length > 0
                                        ) {
                                          const lastScanId =
                                            orgData.data.scanIds[
                                              orgData.data.scanIds.length - 1
                                            ];
                                          window.location.href = `/app/dashboard/${orgData.data.orgId}?reportId=${lastScanId}`;
                                        } else {
                                          console.error();
                                        }
                                      }}
                                      style={{
                                        color: '#0a8fdc',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )}

                              <div>
                                {orgData.data.status === 'error' && (
                                  <div style={{display: 'inline-block'}}>
                                    <Tooltip title='Error'>
                                      <ErrorIcon
                                        style={{
                                          color: '#fc0c07',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                                {orgData.data.status === 'error' && (
                                  <div style={{display: 'inline-block'}}>
                                    {/* <Tooltip title='View Logs'>
                                      <LinkIcon
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          const lastScanId =
                                            orgData.data.scanIds[
                                              orgData.data.scanIds.length - 1
                                            ];
                                          navigate(
                                            `/setup?tab=logging&object=scheduler&objectId=${lastScanId}`,
                                          );
                                        }}
                                      />
                                    </Tooltip> */}
                                    <Tooltip title='View Logs'>
                                      <LinkIcon
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          if (schedulerId) {
                                            navigate(
                                              `/setup?tab=logging&object=scheduler&objectId=${schedulerId}`,
                                            );
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                              </div>

                              {orgData.data.status === 'removed' && (
                                <Tooltip title='Removed from queue'>
                                  <EventBusyIcon
                                    style={{
                                      color: '#fc0c07',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          <TableCell sx={{width: 'auto'}}>
                            <div
                              style={{
                                display: 'flex',
                                gap: '5px',
                                flexWrap: 'wrap',
                              }}
                            >
                              {orgData.data.tags.map((tag, tagIndex) => (
                                <div key={tagIndex}>
                                  <Chip
                                    label={
                                      tag.href ? (
                                        <a
                                          href={tag.href}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          style={{
                                            textDecoration: 'underline',
                                            color: 'white',
                                          }}
                                        >
                                          {`${tag.fieldName} : ${tag.fieldValue}`}
                                        </a>
                                      ) : (
                                        `${tag.fieldName} : ${tag.fieldValue}`
                                      )
                                    }
                                    // onDelete={() => handleTagRemove(tagIndex)}
                                    deleteIcon={
                                      <CancelIcon
                                        fontSize='small'
                                        style={{
                                          backgroundColor: '#0a8fdc',
                                          color: 'white',
                                        }}
                                      />
                                    }
                                    sx={{
                                      backgroundColor: '#0a8fdc',
                                      marginRight: '8px',
                                      color: 'white',
                                    }}
                                  />
                                  {confirmDelete &&
                                    tagIndexToDelete === tagIndex && (
                                      <div
                                        style={{
                                          border: '1px solid #e0c5c5',
                                          backgroundColor: 'white',
                                          position: 'absolute',
                                          zIndex: 999,
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          borderRadius: '5px',
                                          padding: '7px',
                                          width: '550px',
                                          minHeight: '230px',
                                          overflowY: 'auto',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <DeleteIcon
                                          sx={deleteIcon}
                                          color='secondary'
                                        />
                                        <h2>
                                          Do you want to delete this tag?{' '}
                                        </h2>
                                        <p>
                                          Deleted tags will no longer be
                                          accessible
                                        </p>
                                        &nbsp;
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            marginTop: 16,
                                          }}
                                        >
                                          <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() =>
                                              setConfirmDelete(false)
                                            }
                                            style={{marginLeft: 8}}
                                          >
                                            Cancel
                                          </Button>
                                          &nbsp;
                                          <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => {
                                              confirmDeletion();
                                              handleSaveTags();
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ))}
                            </div>
                          </TableCell>
                          <TableCell>
                            {Object.keys(orgData.data.scansettings)
                              .filter((key) => {
                                return (
                                  orgData.data.scansettings[key] === true &&
                                  !(
                                    key === 'auto_scanning' ||
                                    key === 'send_results_to_all_users'
                                  )
                                );
                              })
                              .map((type, index) => {
                                const labelMappings = {
                                  static_code: {
                                    label: 'SAST',
                                    style: {
                                      backgroundColor: '#ff0000',
                                      color: '#ffffff',
                                    },
                                  },
                                  software_composition_analysis: {
                                    label: 'SCA',
                                    style: {
                                      backgroundColor: '#0c8fdc',
                                      color: '#ffffff',
                                    },
                                  },
                                  configuration_testing: {
                                    label: 'CONFIG',
                                    style: {
                                      backgroundColor: '#fece0e',
                                      color: '#000000',
                                    },
                                  },
                                  code_quality: {
                                    label: 'QUALITY',
                                    style: {
                                      backgroundColor: '#8993f6',
                                      color: '#ffffff',
                                    },
                                  },
                                  third_party_packages: {
                                    label: 'THIRD PARTY',
                                    style: {
                                      backgroundColor: '#ef81e3',
                                      color: '#ffffff',
                                    },
                                  },
                                  runtime_testing: {
                                    label: 'IAST',
                                    style: {
                                      backgroundColor: '#92e092e1',
                                      color: '#000000',
                                    },
                                  },
                                  show_new_findings: {
                                    label: 'NEW ISSUES',
                                    style: {
                                      backgroundColor: '#FFA500',
                                      color: '#ffffff',
                                    },
                                  },
                                };

                                const {label, style} = labelMappings[type] || {
                                  label: type,
                                  style: {},
                                };

                                return (
                                  <Chip
                                    size='small'
                                    key={index}
                                    label={label}
                                    style={{marginRight: '5px', ...style}}
                                  />
                                );
                              })}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className='bottom-btn'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleCloseOrgs}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                    // handleSaveChanges();
                    handleCloseOrgs();
                  }}
                  variant='contained'
                >
                  Done
                </Button>
              </div>
            </Box>
          </Modal>
          {/* Orgs Modal */}

          {/*Jira Modal*/}
          <Modal open={openJira} onClose={handleJiraClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '50%',
                minHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <div className={'mainContainer'}>
                <h2>Enter Jira Details </h2>
                <div className={'inputContainerJira'}>
                  <InputLabel className={'labelInputJira'}>
                    Jira Domain
                  </InputLabel>
                  <TextField
                    placeholder='Jira Domain'
                    variant='outlined'
                    className={'inputFieldJira'}
                    value={jiraUsername}
                    onChange={(e) => setJiraUserName(e.target.value)}
                  ></TextField>
                </div>
                <div className={'inputContainerJira'}>
                  <InputLabel className={'labelInputJira'}>
                    Jira Email
                  </InputLabel>
                  <TextField
                    placeholder='Jira Email'
                    variant='outlined'
                    className={'inputFieldJira'}
                    value={jiraEmail}
                    onChange={(e) => setJiraEmail(e.target.value)}
                    required
                    type='email'
                  ></TextField>
                </div>
                <div className={'inputContainerJira'}>
                  <InputLabel className={'labelInputJira'}>
                    API Token
                  </InputLabel>
                  <TextField
                    placeholder='API Token'
                    variant='outlined'
                    className={'inputFieldJira'}
                    value={apiToken}
                    onChange={(e) => setApiToken(e.target.value)}
                    type='password'
                    required
                  ></TextField>
                </div>
                <div className={'inputContainerJira'}>
                  <InputLabel className={'labelInputJira'}>
                    Project Title
                  </InputLabel>
                  <TextField
                    placeholder='Project Title'
                    variant='outlined'
                    className={'inputFieldJira'}
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                    required
                  ></TextField>
                </div>
                <div className={'inputContainerJira'}>
                  <InputLabel className={'labelInputJira'}>
                    Project Key
                  </InputLabel>
                  <TextField
                    placeholder='Project Key'
                    variant='outlined'
                    className={'inputFieldJira'}
                    value={projectKey}
                    onChange={(e) => setProjectKey(e.target.value)}
                    required
                  ></TextField>
                </div>

                <div className={'btnContainer'}>
                  {showFirstMenuItem && (
                    <Button
                      onClick={handleSaveJira}
                      type='submit'
                      variant='contained'
                    >
                      Verify and Save
                    </Button>
                  )}
                  {showSecondMenuItem && (
                    <Button
                      onClick={handleSaveJiraUpdate}
                      type='submit'
                      variant='contained'
                    >
                      Verify and Save
                    </Button>
                  )}
                  <Button
                    onClick={handleJiraClose}
                    variant='contained'
                    color='secondary'
                  >
                    Cancel
                  </Button>
                </div>

                <div className={'helpManual'}>
                  <h4>Help</h4>
                  <p>
                    Please refer to the following JIRA documentation if you are
                    having trouble finding the required information.
                  </p>
                  <div className={'helpLinks'}>
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href='https://support.atlassian.com/jira-core-cloud/docs/edit-a-projects-details/'
                      className={'singleHelpLink'}
                    >
                      How to find Project title &amp; Project key
                    </a>
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href='https://confluence.atlassian.com/cloud/api-tokens-938839638.html'
                      className={'singleHelpLink'}
                    >
                      How to find API token
                    </a>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {/*Jira Modal*/}

          {/*Edit Modal*/}
          <Modal open={openEditName} onClose={handleEditNameClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '800px',
                minHeight: '300px',
                overflowY: 'auto',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                }}
              >
                <EditIcon sx={editIcon} color='secondary' />
                <h2>Enter a desired name for this scan? </h2>
                <p>Your scan will show up with this name in the future.</p>
                &nbsp;
                <TextField
                  fullWidth
                  label='Scan Name'
                  placeholder='Scan Name'
                  value={scanName}
                  onChange={(e) => setScanName(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    textAlign: 'center',
                    marginTop: 16,
                  }}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleEditNameClose}
                    style={{marginRight: 8}}
                  >
                    Cancel
                  </Button>
                  {showFirstMenuItem && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSaveName}
                    >
                      Save
                    </Button>
                  )}
                  {showSecondMenuItem && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSaveNameUpdate}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Modal>
          {/*Edit Modal*/}

          {/* Delete Modal*/}
          <Modal open={deleteModal} onClose={deleteModalClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '800px',
                minHeight: '300px',
                overflowY: 'auto',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 600,
                }}
              >
                <DeleteIcon sx={deleteIcon} color='secondary' />
                <h2>Do you want to delete this Scheduler Scan? </h2>
                <p>Deleted Schedulers are no longer accessible</p>
                &nbsp;
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: 16,
                  }}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={deleteModalClose}
                    style={{marginLeft: 8}}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          {/* Delete Modal Queue*/}
          <Modal open={deleteModalQueue} onClose={deleteModalCloseQueue}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
                padding: 7,
                width: '800px',
                minHeight: '300px',
                overflowY: 'auto',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 600,
                }}
              >
                <DeleteIcon sx={deleteIcon} color='secondary' />
                <h2>Do you want to delete this Scheduler Scan from Queue? </h2>
                <p>Deleted schedules will no longer run for today</p>
                &nbsp;
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: 16,
                  }}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={deleteModalCloseQueue}
                    style={{marginLeft: 8}}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleDeleteClickQueue}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          {/* Success add org*/}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              severity='success'
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* Error message for org add*/}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleSnackbarCloseOrgError}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              onClose={handleSnackbarCloseOrgError}
              severity='error'
            >
              {snackbarMessageOrg}
            </MuiAlert>
          </Snackbar>
          {/* Error for link */}
          <Snackbar
            open={snackbarOpenTags}
            autoHideDuration={3000}
            onClose={closeSnackbarTags}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              severity='error'
              onClose={closeSnackbarTags}
            >
              Url is required
            </MuiAlert>
          </Snackbar>
          {/* Error for org id added */}
          <Snackbar
            open={snackbarOpenId}
            autoHideDuration={8000}
            onClose={handleCloseSnackbarId}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              onClose={handleCloseSnackbarId}
              elevation={6}
              variant='filled'
              severity='error'
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          {/* Error for org id added modal */}
          <Modal
            open={errorSnackbarOpen}
            onClose={closeErrorSnackbar}
            className='custom-modal'
          >
            <Paper
              style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                backgroundColor: '#d32f2f',
                padding: '12px 22px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
              }}
            >
              <div className='header-modal'>
                <ErrorOutlineIcon
                  style={{color: 'white', marginRight: '8px'}}
                />
                <Typography variant='body1' style={{color: 'white'}}>
                  The following organizations are already added to a schedule:
                  <IconButton color='inherit' onClick={closeErrorSnackbar}>
                    <CloseIcon style={{color: 'white'}} />
                  </IconButton>
                </Typography>
              </div>
              <div className='content-modal-id'>
                {modalContent.split('\n').map((item, index) => (
                  <div key={index}>- {item}</div>
                ))}
              </div>
            </Paper>
          </Modal>
          {/* Error for weekday */}
          <Snackbar
            open={openSnackbarWeekday}
            autoHideDuration={3000}
            onClose={handleSnackbarCloseWeekday}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              severity='error'
              onClose={handleSnackbarCloseWeekday}
            >
              You must select a valid weekday
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={openSnackbarDeleted}
            autoHideDuration={6000}
            onClose={handleCloseSnackbarDeleted}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              onClose={handleCloseSnackbarDeleted}
              severity='success'
            >
              Scan Scheduler Successfully Deleted
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={openSnackbarCreated}
            autoHideDuration={6000}
            onClose={handleCloseSnackbarCreated}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              onClose={handleCloseSnackbarCreated}
              severity='success'
            >
              Scan Scheduler Successfully Created
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={openSnackbarUpdated}
            autoHideDuration={6000}
            onClose={handleCloseSnackbarUpdated}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              onClose={handleCloseSnackbarUpdated}
              severity='success'
            >
              Scan Scheduler Successfully Updated
            </MuiAlert>
          </Snackbar>
          {/* Error for today day */}
          <Snackbar
            open={openSnackbarToday}
            autoHideDuration={3000}
            onClose={handleSnackbarCloseToday}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              severity='error'
              onClose={handleSnackbarCloseToday}
            >
              {snackbarMessageToday}
            </MuiAlert>
          </Snackbar>
          {/* Error for empty settings */}
          <Snackbar
            open={openSnackbarSettings}
            autoHideDuration={3000}
            onClose={handleSnackbarCloseSettings}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiAlert
              elevation={6}
              variant='filled'
              severity='error'
              onClose={handleSnackbarCloseSettings}
            >
              {snackbarMessageSettings}
            </MuiAlert>
          </Snackbar>
        </div>
      )}
      {!permissions && (
        <PlaceHolderDiv
          status='permissiondenied'
          message={`You don't have permission to view Scan Scheduler`}
        />
      )}
    </>
  );
};

export default Scheduler;

Scheduler.propTypes = {
//   comments: PropTypes.array,
//   _id: PropTypes.string,
//   detailsTabChange: PropTypes.func,
  writeable: PropTypes.bool,
};
