import React, {Fragment, useState, useEffect} from 'react';
import RestoreSharpIcon from '@mui/icons-material/RestoreSharp';
import styles from './HeaderDetails.module.css';
import {Button} from '@mui/material';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import ChevronLeftSharpIcon from '@mui/icons-material/ChevronLeftSharp';
import PropTypes from 'prop-types';
import {createSearchParams} from 'react-router-dom';
import {useParams, useNavigate} from 'react-router-dom';
import APPI from 'assets/images/Report/APPI.svg';
import GDPR from 'assets/images/Report/GDPR.svg';
import HIPAA from 'assets/images/Report/HIPAA.svg';
import PCI from 'assets/images/Report/PCI.svg';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import ISO from 'assets/images/Report/ISO 27001.svg';
import SOX from 'assets/images/Report/SOX.svg';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

function HeaderDetails({
  issue,
  filename,
  _id,
  nextIssue,
  previousIssue,
  nextIssueBtnState,
  previousIssueBtnState,
  line,
  complianceTypes,
  complianceViolations,
  issueId,
  scanDate,
  scanName,
}) {
  const formattedDate = new Date(scanDate).toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });

  let {org_id, report_id} = useParams();
  const navigate = useNavigate();

  const [showCompliance, setShowCompliance] = useState({
    show: false,
    data: [],
    link: '',
  });
  const complianceModalClose = () => {
    setShowCompliance({show: false, data: [], link: ''});
  };
  const complianceModalOpen = (str) => {
    let violations = complianceViolations.map((violation) => {
      if (violation.split(':')[1].trim() === str) {
        let compliance = violation.split(':')[1].trim();
        let complianceDetail = violation.split(':')[2].trim();
        let newEntry = compliance + ' : ' + complianceDetail;
        return newEntry;
      }
    });

    let link = '';
    switch (str) {
      case 'APPI':
        link = 'https://www.ppc.go.jp/en/legal/';
        break;
      case 'GDPR':
        link = 'https://gdpr.eu/';
        break;
      case 'HIPAA':
        link = 'https://www.hhs.gov/hipaa/for-professionals/index.html';
        break;
      case 'ISO 27001':
        link = 'https://www.iso.org/isoiec-27001-information-security.html';
        break;
      case 'PCI Compliance':
        link = 'https://www.pcisecuritystandards.org/';
        break;
      case 'SOX':
        link = 'https://www.sec.gov/spotlight/sarbanes-oxley.htm';
        break;
      default:
        link = '';
        break;
    }

    setShowCompliance({show: true, data: violations, link: link});
  };

  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        'Are you sure you want to reload the page? Applied filters will be lost.';
      event.returnValue = message;
    };

    const handleLoad = () => {
      const hasRefreshed = localStorage.getItem('hasRefreshed');

      if (hasRefreshed && location.pathname.includes('/app/details')) {
        localStorage.removeItem('hasRefreshed');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  
  const navigateToIssue = () => {
    navigate({
      pathname: `/dashboard/${org_id}`,
      search: `?${createSearchParams({
        tab: 'issues',
        reportId: report_id,
      })}`,
    });
  };

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    const checkURL = () => {
      const url = window.location.href;
      if (url.includes('isFindingsNew')) {
        setIsEnabled(true);
      } else {
        setIsEnabled(false);
      }
    };

    checkURL();
    window.addEventListener('popstate', checkURL);
    return () => window.removeEventListener('popstate', checkURL);
  }, []);

  return (
    <Fragment>
      <Button
        variant='contained'
        disabled={!isEnabled}
        onClick={() => navigateToIssue()}
        startIcon={<ChevronLeftSharpIcon />}
        className={styles.backDashboardBtn}
      >
        Issues
      </Button>

      <div className={styles.title}>
        {filename && filename !== 'NA' && filename !== '' ? (
          line && line !== 0 ? (
            <h3>
              {issue} in file {filename} on line: {line}
            </h3>
          ) : (
            <h3>
              {issue} in file {filename}
            </h3>
          )
        ) : (
          <h3>{issue}</h3>
        )}
      </div>
      <div className={styles.subTitleContainer}>
        <div className={styles.subTitle}>
          <a className={styles.anchorStyle} href='#history'>
            <RestoreSharpIcon /> <p></p>
          </a>
          <p> Issue Id: {issueId ? issueId : _id}</p>
          <br></br>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p>{scanName || 'Scanned'}</p>
            <p style={{marginLeft: '5px'}}>@</p>
            <p style={{marginLeft: '5px'}}>{formattedDate}</p>
          </div>
        </div>
        <div className={styles.btnActionContainer}>
          <div className={styles.complianceImgContainer}>
            {complianceTypes.indexOf('APPI') != -1 && (
              <img
                className={styles.complianceImg}
                src={APPI}
                alt='APPI'
                onClick={() => complianceModalOpen('APPI')}
              />
            )}
            {complianceTypes.indexOf('GDPR') != -1 && (
              <img
                className={styles.complianceImg}
                src={GDPR}
                alt='GDPR'
                onClick={() => complianceModalOpen('GDPR')}
              />
            )}
            {complianceTypes.indexOf('HIPAA') != -1 && (
              <img
                className={styles.complianceImg}
                src={HIPAA}
                alt='HIPPA'
                onClick={() => complianceModalOpen('HIPAA')}
              />
            )}
            {complianceTypes.indexOf('PCI') != -1 && (
              <img
                className={styles.complianceImg}
                src={PCI}
                alt='PCI'
                onClick={() => complianceModalOpen('PCI Compliance')}
              />
            )}
            {complianceTypes.indexOf('ISO 27001') != -1 && (
              <img
                className={styles.complianceImg}
                src={ISO}
                alt='ISO'
                onClick={() => complianceModalOpen('ISO 27001')}
              />
            )}
            {complianceTypes.indexOf('SOX') != -1 && (
              <img
                className={styles.complianceImg}
                src={SOX}
                alt='SOX'
                onClick={() => complianceModalOpen('SOX')}
              />
            )}
          </div>
          {previousIssueBtnState && (
            <Button
              disabled={!isEnabled}
              className={styles.btn}
              variant='contained'
              onClick={previousIssue}
            >
              <ChevronLeftSharpIcon />
            </Button>
          )}

          {nextIssueBtnState && (
            <Button
              disabled={!isEnabled}
              className={styles.btn}
              variant='contained'
              onClick={nextIssue}
            >
              <ChevronRightSharpIcon />
            </Button>
          )}
        </div>
      </div>

      {showCompliance.show && (
        <ModalContainer
          open={showCompliance.show}
          handleModalClose={complianceModalClose}
          closeButtonText='Close'
        >
          <div className={styles.complianceModal}>
            <div className={styles.cpModalHeading}>
              <ReportProblemIcon color='secondary' sx={{fontSize: 40}} />
              <h2> Compliance Violations</h2>
            </div>
            <p>Following compliance violations have been found:</p>
            <ol className={styles.orderlist}>
              {showCompliance?.data.map((compliance, index) => {
                if (compliance) {
                  return <li key={index}>{compliance}</li>;
                }
              })}
            </ol>
            <p>
              Please click <a href={showCompliance?.link}> here </a> to learn
              more
            </p>
          </div>
        </ModalContainer>
      )}
    </Fragment>
  );
}

export default HeaderDetails;

HeaderDetails.propTypes = {
  issue: PropTypes.string,
  filename: PropTypes.string,
  _id: PropTypes.string,
  nextIssue: PropTypes.func,
  previousIssue: PropTypes.func,
  nextIssueBtnState: PropTypes.bool,
  previousIssueBtnState: PropTypes.bool,
  line: PropTypes.number,
  complianceTypes: PropTypes.array,
  complianceViolations: PropTypes.array,
  issueId: PropTypes.number,
  scanDate: PropTypes.string,
  scanName: PropTypes.string,
};