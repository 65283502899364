import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  DETAILS_RESPONSE,
  GET_ISSUE_DETAIL,
  GET_ISSUES_CUR_PAGE,
  SHOW_MESSAGE,
  GET_USER_NOTIFICATIONS,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {getIssuesCleanRequest} from './Dashboard';
import {onRefreshReports} from './Reports';
import {onGetIssuesFilters} from 'redux/actions';
//Refresh Details

export const onRefreshDetails = ({
  report_id,
  issuesCurPage = 1,
  org_id,
  pageSize,
  curIssuesFilterState
}) => {
  return (dispatch) => {
    dispatch(
      getIssuesCleanRequest({
        _id: report_id,
        page: issuesCurPage,
        orgId: org_id,
        pageSize: pageSize,
        ...curIssuesFilterState
      }),
    );
    // dispatch(
    //   onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id}),
    // );
  };
};

// Update Issue Detail
export const onUpdateIssueDetail = ({
  report_id,
  obj,
  issuesCurPage,
  _id,
  issue_id,
  org_id,
  org_name,
  pageSize,
  curIssuesFilterState,
}) => {
  return (dispatch) => {
    obj.org_id = org_id;
    obj.org_name = org_name;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/findings/${_id}`, obj)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'success',
              message: 'Issue Updated Successfully!',
            },
          });
          dispatch(onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id, page: issuesCurPage}));
          dispatch(
            onRefreshDetails({report_id, issuesCurPage, issue_id, org_id, pageSize, curIssuesFilterState}),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Update Failed! Please try again',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Update Failed! Please try again',
          },
        });
      });
  };
};

export const onUpdateIssueDetailComment = ({
  report_id,
  obj,
  issuesCurPage,
  _id,
  issue_id,
  org_id,
  org_name,
  pageSize,
  curIssuesFilterState,
}) => {
  return (dispatch) => {
    obj.org_id = org_id;
    obj.org_name = org_name;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/findings/${_id}`, obj)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'success',
              message: 'Comment Added and Status Updated',
            },
          });
          dispatch(onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id, page: issuesCurPage}));
          dispatch(
            onRefreshDetails({report_id, issuesCurPage, issue_id, org_id, pageSize, curIssuesFilterState}),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'fail',
              message: 'Update Failed! Please try again',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {
            status: 'fail',
            message: 'Update Failed! Please try again',
          },
        });
      });
  };
};

export const onPutCommentDetail = ({
  _id,
  obj,
  issue_id,
  issuesCurPage,
  report_id,
  org_id,
  org_name,
  pageSize,
  curIssuesFilterState
}) => {
  return (dispatch) => {
    obj.org_id = org_id;
    obj.org_name = org_name;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/findings/${_id}`, obj)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {
              status: 'success',
              message: 'Comment Added Successfully!',
            },
          });
          dispatch(
            onRefreshDetails({report_id, issuesCurPage, issue_id, org_id, pageSize, curIssuesFilterState}),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'fail', message: 'Failed! Please try again'},
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {status: 'fail', message: 'Failed! Please try again'},
        });
      });
  };
};

export const onPutBulkFinding = ({
  obj,
  orgId,
  report_id,
  page,
  pageSize,
  filterData,
  issuesSearch,
  isFindingsNew,
  originator
}) => {
  return (dispatch) => {
    obj.org_id = orgId;
    obj.type = 'ui';
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/bulkfindings`, obj)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Bulk update request accepted!'});

          setTimeout(function(){
            dispatch(getUserNotifications({originator: originator}));
              dispatch(onGetIssuesFilters({_id: report_id, orgId: orgId}));
              dispatch(
                getIssuesCleanRequest({
                  ...issuesSearch,
                  _id: report_id,
                  orgId: orgId,
                  filterData: filterData,
                  page: page,
                  pageSize: pageSize,
                  isDownload: false,
                  isFindingsNew: isFindingsNew,
                }),
              );
          }, 10000);



        } else if (data.status === 400) {
          dispatch({type: FETCH_ERROR});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
        else if (data.status === 401) {
          dispatch({type: FETCH_ERROR});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {status: 'fail', message: 'Failed! Please try again'},
        });
      });
  };
};

export const updateNotifications = (payload) => {
  return (dispatch) => {
    jwtAxios
      .put('/notification/update', payload)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch(getUserNotifications());
        } else if (data.status === 400) {
          dispatch({type: FETCH_ERROR});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
        else if (data.status === 401) {
          dispatch({type: FETCH_ERROR});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const getUserNotifications = (payload) => {
  return (dispatch) => {
    jwtAxios
      .post('/notification/get', payload )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_USER_NOTIFICATIONS, payload: data.data});
      }else{
        dispatch({
          type: FETCH_ERROR,
          payload: <IntlMessages id='message.somethingWentWrong' />,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const onPutTagDetail = ({
  _id,
  tagsPayload,
  report_id,
  issue_id,
  issuesCurPage,
  org_id,
  org_name,
  pageSize,
  curIssuesFilterState
}) => {
  return (dispatch) => {
    tagsPayload.org_id = org_id;
    tagsPayload.org_name = org_name;

    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/findings/${_id}`, tagsPayload)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'success', message: 'Tags Added Successfully!'},
          });
          dispatch(onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id, page: issuesCurPage}));

          dispatch(
            onRefreshDetails({report_id, issuesCurPage, issue_id, org_id, pageSize, curIssuesFilterState}),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'fail', message: 'Failed! Please try again!'},
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {status: 'fail', message: 'Failed! Please try again!'},
        });
      });
  };
};

export const onDeleteTag = ({
  _id,
  tag,
  report_id,
  issue_id,
  issuesCurPage,
  org_id,
  org_name,
  pageSize,
  curIssuesFilterState
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    const fieldToDelete = {
      fieldName: tag.fieldName,
      fieldValue: tag.fieldValue,
    };

    jwtAxios
      .put(`/findings/${_id}`, {
        fieldToDelete,
        _scanID: report_id,
        org_id,
        org_name,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'success', message: 'Tag deleted Successfully!'},
          });
          if(tag){
            dispatch(onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id, page: issuesCurPage}));
          }
          dispatch(
            onRefreshDetails({report_id, issuesCurPage, issue_id, org_id, pageSize, curIssuesFilterState}),
          );
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'fail', message: 'Failed! Please try again!'},
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {status: 'fail', message: 'Failed! Please try again!'},
        });
      });
  };
};

export const onPutTagDetailScan = ({
  tagsPayload,
  scanId,
  reportsCurPage,
  org_id,
}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`/scan/${scanId}?orgId=${org_id}`, tagsPayload)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Tag(s) added successfully!'});
          dispatch(onRefreshReports({org_id, reportsCurPage}));
        } else if (data.status === 201) {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DETAILS_RESPONSE,
            payload: {status: 'fail', message: 'Failed! Please try again!'},
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DETAILS_RESPONSE,
          payload: {status: 'fail', message: 'Failed! Please try again!'},
        });
      });
  };
};
// Security Gate PUT

export const onGetSpecificIssue = ({scanId, issueId, orgId, page}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/scan/${scanId}/specificissue/${issueId}?orgId=${orgId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (data.data.success) {
            dispatch({type: GET_ISSUE_DETAIL, payload: data.data});
          }
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: GET_ISSUES_CUR_PAGE,
            payload: page,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const onDeleteScanFinding = ({findingId, scanId, org_id}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(`/scan/${scanId}/findings/${findingId}?orgId=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
