import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import styles from './AddSpecificOrgUser.module.css';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import {Button, Snackbar} from '@mui/material';
import {onPostAddSpecificUser, onPostEditUserSfdc} from 'redux/actions';
import {TEAM_RESPONSE} from 'shared/constants/ActionTypes';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {assignRole} from 'redux/actions/Roles';

const AddSpecificOrgUser = ({addModalClose, orgName, org_id}) => {
  const dispatch = useDispatch();
  const getAllRoles = useSelector(({roles}) => roles.getAllRoles);

  let orgRoles = getAllRoles?.filter((orgRole) => orgRole.type === 'ORG_LEVEL');
  const [userForm, setUserForm] = useState({
    isAdmin: false,
    email: '',
    name: '',
  });
  const [selectedOrgRole, setSelectedOrgRole] = useState('');
  const users = useSelector(({common}) => common.users);
  const specificOrgUsers = useSelector(({team}) => team.specificOrgUsers);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const submitAddUser = async (event) => {
    event.preventDefault();
    const role = userForm.isAdmin == true ? 'GLOBAL_ADMIN' : 'user';

    if (!userForm.email || !selectedOrgRole) {
      setOpenSnackbar(true);
      return;
    }
  
    const existingUserOrg = await specificOrgUsers?.find(
      (user) =>
        user.email.toLocaleLowerCase().trim() ==
        userForm.email.toLocaleLowerCase().trim(),
    );
    if (existingUserOrg != undefined) {
      addModalClose();
      dispatch({
        type: TEAM_RESPONSE,
        payload: {
          status: 'fail',
          message: 'User Already Present',
        },
      });
    } else {
      const foundUser = await users.find(
        (user) => user.email == userForm.email,
      );
      if (foundUser) {
        let orgAssigned = [];
        await foundUser.salesforceOrgs?.map((singleOrg) => {
          singleOrg.access == true &&
            orgAssigned.push(`${singleOrg.sfdc}/*/*/${singleOrg.sfdcId}`);
        });
        orgAssigned.push(orgName);
        dispatch(
          onPostEditUserSfdc({
            userId: foundUser._id,
            orgName: orgAssigned,
            org_id: org_id,
          })
        ).then(() => {
          if (selectedOrgRole) {
            dispatch(
              assignRole({
                method: 'assign',
                orgs: [{ sfdcId: org_id, roleId: selectedOrgRole._id }],
                flag: 'ORG_LEVEL',
                userId: foundUser._id,
                orgId: org_id,
              }),
            );
          }
        }).catch((error) => {
          console.error("Error to assign role", error);
        });
      } else {
        dispatch(
          onPostAddSpecificUser({
            email: userForm.email,
            is_sfdc_oauth: false,
            permission: ['WRITE_ALL', 'READ_ALL'],
            role: role,
            orgName: orgName,
            org_id: org_id,
            roleId: selectedOrgRole._id,
          }),
        );
        // if (selectedOrgRole) {
        //   dispatch(
        //     assignRole({
        //       method: 'assign',
        //       orgs: [{sfdcId: org_id, roleId: selectedOrgRole._id}],
        //       flag: 'ORG_LEVEL',
        //       userId: foundUser._id,
        //     }),
        //   );
        // }
      }
      addModalClose();
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onChangeForm = (event) => {
    event.target.name == 'isAdmin'
      ? setUserForm((cur) => {
          return {...cur, [event.target.name]: event.target.checked};
        })
      : setUserForm((cur) => {
          return {...cur, [event.target.name]: event.target.value};
        });
  };

  const changeOrgRole = (event) => {
    setSelectedOrgRole(event.target.value);
  };
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.addUserTitle}>
          <PersonAddAltSharpIcon color='primary' sx={{fontSize: 30}} />
          <h2>Add Team Member</h2>
        </div>
        <form onSubmit={submitAddUser}>
          <div className={styles.userEmail}>
            <h4 className={styles.textLabel}>Users</h4>
            <FormControl sx={{width: 230}}>
              <InputLabel id='email'>Users</InputLabel>
              <Select
                id='email'
                inputProps={{'aria-label': 'Without label'}}
                name='email'
                label='email'
                value={userForm?.email || ''}
                onChange={onChangeForm}
              >
                {users?.map(
                  (user) =>
                    !specificOrgUsers.find(
                      (item) => item.email === user.email,
                    ) && (
                      <MenuItem value={user?.email} key={user?._id}>
                        {user?.email}
                      </MenuItem>
                    ),
                )}
              </Select>
            </FormControl>
          </div>
          <div className={styles.userEmail}>
            <h4 className={styles.textLabel}>Assign Role</h4>
            <FormControl sx={{width: 230}}>
              <InputLabel id='role'>Role</InputLabel>
              <Select
                id='role'
                inputProps={{'aria-label': 'Without label'}}
                name='role'
                label='role'
                value={selectedOrgRole || ''}
                onChange={(event) => changeOrgRole(event)}
              >
                {orgRoles?.map((list) => (
                  <MenuItem value={list} key={list?._id}>
                    {list?.name?.replaceAll('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.saveButton}>
            <Button
              variant='contained'
              color='secondary'
              onClick={addModalClose}
            >
              Close
            </Button>
            &nbsp;
            <Button variant='contained' type='submit'>
              Save
            </Button>
          </div>
        </form>
      </div>

      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
        message='Please select Users and Assign Role fields to proceed'
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        style={{
          position: 'fixed',
          bottom: -315,
          right: -510,
          zIndex: 9999,
        }}
        ContentProps={{
          style: {
            backgroundColor: '#FF0000',
          },
        }}
      />
    </Fragment>
  );
};

AddSpecificOrgUser.propTypes = {
  addModalClose: PropTypes.func,
  orgName: PropTypes.string,
  org_id: PropTypes.string,
};

export default AddSpecificOrgUser;
