import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../details/CommentSection/Add Tags/TagForm/TagForm.module.css';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';

const PermissionForm = ({
  index,
  removePermission,
  permission,
  access,
  onPermissionChange,
  permissionList,
  disableRelatedSelects,
  disableRelatedSelectsEdit,
  editMode,
  // selectAll,
}) => {
  const obj = {
    sfdcinfo: 'SFDC Orgs',
    sfccinfo: 'SFCC Orgs',
    user: 'Users',
    config: 'Configuration',
    role: 'Roles',
    permission: 'Permission',
    subscription: 'Subscription',
    scan: 'Scans',
    securitygate: 'Security Gate',
    repo: 'Integrations',
    reports: 'Reports',
    // advanced: 'Advanced',
    settings: 'Settings',
    company: 'Company',
    team: 'Team',
    globalscansettings: 'Environment Scan Settings',
    globalsecuritygate: 'Environment Security Gate',
    globalintegrations: 'Environment Integrations',
    logs: 'Logs',
    orgsecuritygate: 'Workspace Security Gate',
    orgscanrules: 'Workspace Scan Rules',
    orgignorelist: 'Workspace Ignorelist',
    orgintegrations: 'Workspace Integrations',
    orgscansettings: 'Workspace Scan Settings',
    scheduler: 'Environment Scheduler',
    orgscheduler: 'Workspace Scheduler',
    finding: 'Issues',
    findingSeverity: 'Issues > Severity',
    findingStatus: 'Issues > Status',
    findingTag: 'Issues > Tags',
    findingComment: 'Issues > Comments',
    findingAssignment: 'Issues > Assigned',
  };

  const relatedPermissions = [
    'findingSeverity',
    'findingStatus',
    'findingTag',
    'findingComment',
    'findingAssignment',
  ];

  return (
    <TableRow>
      <TableCell>
      {permission !== 'findingStatus' && permission !== 'findingSeverity' && permission !== 'findingAssignment'  && permission !== 'findingTag' && permission !== 'findingComment'?
        <FormControl fullWidth>
          <InputLabel id='Permission'>Select</InputLabel>
          <Select
            disabled={
              relatedPermissions.includes(permission)
            }
            labelId='Permission'
            id='permission'
            label='permission'
            name='permission'
            defaultValue={permission}
            onChange={(event) => onPermissionChange(event, index, permission)}
          >
            {permissionList?.map((item) => (
              (item !== 'findingStatus' && item !== 'findingSeverity' && item !== 'findingAssignment'  && item !== 'findingTag' && item !== 'findingComment' && (
              <MenuItem value={item} key={item}>
                {obj[item] || item}
              </MenuItem>
              )
              )
            ))}
          </Select>
        </FormControl>:
        <TextField 
        fullWidth
        id='child_objects'
        value={obj[permission]}
        disabled={true}
        ></TextField> }
      </TableCell>
      <TableCell>
        <FormControl fullWidth>
          <InputLabel id='access'>Select</InputLabel>
          <Select
            disabled={
              (editMode ? disableRelatedSelectsEdit : disableRelatedSelects) &&
              relatedPermissions.includes(permission)
            }
            labelId='access'
            id='access'
            label='access'
            name='access'
            defaultValue={access}
            onChange={(event) => onPermissionChange(event, index, permission)}
          >
            <MenuItem value='READ'>Read</MenuItem>
            <MenuItem value='READ, WRITE'>Write</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        {!relatedPermissions.includes(permission) && (
          <Button
            className={styles.removeTag}
            onClick={() => removePermission(index, permission)}
            size='small'
            variant='contained'
            color='secondary'
          >
            Remove
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

PermissionForm.propTypes = {
  permission: PropTypes.string,
  access: PropTypes.string,
  onPermissionChange: PropTypes.func,
  index: PropTypes.number,
  removePermission: PropTypes.func,
  permissionList: PropTypes.array,
  disableRelatedSelects: PropTypes.bool,
  disableRelatedSelectsEdit: PropTypes.bool,
  editMode: PropTypes.bool,
};

export default PermissionForm;
