import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  IS_API_IN_PROCESS,
  GET_REPORTS_DATA,
  GET_REPORTS_PAGE_COUNT,
  POST_SCAN_DATA,
  POST_START_SCAN_DATA,
  GET_SCAN_STATS,
  GET_FINDINGS_DATA,
  POST_DOWNLOAD_REPORTS,
  GET_REPORTS_CUR_PAGE,
  DASHBOARD_RESPONSE,
  LAST_SCAN_STATUS,
  SHOW_MESSAGE,
  LAST_COMPLETED_SCAN,
} from 'shared/constants/ActionTypes';
import IntlMessages from '@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { onPostTrendingScansPerOrg } from './ScanStatistics';

// LAST SCAN

export const onGetLastScan = ({org_id}) => {
  return (dispatch) => {
    // jwtAxios
    //   .post('scan/list/status', {org_id})

    jwtAxios
      .get(`scan/list/status/get?org_id=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: LAST_SCAN_STATUS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

// last Successful Scan

export const onGetLastCompletedScan = ({org_id, status = 'completed'}) => {
  return (dispatch) => {
    // jwtAxios
    //   .post('scan/list/status', {org_id, status})
    jwtAxios
      .get(`scan/list/status/get?org_id=${org_id}&status=${status}`)
      .then((data) => {
        if (data.status === 200) {
          if (data.data) {
            dispatch({type: LAST_COMPLETED_SCAN, payload: data.data});
          } else {
            dispatch({
              type: LAST_COMPLETED_SCAN,
              payload: {status: 'inComplete'},
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

// Get Reports

export const onGetReportsData = ({org_id, page = 1}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // jwtAxios
    //   .post(`/scan/list?page=${page}`, {org_id: org_id})
    jwtAxios
    .get(`/scan/list/get?page=${page}&org_id=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_REPORTS_DATA,
            payload: data.data.scan,
          });
          dispatch({
            type: GET_REPORTS_PAGE_COUNT,
            payload: Math.ceil(data.data.total / 10),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const onPostScan = ({org_id, file}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(`/scan`, {org_id: org_id})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_SCAN_DATA,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.scanid) {
            dispatch(
              onPostStart({
                orgId: org_id,
                scanId: data.data.scanid,
                file: file,
              }),
            );
          } else {
            dispatch({
              type: DASHBOARD_RESPONSE,
              payload: {
                status: 'fail',
                message: `We couldn't initiate your scan! Please try again later`,
              },
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: `We couldn't initiate your scan! Please try again later`,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: `Network Error! Please try again later`,
          },
        });
      });
  };
};

export const onPostStart = ({orgId, scanId, file}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let body = {
      orgId: orgId,
      scanId: scanId,
    };
    let options = '';
    if (file) {
      options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      body = new FormData();
      body.append('code', file);
      body.append('orgId', orgId);
      body.append('scanId', scanId);
    }
    jwtAxios
      .post(`/scan/start`, body, options)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_START_SCAN_DATA,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.success) {
            dispatch(onGetReportsData({org_id: orgId, page: 1}));
            dispatch({
              type: SHOW_MESSAGE,
              payload:
                'Scan Started Successfully! You will receive an email once the scan completes.',
            });
            dispatch(onGetLastScan({org_id: orgId}));
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: `We couldn't initiate your scan! Please try again later`,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onPostStartScan = ({org_id, file}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let body = {
      org_id,
      source: 'digitsecWeb'
    };
    let options = '';
    if (file) {
      options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      body = new FormData();
      body.append('code', file);
      body.append('orgId', org_id);
    }
    jwtAxios
      .post(`/scan/create_start`, body, options)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_SCAN_DATA,
            payload: data.data,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          if (data.data.success) {
            dispatch(onGetReportsData({org_id, page: 1}));
            dispatch({
              type: SHOW_MESSAGE,
              payload:
                'Scan Started Successfully! You will receive an email once the scan completes.',
            });
            dispatch(onGetLastScan({org_id}));
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: `We couldn't initiate your scan! Please try again later`,
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: DASHBOARD_RESPONSE,
            payload: {
              status: 'fail',
              message: `We couldn't initiate your scan! Please try again later`,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch({type: IS_API_IN_PROCESS, payload: false});
        dispatch({
          type: DASHBOARD_RESPONSE,
          payload: {
            status: 'fail',
            message: `Network Error! Please try again later`,
          },
        });
      });
  };
};

function download(content, fileName, contentType) {
  var a = document.createElement('a');
  var file = '';
  if (contentType === 'json') {
    file = new Blob([JSON.stringify(content)], {type: contentType});
  } else {
    file = new Blob([content], {type: contentType});
  }
  a.href = URL.createObjectURL(file);
  a.download = fileName + '.' + contentType;
  a.click();
}

export const onGetJsonData = ({_id, orgId, show_new_findings}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let url = `scan/${_id}/finding/download/?orgId=${orgId}`;
    if(show_new_findings){
      url += `&downloadNew=${true}`;
    }
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          download(data.data, 'DigitSec_Findings', 'json');
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetExcelData = ({_id, reportType, fields, orgId, show_new_findings}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let url = `scan/${_id}/finding/downloadCSV?orgId=${orgId}`;
    if(show_new_findings){
      url+= `&downloadNew=${true}`;
    }
    jwtAxios
      .post(url, {fields, reportType})
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          download(data.data, 'DigitSec_Findings', 'csv');
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onDeleteReport = ({_id, org_id, page = 1}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(`scan/${_id}?orgId=${org_id}`)
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Scan Deleted Successfully!'});
          dispatch(onRefreshReports({org_id, page}));
        } else if (data.status === 201) {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onDeleteTagsReport = ({_id, org_id, page = 1, fieldToDelete}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    let body = {};
    body.fieldToDelete = fieldToDelete;
    jwtAxios
      .put(`scan/${_id}?orgId=${org_id}`, body)
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: SHOW_MESSAGE, payload: 'Tag deleted successfully!'});
          dispatch(onRefreshReports({org_id, page}));
        } else if (data.status == 201) {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onEditReport = ({_id, org_id, page = 1, scanName}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .put(`scan/${_id}?orgId=${org_id}`, {name: scanName})
      .then((data) => {
        if (data.status == 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Scan name updated successfully!',
          });
          dispatch(onRefreshReports({org_id, page}));
        } else if (data.status == 201) {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetScanStatsStat = ({_id, orgId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let url = `/dashboard/stats_scan/${_id}?orgId=${orgId}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_SCAN_STATS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const onGetScanStats = ({_id, orgId, downloadNew}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let url = `/dashboard/stats_scan/${_id}?orgId=${orgId}&newFindings=${downloadNew}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_SCAN_STATS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
      });
  };
};

export const onGetFindingsData = ({_id, orgId/*, downloadNew*/}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
      let url = `/scan/${_id}/findings?orgId=${orgId}`;//&newFindings=${downloadNew}`;
      jwtAxios
      .get(url)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_FINDINGS_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

export const onGetComplianceFindingsData = ({_id, violations,orgId}) => {
  return (dispatch) => {
    let compliance = violations;
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`/scan/${_id}/compliancefindings?compliance=${compliance}&orgId=${orgId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: IS_API_IN_PROCESS, payload: false});
          dispatch({type: GET_FINDINGS_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Downloadable Reports

export const onPostDownloadableReports = ({org_id, page = 1}) => {
  return (dispatch) => {
    dispatch({type: IS_API_IN_PROCESS, payload: true});
    dispatch({type: FETCH_START});
    // jwtAxios
    //   .post(`scan/downloadscans?page=${page}`, {org_id: org_id})
    jwtAxios
    .get(`scan/downloadscans/get?page=${page}&org_id=${org_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_DOWNLOAD_REPORTS,
            payload: {
              data: data.data?.scan ? data.data.scan : [],
              pageCount: Math.ceil(data.data.total ? data.data.total / 10 : 0),
            },
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
          dispatch({type: IS_API_IN_PROCESS, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.response?.data?.message || error.message });
        dispatch({type: IS_API_IN_PROCESS, payload: false});
      });
  };
};

// Refresh Reports

export const onRefreshReports = ({org_id, page = 1}) => {
  return (dispatch) => {
    dispatch(onGetReportsData({org_id, page}));
    dispatch(onPostDownloadableReports({org_id, page}));
    dispatch({type: GET_REPORTS_CUR_PAGE, payload: page});
    dispatch(onGetLastCompletedScan({org_id}));
    dispatch(onPostTrendingScansPerOrg({org_id}));
  };
};
