import React, {useState, useEffect} from 'react';
import ModalContainer from '../../../modals/ModalContainer/ModalContainer';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import styles from '../../../details/CommentSection/Add Tags/AddTags.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import StyleIcon from '@mui/icons-material/Style';
import PermissionForm from './PermissionForm/PermissionForm';
import {createNewRole} from 'redux/actions/Roles';
import {useDispatch} from 'react-redux';
import {onGetUsers} from 'redux/actions';
import Checkbox from '@mui/material/Checkbox';
import {GlobalPermissions} from 'shared/constants/AppConst';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';
import {updateRole} from 'redux/actions/Roles';
import {getAllRoles} from 'redux/actions/Roles';
import {getAllPermissions} from 'redux/actions/Roles';


const AddNewRoles = ({
  rolesModal,
  rolesModalClose,
  editMode,
  rolesName,
  rolesType,
  title,
  roleId,
  currentPermissionsList,
}) => {
  const dispatch = useDispatch();
  const [allRoles, setAllRoles] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [roleType, setRoleType] = useState(null);
  const [permissionList, setPermisssionList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const orgLevelPermission = [
    'scan',
    'reports',
    'team',
    'orgsecuritygate',
    'orgintegrations',
    'orgscansettings',
    'orgscanrules',
    'orgignorelist',
    'orgscheduler',
    'finding',
    'findingSeverity',
    'findingStatus',
    'findingTag',
    'findingComment',
    'findingAssignment',
  ];

  useEffect(() => {
    setRoleName(rolesName);
  }, [rolesName]);


  useEffect(() => {
    return () => {
      setAllRoles([]);
    };
  }, []);

  const addTagHandlerEdit = () => {
    let updatedAllRoles = [...localPermissionsList];
    updatedAllRoles.unshift({
        permission: '',
        access: 'READ',
    });
    setLocalPermissionsList(updatedAllRoles);
  };

  const addTagHandler = () => {
    let updatedAllRoles = [...allRoles];
    updatedAllRoles.unshift({
        permission: '',
        access: 'READ',
    });
    setAllRoles(updatedAllRoles);
  };

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
  }, []);

  const changeRoleType = (value) => {
    value === 'ORG_LEVEL'
      ? setPermisssionList(orgLevelPermission)
      : setPermisssionList(GlobalPermissions);
    setRoleType(value);
    setSelectAll(false);
    setAllRoles([]);
  };

  const onPermissionChange = (event, index) => {
    if(allRoles.filter((elem)=>elem.permission === event.target.value).length === 0){
    setAllRoles((cur) => {
      let updateTag = [...cur];
      updateTag[index][event.target.name] = event.target.value;
      return [...updateTag];
    });

    if(event.target.value === 'finding') {
      addIssuesField();
    }else if(!allRoles.filter(elem=>elem.permission==='finding').length){
      removeIssuesField();
    }
  }else{
    dispatch({
      type: FETCH_ERROR,
      payload:
        'This object is already added!',
    });
    removePermission(index, event.target.value, true);
  }
};

const onPermissionChangeEdit = (event, index) => {

  if(localPermissionsList.filter((elem)=>elem.permission === event.target.value).length === 0){

  const updatedList = [...localPermissionsList];
  updatedList[index][event.target.name] = event.target.value;
  setLocalPermissionsList(updatedList);

  if(event.target.value === 'finding') {
    addIssuesFieldEdit();
  }else if(!localPermissionsList.filter(elem=>elem.permission==='finding').length){
    removeIssuesFieldEdit();
  }
}else{
  dispatch({
    type: FETCH_ERROR,
    payload:
      'This object is already added!',
  });
  removePermissionEdit(index, event.target.value, true);
}
};


  const removePermission = (index, permission, fromOnChange) => {
    setAllRoles((cur) => {
      let updateTag = [...cur];
      let findingUpdatedArray = [];
      if(permission !== 'finding' || (permission === 'finding' && fromOnChange)){
        updateTag.splice(index, 1);
        return updateTag;
      }else {
        updateTag.map((elem)=>{
          if(elem.permission !== '' && (elem.permission !== 'finding' && elem.permission !== 'findingStatus' && elem.permission !== 'findingSeverity' && elem.permission !== 'findingAssignment' && elem.permission !== 'findingComment' && elem.permission !== 'findingTag')){
           findingUpdatedArray.push(elem);
          }
        });
        return findingUpdatedArray;
      }
    });
  };

  const removePermissionEdit = (index, permission, fromOnChange) => {
    setLocalPermissionsList((cur) => {
      let updatedList = [...cur];
      let findingUpdatedArray = [];
      if(permission !== 'finding' || (permission === 'finding' && fromOnChange)){
        updatedList.splice(index, 1);
        return updatedList;
      }else{

        updatedList.map((elem)=>{
          if(elem.permission !== '' && elem.permission !== 'finding' && elem.permission !== 'findingStatus' && elem.permission !== 'findingSeverity' && elem.permission !== 'findingAssignment' && elem.permission !== 'findingComment' && elem.permission !== 'findingTag'){
           findingUpdatedArray.push(elem);
          }
        });
        return findingUpdatedArray;
      }
    });
  };

  const oobroles = [
    'Admin',
    'Environment Manager',
    'Workspace Manager',
    'Environment User',
    'Workspace User',
    'Environment User Read Only',
    'Workspace User Read Only',
  ];

  const onSubmitTag = (event) => {
    event.preventDefault();
    const trimTags = allRoles.map((item) => {
      let permissionArray = [];
      if (item.access === 'READ, WRITE') {
        permissionArray.push('READ', 'WRITE');
      } else {
        permissionArray.push(item.access);
      }
      return {
        object: item.permission,
        permissions: permissionArray,
      };
    });
    let obj = {
      name: roleName,
      flag: roleType,

      globalRoles: [
        {
          type: roleType,
          name: roleName,
        },
      ],
      globalPermissions: {
        name: roleName,
        permissions: trimTags,
      },
    };

    const isValidFindingPermissions = () => {
      const finding = trimTags.find((tag) => tag.object === 'finding');

      if (!finding) return true;

      const dependentFields = [
        'findingSeverity',
        'findingStatus',
        'findingTag',
        'findingComment',
        'findingAssignment',
      ];

      if (
        finding.permissions.includes('READ') &&
        finding.permissions.length === 1
      ) {
        for (let field of dependentFields) {
          const fieldTag = trimTags.find((tag) => tag.object === field);
          if (
            fieldTag &&
            !(
              fieldTag.permissions.length === 1 &&
              fieldTag.permissions[0] === 'READ'
            )
          ) {
            return false;
          }
        }
      }

      if (
        finding.permissions.includes('READ') &&
        finding.permissions.includes('WRITE')
      ) {
        for (let field of dependentFields) {
          const fieldTag = trimTags.find((tag) => tag.object === field);
          if (
            fieldTag &&
            !(
              fieldTag.permissions.includes('READ') ||
              fieldTag.permissions.includes('WRITE')
            )
          ) {
            return false;
          }
        }
      }

      return true;
    };

    if (!isValidFindingPermissions()) {
      dispatch({
        type: FETCH_ERROR,
        payload:
          'Issues sub fields cannot be set to WRITE if Issues is set to READ',
      });
      return;
    }

    const hasPermissions = trimTags.some((tag) => tag.permissions.length > 0);

    if (!hasPermissions) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select at least one permission',
      });
      return;
    }

    const fieldTag = trimTags.find((tag) => tag.object);

    if (!fieldTag || !fieldTag.object) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select at least one permission',
      });
      return;
    }

    if(!roleName){
      dispatch({
        type: FETCH_ERROR,
        payload: 'Role name is required!',
      });
      return;
    }

    const lowerCaseRoleName = roleName.toLowerCase();

    const lowerCaseOob = oobroles.map((role) => role.toLowerCase());

    if (lowerCaseOob.includes(lowerCaseRoleName)) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Custom roles cannot have the same names as out of the box roles',
      });
      return;
    }

    if(!roleType){
      dispatch({
        type: FETCH_ERROR,
        payload: 'Role type is required!',
      });
      return;
    }
    if (roleType === 'ORG_LEVEL') {
      obj['orgLevelRoles'] = obj['globalRoles'];
      obj['orgLevelPermissions'] = obj['globalPermissions'];
      delete obj.globalRoles;
      delete obj.globalPermissions;
    }
    if (allRoles.length > 0 && !validateOrgRole(allRoles)) {
      dispatch({
        type: FETCH_ERROR,
        payload:
          'Please select both Permission and Access level from the dropdowns!',
      });
      return;
    }
    dispatch(createNewRole(obj));
    dispatch(onGetUsers());
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
    rolesModalClose();
    setAllRoles([]);
    setRoleName('');
    setSelectAll(false);
  };

  const validateOrgRole = (allRolesArr) => {
    return (
      allRolesArr.length ===
      allRolesArr.filter((cur) => cur.permission && cur.access).length
    );
  }; 
  const closeAndRemoveTag = () => {
    setAllRoles([]);
    rolesModalClose();
    setSelectAll(false);
  };

  const selectAllHandler = () => {
    if (selectAll) {
      setSelectAll(false);
      return;
    }
    setSelectAll(true);
    let permissionAll = [];
    if (roleType == 'ORG_LEVEL') {
      permissionAll = orgLevelPermission;
    } else {
      permissionAll = GlobalPermissions;
    }
    let selectPermission = permissionAll?.map((item) => {
      return {
        permission: item,
        access: 'READ',
      };
    });
    setAllRoles(selectPermission);
  };

  useEffect(() => {
    if (rolesType === 'GLOBAL') {
      setPermisssionList(GlobalPermissions);
    } else if (rolesType === 'ORG_LEVEL') {
      setPermisssionList(orgLevelPermission);
    }
  }, [rolesType]);

  const [localPermissionsList, setLocalPermissionsList] = useState(
    currentPermissionsList,
  );



  useEffect(() => {
    setLocalPermissionsList(currentPermissionsList);
  }, [currentPermissionsList]);

  const onSubmitTagEdit = (event) => {
    event.preventDefault();

    const trimTagsEdit = localPermissionsList.map((item) => {
      let permissionArray = [];
      let permissions = [];
      if (item.access === 'READ, WRITE') {
        permissionArray.push('READ', 'WRITE');
        permissions.push(item.access.split(',')[0]);
        permissions.push(item.access.split(',')[1].trim());
      } else {
        permissionArray.push(item.access);
        permissions.push(item.access);
      }
      return {
        object: item.permission,
        permissions: permissions,
      };
    });

    let obj = {
      name: roleName,
      flag: rolesType,
      roleId: roleId,

      globalRoles: [
        {
          type: rolesType,
          name: roleName,
        },
      ],
      globalPermissions: {
        name: roleName,
        permissions: trimTagsEdit,
      },
    };

    const isValidFindingPermissionsEdit = () => {
      const finding = trimTagsEdit.find((tag) => tag.object === 'finding');

      if (!finding) return true;

      const dependentFields = [
        'findingSeverity',
        'findingStatus',
        'findingTag',
        'findingComment',
        'findingAssignment',
      ];

      if (
        finding.permissions.includes('READ') &&
        finding.permissions.length === 1
      ) {
        for (let field of dependentFields) {
          const fieldTag = trimTagsEdit.find((tag) => tag.object === field);
          if (
            fieldTag &&
            !(
              fieldTag.permissions.length === 1 &&
              fieldTag.permissions[0] === 'READ'
            )
          ) {
            return false;
          }
        }
      }

      if (
        finding.permissions.includes('READ') &&
        finding.permissions.includes('WRITE')
      ) {
        for (let field of dependentFields) {
          const fieldTag = trimTagsEdit.find((tag) => tag.object === field);
          if (
            fieldTag &&
            !(
              fieldTag.permissions.includes('READ') ||
              fieldTag.permissions.includes('WRITE')
            )
          ) {
            return false;
          }
        }
      }

      return true;
    };

    if (!isValidFindingPermissionsEdit()) {
      dispatch({
        type: FETCH_ERROR,
        payload:
          'Issues sub fields cannot be set to WRITE if Issues is set to READ',
      });
      return;
    }

    const hasPermissionsEdit = trimTagsEdit.some(
      (tag) => tag.permissions.length > 0,
    );

    if (!hasPermissionsEdit) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select at least one permission',
      });
      return;
    }

    const fieldTag = trimTagsEdit.find((tag) => tag.object);

    if (!fieldTag || !fieldTag.object) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select at least one permission',
      });
      return;
    }

    if (!roleName){
      dispatch({
        type: FETCH_ERROR,
        payload: 'Role name is required!',
      });
      return;
    }

    const lowerCaseRoleName = roleName.toLowerCase();

    const lowerCaseOob = oobroles.map((role) => role.toLowerCase());

    if (lowerCaseOob.includes(lowerCaseRoleName)) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Custom roles cannot have the same names as out of the box roles',
      });
      return;
    }

    if (rolesType === 'ORG_LEVEL') {
      obj['orgLevelRoles'] = obj['globalRoles'];
      obj['orgLevelPermissions'] = obj['globalPermissions'];
      delete obj.globalRoles;
      delete obj.globalPermissions;
    }
    if (localPermissionsList.length > 0 && !validateOrgRole(localPermissionsList)) {
      dispatch({
        type: FETCH_ERROR,
        payload:
          'Please select both Permission and Access level from the dropdowns!',
      });
      return;
    }
    dispatch(updateRole(obj));
    dispatch(onGetUsers());
    rolesModalClose();
    setLocalPermissionsList([]);
    setSelectAll(false);
  };

  const isFindingRead = allRoles?.some(
    (role) => role.permission === 'finding' && role.access === 'READ',
  );

  const isFindingReadEdit = localPermissionsList?.some(
    (role) => role.permission === 'finding' && role.access === 'READ',
  );

    const removeIssuesField = () => {

      setAllRoles((cur) => {
        let updateTag = [...cur];
        let updatedArray = [];
        updateTag.map((elem)=>{

          if(elem.permission !== 'findingStatus' && elem.permission !== 'findingSeverity' && elem.permission !== 'findingAssignment' && elem.permission !== 'findingTag' && elem.permission !== 'findingComment'){

            updatedArray.push(elem);
          }
      });
        
      return [...updatedArray];
    });
  };

  const removeIssuesFieldEdit = () => {

    setLocalPermissionsList((cur) => {
      let updateTag = [...cur];
      let updatedArray = [];
      updateTag.map((elem)=>{

        if(elem.permission !== 'findingStatus' && elem.permission !== 'findingSeverity' && elem.permission !== 'findingAssignment' && elem.permission !== 'findingTag' && elem.permission !== 'findingComment'){

          updatedArray.push(elem);
        }
    });
      
    return [...updatedArray];
  });
};



  const addIssuesField = () => {
    setAllRoles((cur) => {
      return [
        ...cur,
        {
          permission: 'findingSeverity',
          access: 'READ',
        },
        {
          permission: 'findingStatus',
          access: 'READ',
        },
        {
          permission: 'findingTag',
          access: 'READ',
        },
        {
          permission: 'findingComment',
          access: 'READ',
        },
        {
          permission: 'findingAssignment',
          access: 'READ',
        },
      ];
    });
  };

  const addIssuesFieldEdit = () => {
      setLocalPermissionsList((cur) => {
      return [
        ...cur,
        {
          permission: 'findingSeverity',
          access: 'READ',
        },
        {
          permission: 'findingStatus',
          access: 'READ',
        },
        {
          permission: 'findingTag',
          access: 'READ',
        },
        {
          permission: 'findingComment',
          access: 'READ',
        },
        {
          permission: 'findingAssignment',
          access: 'READ',
        },
      ];
    });
  };
  return (
    <ModalContainer open={rolesModal} handleModalClose={closeAndRemoveTag}>
      <div className={styles.headerTag}>
        <StyleIcon />
        <h3>{editMode ? title : 'Add Roles'}</h3>
      </div>
      <div className={styles.fieldContainer}>
        <h4 className={styles.fieldLabel}>Role Name</h4>
        <TextField
          id='flag'
          label='Select'
          variant='outlined'
          type='test'
          name='flag'
          value={roleName}
          defaultValue={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
      </div>
      <div className={styles.fieldContainer}>
        <h4 className={styles.fieldLabel}>Role Type *</h4>
        <FormControl sx={{minWidth: 180}}>
          <InputLabel id='role_type'>Select</InputLabel>
          <Select
            id='role_type'
            inputProps={{'aria-label': 'Without label'}}
            name='role_type'
            label='Role Type'
            disabled={editMode}
            defaultValue={editMode ? rolesType : undefined}
            onChange={(e) => changeRoleType(e.target.value)}
          >
            <MenuItem value={'GLOBAL'}>Environment</MenuItem>
            <MenuItem value={'ORG_LEVEL'}>Workspace</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        className={styles.fieldContainer}
        style={{display: editMode ? 'none' : 'block'}}
      >
        <h4 className={styles.fieldLabel}>Select All</h4>
        <Checkbox
          onChange={selectAllHandler}
          checked={selectAll}
        />
      </div>
      <div className={styles.addBtnContainer}>
        <Button
          className={styles.closeBtn}
          variant='contained'
          color='primary'
          onClick={editMode ? addTagHandlerEdit : addTagHandler}
        >
          Add Permission
        </Button>
      </div>
      <Box
        component='form'
        autoComplete='off'
        onSubmit={editMode ? onSubmitTagEdit : onSubmitTag}
      >
        <TableContainer component={Paper} sx={{maxHeight: 400}}>
          <Table
            sx={{minWidth: 650}}
            aria-label='simple table'
            stickyHeader={true}
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Permission Object</TableCell>
                <TableCell align='left'>Access</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editMode
                ? localPermissionsList?.map((item, index) => (
                    <PermissionForm
                      key={index + item.permission}
                      permission={item.permission}
                      access={item.access}
                      onPermissionChange={onPermissionChangeEdit}
                      index={index}
                      removePermission={removePermissionEdit}
                      permissionList={permissionList}
                      editMode={editMode}
                      selectAll={selectAll}
                      disableRelatedSelectsEdit={isFindingReadEdit}
                    />
                  ))
                : allRoles?.map((item, index) => (
                    <PermissionForm
                      key={index + item.permission}
                      permission={item.permission}
                      access={item.access}
                      onPermissionChange={onPermissionChange}
                      index={index}
                      removePermission={removePermission}
                      permissionList={permissionList}
                      editMode={editMode}
                      selectAll={selectAll}
                      disableRelatedSelects={isFindingRead}
                      disableRelatedSelectsEdit={isFindingReadEdit}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.submitBtnContainer}>
          <Button
            className={styles.closeBtn}
            variant='contained'
            color='secondary'
            onClick={closeAndRemoveTag}
          >
            Close
          </Button>
          <Button
            variant='contained'
            type='submit'
          >
            <p>{editMode ? 'Update' : 'Submit'}</p>
          </Button>
        </div>
      </Box>
    </ModalContainer>
  );
};

export default AddNewRoles;

AddNewRoles.propTypes = {
  title: PropTypes.string,
  rolesModalClose: PropTypes.func,
  rolesModal: PropTypes.bool,
  editMode: PropTypes.bool,
  currentPermissionsList: PropTypes.array,
  _id: PropTypes.string,
  detailsTabChange: PropTypes.func,
  scanId: PropTypes.string,
  rolesName: PropTypes.string,
  rolesType: PropTypes.string,
  roleId: PropTypes.string,
  reportsCurPage: PropTypes.number,
};
