import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import DetailsForm from './DetailsForm/DetailsForm';
import Information from './Information/Information';
import HeaderDetails from './HeaderDetails/HeaderDetails';
import DetailsTab from './DetailsTab/DetailsTab';
// import {extractDate} from 'components/utils/UtilFunctions';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {onGetSpecificIssue} from 'redux/actions';
import { getIssuesCleanRequest } from 'redux/actions';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {GET_CUR_REPORT_ID} from 'shared/constants/ActionTypes';

const Details = ({writeable, isFindingsNew}) => {
  let {report_id, issue_id, org_id, page} = useParams();
  page = Number(page);
  const dispatch = useDispatch();
  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  const issuesFilters = useSelector(({dashboard}) => dashboard.issuesFilters);
  const issueDetail = useSelector(({details}) => details.issueDetail);
  // const singleIssue = issueDetail?.issue ? issueDetail.issue : null;
  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const filterData = useSelector(({dashboard}) => dashboard.filterData);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  const issuesData = useSelector(({dashboard}) => dashboard.issuesData);
  const totalIssues =  useSelector(({dashboard}) => dashboard.totalIssues);

  const issueIds =issuesData?.map((issue)=>issue._id);
  let curIndex = issueIds?.indexOf(issue_id);

  let singleIssue = (issuesData && issuesData.length && curIndex !== -1)?issuesData[curIndex]:null;

  if(!singleIssue && issueDetail){
    singleIssue = issueDetail.issue;
  }
  const [filteredReport, setFilteredReport] = useState(null);
  const reportsData = useSelector(({reports}) => reports.reportsData);

  // console.log('filteredReport: ', filteredReport);

  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );

  const [reqComment, setReqComment] = useState(null);

  useEffect(() => {
    if (globalConfigs) {
      const reqCommentValue = globalConfigs.required_comments_findings;
      setReqComment(reqCommentValue);
    }
  }, [globalConfigs]);

  useEffect(() => {
    if (reportsData && reportsData.length > 0 && report_id) {
      const specificReport = reportsData.find(
        (report) => report._id === report_id,
      );

      if (specificReport) {
        const {name: scanName} = specificReport;
        const {Initiated_Scan_date: scanDate} = specificReport;

        console.log('scanName:', scanName);
        console.log('scanDate:', scanDate);

        setFilteredReport(specificReport);
      }
      if (specificReport) {
        const {name: scanName, Initiated_Scan_date: scanDate} = specificReport;

        const formattedReport = {
          name: scanName,
          date: scanDate,
        };

        // console.log('ScanDate from DEtails: ', scanDate);
        // console.log('formattedReport: ', formattedReport);

        setFilteredReport(formattedReport);
      }
    }
  }, [reportsData, report_id]);

  useEffect(() => {
    dispatch(
      dispatch({type: GET_CUR_REPORT_ID, payload: report_id}),
    );
    return () => {};
  }, [issue_id, report_id]);
  

  useEffect(() => {
    if(curIssuesFilterState){
    dispatch(getIssuesCleanRequest({  ...curIssuesFilterState, _id: report_id, page: page, orgId: org_id, pageSize: pageSize, isFindingsNew: isFindingsNew, filterData: filterData}));
    }else{
      dispatch(onGetSpecificIssue({scanId: report_id, issueId: issue_id, orgId: org_id, page: page}));
    }
  }, [page]);

  const navigate = useNavigate();
  const nextIssue = () => {
    if(curIndex !== -1 && curIndex < issuesData.length - 1){
    let next_id = issueIds[curIndex + 1];
    if (next_id != null && next_id != undefined) {
      navigate(`/details/${org_id}/${report_id}/${next_id}/${page}?isFindingsNew=${isFindingsNew}`);
    }
    }else if(curIndex !== -1){
      if(issuesCurPage * pageSize < totalIssues){
      dispatch(
        getIssuesCleanRequest({
          ...curIssuesFilterState,
          _id: report_id,
          page: issuesCurPage?issuesCurPage + 1 : 1,
          pageSize: pageSize,
          orgId: org_id,
          isFindingsNew: isFindingsNew,
          filterData: filterData
        }),
      ).then((data)=>{
        navigate(`/details/${org_id}/${report_id}/${data.data.findings[0]._id}/${issuesCurPage?issuesCurPage + 1 : 1}?isFindingsNew=${isFindingsNew}`);
      }).catch((error)=>{
        console.log(error);
      });
    }
    }
    // navigate(`/details/${org_id}/${report_id}/${issuesData[0]._id}`);
  };

  const previousIssue = () => {
    if(curIndex > 0) {
    let prev_id = issueIds[curIndex - 1];
    // const prev_id = issueDetail?.prev != null ? issueDetail.prev : null;
    if (prev_id != null && prev_id != undefined) {
      navigate(`/details/${org_id}/${report_id}/${prev_id}/${page}?isFindingsNew=${isFindingsNew}`);
    }
  }else if(curIndex!==-1 && issuesCurPage > 1){
    dispatch(
      getIssuesCleanRequest({
        ...curIssuesFilterState,
        _id: report_id,
        page: issuesCurPage && issuesCurPage > 1 ?issuesCurPage - 1 : 1,
        pageSize: pageSize,
        orgId: org_id,
        isFindingsNew: isFindingsNew,
        filterData: filterData
      }),
    ).then((data)=>{
      navigate(`/details/${org_id}/${report_id}/${data.data.findings[data.data.findings.length - 1]._id}/${issuesCurPage && issuesCurPage > 1 ?issuesCurPage - 1 : 1}?isFindingsNew=${isFindingsNew}`);
    }).catch((error)=>{
      console.log(error);
    });
  }
};



  return (
    <Fragment>
      {singleIssue  && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <HeaderDetails
              issue={singleIssue.issue}
              filename={singleIssue.filename}
              line={singleIssue.line}
              _id={singleIssue._id}
              issueId={singleIssue.issueId}
              nextIssue={nextIssue}
              nextIssueBtnState={true}
              previousIssue={previousIssue}
              previousIssueBtnState={true}
              complianceTypes={singleIssue?.complianceTypes || []}
              complianceViolations={singleIssue?.complianceViolations || []}
              scanName={filteredReport?.name || ''}
              scanDate={filteredReport?.date || ''}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Information
              description={singleIssue.description}
              remediationguidance={singleIssue.remediationguidance}
              traces={singleIssue.traces?singleIssue.traces[0]:null}
              codesnippets={
                singleIssue.codesnippets ? singleIssue.codesnippets : null
              }
              exploits={singleIssue.exploits?singleIssue.exploits:null}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DetailsForm
              founddate={singleIssue.founddate}
              updateddate={singleIssue.updateddate}
              assigned={singleIssue.assigned}
              tags={singleIssue.customFields || []}
              type={singleIssue.issue}
              status={singleIssue.status}
              severity={singleIssue.severity}
              issuesFilters={issuesFilters}
              _id={singleIssue._id}
              category={singleIssue?.category?.toUpperCase() || ''}
              cweViolation={singleIssue?.cweViolation || ''}
              owasp={singleIssue?.owasp || ''}
              reqComment={reqComment}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DetailsTab
              history={singleIssue.history}
              assigned={singleIssue.assigned}
              comments={singleIssue.comments}
              _id={singleIssue._id}
              writeable={writeable}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Details;

Details.propTypes = {
  writeable: PropTypes.bool,
  isFindingsNew: PropTypes.bool,
};
