import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import {useDispatch} from 'react-redux';
import {onDeleteOrg} from 'redux/actions';
import EditOrg from '../EditOrg/EditOrg';
import {EDIT_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';


function DotsDropdownButton({org_id, name, isSfcc, webdavurl, instanceurl, writeable}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = () => {
    setDeleteModal(true);
  };

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteModalClose = () => {
    setDeleteModal(false);
    setAnchorEl(null);
  };

  const deleteModalProceed = () => {
    setDeleteModal(false);
    dispatch(onDeleteOrg({org_id}));
  };

  const [editModal, setEditModal] = useState(false);
  const editModalClose = () => {
    dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: null});
    setEditModal(false);
    setAnchorEl(null);
  };
  const editModalOpen = () => {
    setEditModal(true);
  };
  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon
          sx={{fontSize: 30, margin: 0, padding: '0 !important'}}
          color='primary'
        />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem disabled={!writeable} onClick={editModalOpen}>
          Edit
        </MenuItem>
        <Divider sx={{my: 0.5}} />
        <MenuItem disabled={!writeable} onClick={deleteHandler}>          
          Delete
        </MenuItem>
      </Menu>

      <ModalContainer
        handleModalClose={deleteModalClose}
        handleModalProceed={deleteModalProceed}
        proceedButtonText='Yes'
        closeButtonText='No'
        open={deleteModal}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <DeleteForeverSharpIcon color='primary' sx={{fontSize: '80px'}} />
          <h1> Do you want to delete the Org?</h1>
          <p>
            This will remove all scans and findings associated with this org
          </p>
        </div>
      </ModalContainer>

      <EditOrg
        open={editModal}
        handleModalClose={editModalClose}
        org_id={org_id}
        name={name}
        isSfcc={isSfcc}
        webdavurl={webdavurl}
        instanceurl={instanceurl}
      ></EditOrg>
    </div>
  );
}

export default DotsDropdownButton;

DotsDropdownButton.propTypes = {
  org_id: PropTypes.string,
  name: PropTypes.string,
  isSfcc: PropTypes.bool,
  writeable: PropTypes.bool,
  webdavurl: PropTypes.string,
  instanceurl: PropTypes.string,
};
