import React, {Fragment, useState} from 'react';
// import { debounce } from 'lodash';
import {useEffect} from 'react';
import AppCard from '../../../@crema/core/AppCard';
import {useDispatch, useSelector} from 'react-redux';
import {getIssuesCleanRequest, onGetIssuesFilters} from 'redux/actions';
import IssuesDataTable from './IssuesDataTable';
import TextField from '@mui/material/TextField';
import styles from './Issues.module.css';
import Autocomplete from '@mui/material/Autocomplete';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
  CUR_ISSUES_FILTER_STATE,
  // GET_USER_NOTIFICATIONS,
  // GET_ISSUES_DATA,
  // GET_REPORTS_DATA,
} from 'shared/constants/ActionTypes';
import {Button} from '@mui/material';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';
import Switch from '@mui/material/Switch';
// import {onGetScanTypes} from 'redux/actions';
// import {GET_SCAN_TYPES} from 'shared/constants/ActionTypes';
import {GET_CUR_REPORT_ID} from 'shared/constants/ActionTypes';
// import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import {onGetGlobalConfigs} from 'redux/actions';
// import {socket} from '../../../socket';
// import {getUserNotifications} from 'redux/actions';
const Issues = () => {
  // Params
  let {org_id} = useParams();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [download, setDownload] = useState(false);
  // Redux

  const dispatch = useDispatch();
  const _id = useSelector(({dashboard}) => dashboard.curReportId);
  const reportsData = useSelector(({reports}) => reports.reportsData);
  const reportId = searchParams.get('reportId');
  const apiInProcess = useSelector(({common}) => common.apiInProcess);
  const issuesFilters = useSelector(({dashboard}) => dashboard.issuesFilters);
  const filterData = useSelector(({dashboard}) => dashboard.filterData);
  const pageSize = useSelector(({dashboard}) => dashboard.pageSize);
  // const userNotifications = useSelector(({details}) => details.userNotifications);
  const tableColumns = useSelector(({table}) => table.tableColumns);
  const curIssuesFilterState = useSelector(
    ({dashboard}) => dashboard.curIssuesFilterState,
  );
  const issuesCurPage = useSelector(({dashboard}) => dashboard.issuesCurPage);
  const downloadData = useSelector(({dashboard}) => dashboard.downloadData);
  const globalConfigs = useSelector(({configure}) => configure.globalConfigs);

  // const loggedUser = useSelector(({common}) => common.userInfo);
  // const originator = [loggedUser?.usr?._id];

  const currentReport = reportsData?.filter((scan) => scan._id === _id)[0];
  // const singleOrg = useSelector(({dashboard}) => dashboard.singleOrg);
  let {issue_id} = useParams();

  // useEffect(() => {
  //   socket.on('foo', onFooEvent);
    
  //   return () => {
  //     socket.off('foo', onFooEvent);
  //   };
  // }, []);

  // function onFooEvent() {
  //   dispatch(getUserNotifications({originator: originator}));
  //   dispatch(onGetIssuesFilters({_id: _id, orgId: org_id}));
  //   dispatch(
  //     getIssuesCleanRequest({
  //       ...issuesSearch,
  //       _id: _id,
  //       orgId: org_id,
  //       filterData: filterData,
  //       page: issuesCurPage,
  //       pageSize: pageSize,
  //       isDownload: false,
  //       isFindingsNew: switchState,
  //     }),
  //   );
  // }
  

  useEffect(() => {
    dispatch(dispatch({type: GET_CUR_REPORT_ID, payload: reportId}));
    return () => {};
  }, [issue_id, reportId]);

  const handleSwitchChange = (event) => {
    const switchValue = event.target.checked;
    setSwitchState(switchValue);
    // localStorage.setItem('getNew', !switchState ? 'true' : 'false');
    dispatch(
      getIssuesCleanRequest({
        _id: reportId,
        page: issuesCurPage,
        orgId: org_id,
        isFindingsNew: switchValue,
        pageSize: pageSize,
      }),
    );
  };



  useEffect(() => {
    dispatch(onGetGlobalConfigs(true));
  }, []);

  useEffect(() => {
    // const storedSwitchState = localStorage.getItem('getNew');
    // if (storedSwitchState) {
    //   setSwitchState(storedSwitchState === 'true');
    // }

    setSwitchState(currentReport?.configs?.show_new_findings?currentReport.configs.show_new_findings : false);
  }, []);
  const [switchState, setSwitchState] = useState(
    currentReport?.configs?.show_new_findings?currentReport.configs.show_new_findings : false,
  );


  const [reqComment, setReqComment] = useState(null);

  useEffect(() => {
    if (globalConfigs) {
      const reqCommentValue = globalConfigs.required_comments_findings;
      setReqComment(reqCommentValue);
    }
  }, [globalConfigs]);
  
  // State
  const [issuesSearch, setIssuesSearch] = useState({
    search: curIssuesFilterState?.search || '',
    historyIssueId: curIssuesFilterState?.historyIssueId || '',
    type: curIssuesFilterState?.type || [],
    severityType: curIssuesFilterState?.severityType || [],
    status: curIssuesFilterState?.status || [],
    assign: curIssuesFilterState?.assign || [],
    field: curIssuesFilterState?.field || [],
    category: curIssuesFilterState?.category || [],
    compliance: curIssuesFilterState?.compliance || [],
    cwe: curIssuesFilterState?.cwe || [],
    owasp: curIssuesFilterState?.owasp || [],
  });

  function cweCalc(cwe) {
    return cwe?.map((item) => {
      if (item) {
        return item;
      }
    });
  }

  const exportAsCSV = () => {
    setDownload(true);
    dispatch(
      getIssuesCleanRequest({
        _id: _id,
        orgId: org_id,
        ...issuesSearch,
        page: 1,
        pageSize: pageSize,
        filterData: filterData,
        isDownload: true,
        isFindingsNew: switchState,
      }),
    );
  };

  const clearFilters = () => {
    setIssuesSearch((object) => {
      return {
        ...object,
        search: '',
        historyIssueId: '',
        type: [],
        severityType: [],
        status: [],
        assign: [],
        field: [],
        category: [],
        compliance: [],
        cwe: [],
        owasp: [],
      };
    });
    dispatch(
      getIssuesCleanRequest({
        _id: _id,
        orgId: org_id,
        search: '',
        historyIssueId: '',
        type: [],
        severityType: [],
        status: [],
        assign: [],
        field: [],
        category: [],
        compliance: [],
        cwe: [],
        owasp: [],
        page: 1,
        pageSize: pageSize,
        filterData: filterData,
        isFindingsNew: switchState,
      }),
    );
    dispatch(onGetIssuesFilters({_id: _id, orgId: org_id}));
  };

  // Effects

  useEffect(() => {
    let count = 0;
    if (download) {
      const tableColumnFields = tableColumns.columns
        .filter((column) => column.hide === undefined || column.hide === false)
        .map((column) => column.field);
      const csvContent = [
        tableColumnFields.join(','), // Header row
        ...downloadData.map((item) => {
          const today = new Date();
          const diffDay = differenceInDays(today, new Date(item.founddate));
          const diffHour = differenceInHours(today, new Date(item.founddate));
          const diffMin = differenceInMinutes(today, new Date(item.founddate));
          count++;

          const rowData = tableColumnFields.map((column) => {
            switch (column) {
              case 'issueNumber':
                return count;
              case 'issueId':
                return item.issueId;
              case 'status':
                return item.status;
              case 'title':
                return `"${item.issue}"`;
              case 'filename':
                return `"${item?.filename?item.filename:'NA'}"`;
              case 'line':
                return `"${item?.line?item.line:'NA'}"`;
              case 'severity':
                return item.severity;
              case 'assigned':
                return item.assigned ? `"${item.assigned.email}"` : '';
              case 'age':
                return diffDay > 1
                  ? diffDay + ' Days'
                  : diffHour > 1
                  ? diffHour + ' Hours'
                  : diffMin > 1
                  ? diffMin + ' Mins'
                  : diffMin < 1
                  ? '1 Min'
                  : '';
              case 'tags':
                return (item.customFields && item.customFields.length > 0)
                  ? item.customFields
                      .map((tag) => `${tag.fieldName}:${tag.fieldValue}`)
                      .join(' || ')
                  : '';
              case 'category':
                return item.category || '';
              case 'violations':
                return (item.complianceViolations && item.complianceViolations.length>0)
                ? item.complianceViolations
                  .join(' || ')
                  :'';
              case 'cwe':
                return item.cweViolation || '';
              case 'owasp':
                return item.owasp || '';
              default:
                return ''; // Skip columns not in tableColumns
            }
          });

          return rowData.join(',');
        }),
      ].join('\n');

      const blob = new Blob([csvContent], {type: 'text/csv'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      a.click();
      URL.revokeObjectURL(url);
      setDownload(false);
    }
    return () => {
      setDownload(false);
    };
  }, [downloadData]);

  useEffect(() => {
    if (
      searchParams.get('target') === '_blank' &&
      searchParams.get('issueId') &&
      searchParams.get('reportId')
    ) {
      if (_id) {
        navigate(
          `/details/${org_id}/${searchParams.get(
            'reportId',
          )}/${searchParams.get('issueId')}`,
        );
      }
    }
  }, [_id]);

  useEffect(() => {
    if (_id != null) {
      let page = issuesCurPage || 1;
      dispatch(
        getIssuesCleanRequest({
          _id: _id,
          orgId: org_id,
          page: page,
          ...issuesSearch,
          pageSize: pageSize,
          isFindingsNew: switchState,
          filterData: filterData
        }),
      );
      dispatch(onGetIssuesFilters({_id: _id, orgId: org_id}));
    }
    return () => {};
  }, [dispatch, org_id, _id]);

  // useEffect(() => {
  //   if (searchParams.get('tab') === 'issues' && !searchParams.get('reportId')) {
  //     if (_id) {
  //       navigate({
  //         pathname: `/dashboard/${org_id}`,
  //         search: `?tab=issues&reportId=${_id}&page=${issuesCurPage}`,
  //       });
  //     } else {
  //       navigate({
  //         pathname: `/dashboard/${org_id}`,
  //         search: `?tab=dashboard`,
  //       });
  //     }
  //   }
  // }, [issuesCurPage, _id, org_id, navigate, searchParams]);

  useEffect(() => {
    if (searchParams.get('tab') === 'issues' && !searchParams.get('reportId')) {
      if (_id) {
        navigate({
          pathname: `/dashboard/${org_id}`,
          search: `?tab=issues&reportId=${_id}&page=${issuesCurPage}`,
        });
      } else {
        navigate({
          pathname: `/dashboard/${org_id}`,
          search: `?tab=dashboard`,
        });
      }
    }
  }, [_id, issuesCurPage, navigate, org_id]);

  useEffect(() => {
    dispatch({type: CUR_ISSUES_FILTER_STATE, payload: issuesSearch});
    return () => {
      // dispatch({type: GET_REPORTS_DATA, payload: null});
    };
  }, [issuesSearch]);

  // Search Handler
  useEffect(() => {
    let timerId;
    if (
      issuesSearch.search.length > 2 ||
      issuesSearch.historyIssueId.length > 0
    ) {
      const delay = 2000;
      timerId = setTimeout(() => {
        dispatch(
          getIssuesCleanRequest({
            ...issuesSearch,
            ['search']: issuesSearch.search,
            page: 1,
            _id: _id || reportId,
            orgId: org_id,
            pageSize: pageSize,
            isFindingsNew: switchState,
          }),
        );
      }, delay);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [issuesSearch.search, issuesSearch.historyIssueId]);

  // const scanTypes = useSelector(({dashboard}) => dashboard.scanTypes);

  // useEffect(() => {
  //   dispatch(onGetScanTypes({org_id}));
  //   return () => {
  //     dispatch({type: GET_SCAN_TYPES, payload: null});
  //   };
  // }, [org_id]);

  // console.log('scanTypes: ', scanTypes);

  function fileNameHandler(event) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  }

  function assignedHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        assign: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        assign: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function typeHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        type: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        type: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function severityHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        severityType: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        severityType: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function stateHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        status: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        status: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function cweHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        cwe: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        cwe: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function owaspHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        owasp: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        owasp: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function complianceHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        compliance: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        compliance: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function categoryHandler(event, value) {
    setIssuesSearch((cur) => {
      return {
        ...cur,
        category: value,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        category: value,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  function fieldHandler(event, value) {
    let val =
      value?.map((item) => {
        return `${item}`;
      }) || [];

    setIssuesSearch((cur) => {
      return {
        ...cur,
        field: val,
      };
    });
    dispatch(
      getIssuesCleanRequest({
        ...issuesSearch,
        field: val,
        page: 1,
        _id: _id || reportId,
        orgId: org_id,
        pageSize: pageSize,
        isFindingsNew: switchState,
      }),
    );
  }

  const getAssignTo = () => {
    let arr = issuesFilters?.assignTo?.map((item) => {
      if (item == null) {
        return 'Unassigned';
      } else return item;
    });
    return arr;
  };

  const getTags = () => {
    return (
      issuesFilters?.customFields?.map((item) => {
        if (item.href) {
          return `${item.fieldName}:${item.fieldValue}:${item.href}`;
        } else if(item.fieldName) {
          return `${item.fieldName}:${item.fieldValue}`;
        } else{
          return item;
        }
      }) || []
    );
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  //three dots menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //disabled menuitem
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSelectedIdsChange = (isEmpty) => {
    setIsDisabled(isEmpty);
  };
  

  return (
    <Fragment>
      <AppCard sxStyle={{}} title='Issues'>
        <>
          <div
            className={`${styles.filtersContainer} ${
              apiInProcess ? styles.disabled : ''
            }`}
          >
            <TextField
              className={styles.textField}
              id='outlined-basic'
              label='Filename'
              variant='outlined'
              size='small'
              onChange={fileNameHandler}
              autoComplete='off'
              name='search'
              value={issuesSearch.search}
            />

            <Autocomplete
              multiple
              limitTags={1}
              disablePortal
              id='assigned-to'
              options={getAssignTo()}
              onChange={assignedHandler}
              className={styles.autoComplete}
              value={issuesSearch.assign}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Assigned to'
                  size='small'
                  name='assign'
                />
              )}
            />

            <Autocomplete
              multiple
              limitTags={1}
              disablePortal
              id='type'
              options={issuesFilters.issueTypes}
              className={styles.autoComplete}
              onChange={typeHandler}
              value={issuesSearch.type}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Type'
                  size='small'
                />
              )}
            />

            <Autocomplete
              multiple
              limitTags={1}
              disablePortal
              id='severity'
              options={issuesFilters.severity.map(
                (item) => item.charAt(0).toUpperCase() + item.substring(1),
              )}
              className={styles.autoComplete}
              onChange={severityHandler}
              value={issuesSearch.severityType}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Severity'
                  size='small'
                />
              )}
            />

            <Autocomplete
              multiple
              limitTags={1}
              disablePortal
              id='state'
              options={issuesFilters.status}
              className={styles.autoComplete}
              onChange={stateHandler}
              value={issuesSearch.status}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='State'
                  size='small'
                />
              )}
            />

            <Autocomplete
              multiple
              disablePortal
              id='cwe'
              options={cweCalc(issuesFilters.cwe)}
              className={styles.autoComplete}
              onChange={cweHandler}
              value={cweCalc(issuesSearch.cwe)}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='CWE'
                  size='small'
                />
              )}
            />

            <Autocomplete
              disablePortal
              multiple
              id='tags'
              options={getTags()}
              className={styles.autoComplete}
              onChange={fieldHandler}
              value={issuesSearch.field}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Tags'
                  size='small'
                />
              )}
            />

            <Autocomplete
              multiple
              disablePortal
              id='compliance'
              options={issuesFilters.compliance}
              className={styles.autoComplete}
              onChange={complianceHandler}
              value={issuesSearch.compliance}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Compliance'
                  size='small'
                />
              )}
            />

            <Autocomplete
              multiple
              disablePortal
              id='category'
              options={issuesFilters.category.map((item) => item.toUpperCase())}
              className={styles.autoComplete}
              onChange={categoryHandler}
              value={issuesSearch.category}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='Category'
                  size='small'
                />
              )}
            />

            <TextField
              className={styles.textField}
              id='outlined-basic'
              label='Issue Id'
              variant='outlined'
              size='small'
              onChange={fileNameHandler}
              autoComplete='off'
              name='historyIssueId'
              value={issuesSearch.historyIssueId}
              type='number'
            />

            <Autocomplete
              multiple
              disablePortal
              id='owasp'
              options={issuesFilters.owasp}
              className={styles.autoComplete}
              onChange={owaspHandler}
              value={issuesSearch.owasp}
              renderInput={(params) => (
                <TextField
                  className={styles.textField}
                  {...params}
                  variant='outlined'
                  label='OWASP'
                  size='small'
                />
              )}
            />

            {/* <div className={styles.textField}></div> */}

            <div className={styles.exportBtnDiv}>
              <Button
                className={styles.resetBtn}
                disablePortal
                variant='contained'
                onClick={() => clearFilters()}
              >
                Reset
              </Button>
              <div className={styles.newFindingContainer}>
                <p>New Issues</p>
                <Switch
                  disablePortal
                  checked={switchState}
                  onChange={handleSwitchChange}
                  color='primary'
                  inputProps={{'aria-label': 'controlled'}}
                />
                {/*Menú*/}
                <div>
                  <IconButton
                    className={styles.menuIcon}
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClick={handleCloseMenu}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                      },
                    }}
                  >
                    <MenuItem onClick={() => exportAsCSV()}>Export Result Set</MenuItem>
                    <MenuItem onClick={handleOpen} disabled={isDisabled}>Edit Selections</MenuItem>
                  </Menu>
                </div>
                {/*Menú*/}
              </div>
            </div>
          </div>

          {!apiInProcess && (
            <IssuesDataTable
              _id={_id}
              issuesSearch={issuesSearch}
              isFindingsNew={switchState}
              open={open}
              handleClose={handleClose}
              reqComment={reqComment}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
          )}
        </>
      </AppCard>
    </Fragment>
  );
};

export default Issues;
