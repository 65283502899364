import React, {Fragment} from 'react';
import AppCard from '@crema/core/AppCard';
import styles from './Information.module.css';
import PropTypes from 'prop-types';
import VulnerabilityDetails from '../VulnerabilityDetails/VulnerabilityDetails';

function Information({description, remediationguidance, traces, codesnippets, exploits}) {
  return (
    <Fragment>
      <AppCard sxStyle={{marginBottom: 7}}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Background</h3>
          <p className={styles.textBrief}>{description}</p>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Issue (Code Snippet, Exploit)</h3>
          <div className={styles.textBriefNew}>
            {traces != null && traces != undefined && traces.length > 0 && (
              <pre className={styles.preformatted}>
                <code className={styles.codeContainer}>
                  {traces[traces.length - 1]['codeSnippet']}
                </code>{' '}
              </pre>
            )}
            {codesnippets != null && (
              <pre className={styles.preformatted}>
                <code className={styles.codeContainer}>{codesnippets}</code>
              </pre>
            )}
          </div>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Remediation Guideline</h3>
          <ul className={styles.textBrief}>
            {remediationguidance.map((item) => {
              return (
                <li key={(Math.random() + 1).toString(36).substring(7)}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Vulnerability Trace</h3>
          <VulnerabilityDetails traces={traces} exploits={exploits}/>
        </div>
      </AppCard>
    </Fragment>
  );
}

export default Information;

Information.propTypes = {
  description: PropTypes.string,
  remediationguidance: PropTypes.array,
  traces: PropTypes.array,
  codesnippets: PropTypes.string,
  exploits: PropTypes.string,
};
